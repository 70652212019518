import { objectOf } from "prop-types";
import axios from "util/Api";
import {
  FETCH_ERROR,
  SET_INTERESTS_TABLE_STATUS,
  SET_INTERESTS_TABLE_VALUES,
  SET_TABLE_INTERESTS_GENERIC_STATUS,
  SET_TABLE_INTERESTS_GENERIC_VALUES,
  SET_TABLE_TABS_GENERIC_STATUS,
  SET_TABLE_TABS_GENERIC_VALUES,
  SET_TABLE_SIMPLE_STATUS,
  SET_TABLE_SIMPLE_VALUES,
  GET_EXPORT,
  SET_PAGINATION_TABLE_STATUS,
  SET_PAGINATION_TABLE_VALUES,
  SET_PAGINATION_TOTAL_RETRIEVED,
} from "../../constants/ActionTypes";
import util from "../../util/utilities";

/**
 *
 * @param {*} endpoint
 * @param {*} template
 * @param {*} chiave è l'id del componente
 * @param {*} event
 * @param {*} campaign
 */
export const getTableData2 = (
  endpoint,
  template,
  chiave,
  event,
  campaign,
  tab
) => {
  return (dispatch, getState) => {
    // Import element for Url created
    const { start_date, end_date } = getState().filters.dates; // from Redux
    const filterDevice2 = getState().filters.filterDevice.values; // from Redux
    const days = getState().filters.days.values; // from Redux
    const customer = getState().user.customerActive.values; // from redux
    const conversion = getState().filters.filterConversions.values;
    const pageActive = JSON.parse(localStorage.getItem("pageActive")); // from localstorage
    const interest = JSON.parse(localStorage.getItem("interest")); // from localstorage

    // Init Url
    let url = "";

    // if for differentiate interest pages from normal pages
    if (pageActive === "interest_detail") {
      const queryString = require("query-string");
      let parsed = queryString.parse(window.location.search);

      let name =
        interest !== null && interest !== undefined
          ? interest
          : parsed["interest"];
      name = name.split("> ");
      let interestFather = "";
      let interestSon = name[name.length - 1].replace("&", "%26");

      if (name.length > 2) {
        name.pop();
        for (var i in name) {
          if (i < name.length - 1) {
            interestFather =
              interestFather + name[i].replace("&", "%26") + "> ";
          } else {
            interestFather = interestFather + name[i].replace("&", "%26");
            interestFather = interestFather.trim();
          }
        }
      } else if (name.length > 1) {
        name.pop();
        interestFather = name[0].replace("&", "%26");
        interestFather = interestFather.trim();
      } else {
        interestFather = "";
      }

      url = `${endpoint}?converted=all&customer=${customer}&days=30&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&filters=&event=&father=${interestFather}&interest=${interestSon}&tab=${tab}`;
    } else if (
      pageActive === "prediction" ||
      pageActive === "behaviour" ||
      pageActive === "content_promoted"
    ) {
      url = `${endpoint}?campaign=${campaign}&converted=all&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&page=1`;
    } else if (pageActive === "dem_cart" || pageActive === "dem_cart_promo") {
      url = `${endpoint}?campaign=${campaign}&converted=all&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}`;
    } else {
      // interests
      url = `${endpoint}?converted=${conversion}&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}`;
    }

    axios
      .get(url)
      .then(({ data }) => {
        let chartContent = data;

        switch (template) {
          case "TrendingInterestsGeneric": {
            if (chartContent !== undefined) {
              dispatch(computeTrendingInterestsTest(chartContent, chiave));
            } else {
              dispatch({ type: SET_INTERESTS_TABLE_STATUS, payload: "failed" });
            }
            break;
          }
          case "TrendingProductGeneric": {
            if (chartContent !== undefined) {
              dispatch(computeTrendingInterestsTest(chartContent, chiave));
            } else {
              dispatch({ type: SET_INTERESTS_TABLE_STATUS, payload: "failed" });
            }
            break;
          }
          case "TableTabsGeneric": {
            if (chartContent !== undefined) {
              dispatch(
                computeTableTabsGeneric(chartContent["results"], chiave)
              );
            } else {
              dispatch({
                type: SET_TABLE_TABS_GENERIC_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "TableInterests": {
            if (
              chartContent !== undefined &&
              Object.keys(chartContent).length > 0
            ) {
              dispatch(
                computeTableInterestsTest(chartContent["results"], chiave)
              );
            } else if (chartContent.length === 0) {
              dispatch({
                type: SET_INTERESTS_TABLE_VALUES,
                payload: { values: [], status: false },
              });
              dispatch({
                type: SET_INTERESTS_TABLE_STATUS,
                payload: "complete",
              });
            } else {
              dispatch({ type: SET_INTERESTS_TABLE_STATUS, payload: "failed" });
            }
            break;
          }
          case "TableSimple": {
            if (chartContent !== undefined) {
              dispatch(computeTableSimple(chartContent["results"], chiave));
            } else {
              dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "failed" });
            }
            break;
          }
          default:
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: "failed" });
      });
  };
};

export const getTableDataServer = (
  endpoint,
  template,
  chiave,
  event,
  campaign,
  filters
) => {
  return (dispatch, getState) => {
    // Import element for Url created
    const { start_date, end_date } = getState().filters.dates; // from Redux
    const days = getState().filters.days.values; // from Redux
    const customer = getState().user.customerActive.values; // from localstorage

    let url = `${endpoint}?converted=all&customer=${customer}&days=${days}&device=all&start_date=${start_date}&end_date=${end_date}`;

    if (filters) {
      url = url.concat(
        `&filters=&filter=medium:${filters.medium}&filter=source:${filters.source}`
      );
    }

    axios
      .get(url)
      .then(({ data }) => {
        let chartContent = data;

        switch (template) {
          case "TableWithTableInside": {
            if (chartContent !== undefined) {
              dispatch(computeTableSimple(chartContent, chiave, url));
            } else {
              dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "failed" });
            }
            break;
          }
          case "TableSimple": {
            if (chartContent !== undefined) {
              dispatch(
                computeTableSimple(chartContent["results"], chiave, url)
              );
            } else {
              dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "failed" });
            }
            break;
          }
          default:
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: "failed" });
      });
  };
};

export const getPaginationTable = (
  endpoint,
  template,
  chiave,
  event,
  campaign,
  recordFilters,
  page,
  sorter,
  filter,
  pageSize,
  currentTab
) => {
  return (dispatch, getState) => {
    // console.log("getPaginationTable with page: ", page);
    // console.log("page size; ", pageSize);

    // Importo gli elementi per comporre l'Url
    const { start_date, end_date } = getState().filters.dates; // from Redux
    const days = getState().filters.days.values; // from Redux
    const customer = getState().user.customerActive.values; // from localstorage

    let field = "";
    let order = "";

    // Ordinamento
    if (sorter !== undefined) {
      field = sorter.field;
      order = sorter.order === "ascend" ? "asc" : "desc";
    }

    //console.log(order)

    let filterColumns = "";

    // Filtro
    if (filter !== undefined) {
      for (const [key, value] of Object.entries(filter)) {
        if (value !== null)
          filterColumns = filterColumns + `&filter=${key}:${value[0]}`;
      }
    }

    let url = "";

    // Compongo l'Url
    if (window.location.pathname !== "/interest_detail") {

      if (
        recordFilters !== undefined &&
        Object.keys(recordFilters).length > 0
      ) {
        url = `${endpoint}?converted=all&customer=${customer}&device=all&start_date=${start_date}&end_date=${end_date}${
          page !== 0 ? `&page=${page}` : "&totals=1"
        }&page_size=${pageSize !== undefined ? pageSize : 10}${
          sorter !== undefined ? `&sort=${field}&direction=${order}` : ""
        }${
          filter !== undefined
            ? `&filters=&filter=medium:${recordFilters.medium}&filter=source:${recordFilters.source}${filterColumns}`
            : `&filters=&filter=medium:${recordFilters.medium}&filter=source:${recordFilters.source}`
        }`;
      } else {
        url = `${endpoint}?converted=all&customer=${customer}&device=all&start_date=${start_date}&end_date=${end_date}${
          page !== 0 ? `&page=${page}` : "&totals=1"
        }&page_size=${pageSize !== undefined ? pageSize : 10}${
          sorter !== undefined ? `&sort=${field}&direction=${order}` : ""
        }${filter !== undefined ? `&filters=${filterColumns}` : ""}${
          currentTab !== undefined ? `&tab=${currentTab}` : ""
        }`;
      }
    } else {
      

      const interest = JSON.parse(localStorage.getItem("interest"));

      const queryString = require("query-string");
      let parsed = queryString.parse(window.location.search);

      let name =
        interest !== null && interest !== undefined
          ? interest
          : parsed["interest"];
      name = name.split("> ");

      let interestFather = "";
      let interestSon = name[name.length - 1].replace("&", "%26");

      if (name.length > 2) {
        name.pop();
        for (var i in name) {
          if (i < name.length - 1) {
            interestFather =
              interestFather + name[i].replace("&", "%26") + "> ";
          } else {
            interestFather = interestFather + name[i].replace("&", "%26");
          }
        }
      } else if (name.length > 1) {
        name.pop();
        interestFather = name[0].replace("&", "%26");
      } else {
        interestFather = "";
      }

      if (
        recordFilters !== undefined &&
        Object.keys(recordFilters).length > 0
      ) {
        url = `${endpoint}?converted=all&customer=${customer}&device=all&start_date=${start_date}&end_date=${end_date}&father=${interestFather}&interest=${interestSon}${
          page !== 0 ? `&page=${page}` : "&totals=1"
        }${sorter !== undefined ? `&sort=${field}&direction=${order}` : ""}${
          filter !== undefined
            ? `&filters=&filter=medium:${recordFilters.medium}&filter=source:${recordFilters.source}${filterColumns}`
            : `&filters=&filter=medium:${recordFilters.medium}&filter=source:${recordFilters.source}`
        }`;
      } else {
        url = `${endpoint}?converted=all&customer=${customer}&device=all&start_date=${start_date}&end_date=${end_date}&father=${interestFather}&interest=${interestSon}${
          page !== 0 ? `&page=${page}` : "&totals=1"
        }${sorter !== undefined ? `&sort=${field}&direction=${order}` : ""}${
          filter !== undefined ? `&filters=${filterColumns}` : ""
        }${currentTab !== undefined ? `&tab=${currentTab}` : ""}`;
      }
    }

    //console.log(url)

    // Eseguo la chiamata
    axios
      .get(url)
      .then(({ data }) => {
        let chartContent = data;

        // controllo se il campo page è !== da 0
        // !== 0: eseguo la chiamata paginata
        // === 0: inserisco il numero delle pagine
        if (page !== 0) {
          switch (template) {
            case "SimplePaginationTable": {
              if (chartContent !== undefined) {
                dispatch(
                  computeSimplePaginationTable(
                    chartContent["results"],
                    chiave,
                    url,
                    page,
                    pageSize !== undefined ? pageSize : 10
                  )
                );
              } else {
                dispatch({
                  type: SET_PAGINATION_TABLE_STATUS,
                  payload: { values: "failed", key: chiave },
                });
              }
              break;
            }
            default:
          }
        } else {
          // page === 0 means totals=1 (i.e get the totals)
          //console.log("setting totals", chartContent);

          //console.log(chartContent)

          // Salviamo dentro lo status del componente:
          // Current: la pagina corrente
          // pageSize: il numero di elementi nella tabella
          // loading: se è in caricamento
          if (chartContent.results < 1){
            dispatch({
              type: SET_PAGINATION_TABLE_STATUS,
              payload: {
                values: null,
                key: chiave,
              },
            });
          } else {
            dispatch({
              type: SET_PAGINATION_TABLE_STATUS,
              payload: {
                values: {
                  pageSize: pageSize !== undefined ? pageSize : 10,
                  current: 1,
                  total: chartContent.results[0].totals,
                  loading: true,
                },
                key: chiave,
              },
            });
          }
          // dispatch({
          //   type: SET_PAGINATION_TABLE_STATUS,
          //   payload: {
          //     values: {
          //       pageSize: pageSize !== undefined ? pageSize : 10,
          //       current: 1,
          //       total: chartContent.results[0].totals,
          //       loading: true,
          //     },
          //     key: chiave,
          //   },
          // });

          dispatch({
            type: SET_PAGINATION_TOTAL_RETRIEVED,
            payload: {
              key: chiave,
              value: true,
            },
          });
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: "failed" });
      });
  };
};

const computeSimplePaginationTable = (content, chiave, url, page, pageSize) => {
  return (dispatch, getState) => {
    //console.log(url);

    let exportTable = {
      url: process.env.REACT_APP_BACKEND_URL + url,
    };

    let response = {};
    let status = {};

    let new_results = [];

    content.map((item, i) => {
      new_results.push({
        ...item,
        key: i,
        id: i,
      });

      return true; // React
    });

    try {
      response = {
        data: new_results,
      };

      //console.log(status);

      status = {
        current: page,
        total: getState().table.paginationTables.status[chiave].total,
        loading: false,
        pageSize: pageSize !== undefined ? pageSize : 10,
      };
    } catch (error) {
      console.log("failed");
    }

    //console.log(status);
    //console.log(new_results);

    dispatch({
      type: SET_PAGINATION_TABLE_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: GET_EXPORT,
      payload: { values: exportTable, key: chiave },
    });

    dispatch({
      type: SET_PAGINATION_TABLE_STATUS,
      payload: { values: status, key: chiave },
    });
  };
};

const computeTableSimple = (content, chiave, url) => {
  return (dispatch) => {
    dispatch({
      type: SET_TABLE_SIMPLE_STATUS,
      payload: { values: "loading", key: chiave },
    });

    //console.log(content)

    let exportTable = {
      url: process.env.REACT_APP_BACKEND_URL + url,
    };

    let response = [];

    let new_results = [];

    content.map((item, i) => {
      new_results.push({
        ...item,
        key: i,
        id: i,
      });

      return true; // React
    });

    try {
      response = {
        values: new_results,
        loading: false,
      };
    } catch (error) {
      console.log("failed");
      dispatch({
        type: SET_TABLE_SIMPLE_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }

    //console.log(exportTable)

    dispatch({
      type: SET_TABLE_SIMPLE_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: GET_EXPORT,
      payload: { values: exportTable, key: chiave },
    });

    dispatch({
      type: SET_TABLE_SIMPLE_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

function calcola_interests(data, start_date, end_date, chiave) {
  var risultato = [];

  if (chiave === "interests") {
    //console.log('chiave=interest')
    risultato = {
      tot_users: 0,
      tot_conversions: 0,
      conversion_rate: 0,
      users: [],
      conversions: [],
      conv_rates: [],
      dates: util.get_dates_array(start_date, end_date),
    };
  } else {
    risultato = {
      tot_users: 0,
      tot_conversions: 0,
      conversion_rate: 0,
      delta_users: 0,
      users: [],
      conversions: [],
      conv_rates: [],
      dates: util.get_dates_array(start_date, end_date),
    };
  }

  // inizializzo Dates
  var dates = {};
  risultato.dates.map(function (d) {
    dates[d] = {
      users: 0,
      converted: 0,
    };
    return true;
  });

  // Somma utenti per ciascuna data
  data.map(function (item) {
    if (dates[item["date"]] === undefined) {
      dates[item["date"]] = [];
      dates[item["date"]]["users"] = item["users"] + item["users"];
      if (item["converted"] === 1)
        dates[item["date"]]["converted"] += item["users"];
    } else {
      dates[item["date"]]["users"] += item["users"];
      if (item["converted"] === 1)
        dates[item["date"]]["converted"] += item["users"];
    }
    return true;
  });

  risultato.dates.map(function (d) {
    let current_users = dates[d]["users"];
    let current_conv = dates[d]["converted"];
    risultato.users.push(current_users);
    risultato.conversions.push(current_conv);
    risultato.conv_rates.push(
      util.getConversionRate(current_conv, current_users)
    );
    risultato.tot_users += current_users;
    risultato.tot_conversions += current_conv;
    return true;
  });

  risultato.conversion_rate =
    (risultato.tot_conversions / risultato.tot_users) * 100;

  //console.log(risultato)

  return risultato;
}

const computeTrendingInterestsTest = (content, chiave) => {
  return (dispatch) => {
    dispatch({
      type: SET_TABLE_INTERESTS_GENERIC_STATUS,
      payload: { values: true, key: chiave },
    });

    let response = {};

    try {
      var start_date = JSON.parse(localStorage.getItem("start_date"));
      var end_date = JSON.parse(localStorage.getItem("end_date"));

      var results = {};
      var prev = {};
      var prev_dates = util.get_previous_period(start_date, end_date);

      for (var element in content) {
        response[element] = [];
        if (content[element] !== null) {
          for (var key in content[element]) {
            var delta = 0;

            if (element === "new") {
              delta = "100%";
            } else if (element === "disappeared") {
              delta = 0 + " %";
            } else {
              delta = content[element][key].delta.toFixed(2) + " %";
            }

            results = calcola_interests(
              content[element][key].results,
              start_date,
              end_date,
              chiave
            );
            prev = calcola_interests(
              content[element][key].prev,
              prev_dates.start_date,
              prev_dates.end_date,
              chiave
            );
            response[element].push({
              name: key,
              delta: delta,
              unique_users: {
                total: results.tot_users,
                values: prev.users.concat(results.users),
                dates: prev.dates.concat(results.dates),
              },
              converted: {
                total: results.tot_conversions,
                values: prev.conversions.concat(results.conversions),
                dates: prev.dates.concat(results.dates),
              },
              conversion: {
                total: results.conversion_rate,
                values: prev.conv_rates.concat(results.conv_rates),
                dates: prev.dates.concat(results.dates),
              },
            });
          }
        }
      }
    } catch (error) {
      console.log(error);
      console.log("error");
      dispatch({
        type: SET_TABLE_INTERESTS_GENERIC_STATUS,
        payload: { values: true, key: chiave },
      });
    }
    //console.log(response)
    dispatch({
      type: SET_TABLE_INTERESTS_GENERIC_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_TABLE_INTERESTS_GENERIC_STATUS,
      payload: { values: false, key: chiave },
    });
  };
};

const computeTableTabsGeneric = (content, chiave) => {
  return (dispatch) => {
    dispatch({
      type: SET_TABLE_TABS_GENERIC_STATUS,
      payload: { values: true, key: chiave },
    });

    let response = {};

    try {
      if (chiave === "keywords") {
        for (let element in content) {
          response[element] = [];
          content[element].map((item) => {
            response[element].push({
              name: item.node,
              users: item.users,
              recurrences: item.recurrences,
              converted: item.converted,
            });
          });
        }
      } else {
        for (let element in content) {
          response[element] = [];
          content[element].map((item) => {
            response[element].push({
              name: item.node,
              users: item.users,
              conversions: item.conversions,
              revenue: item.revenue,
            });
          });
        }
      }
    } catch (error) {
      console.log("error");
      console.log(error);
      dispatch({
        type: SET_TABLE_TABS_GENERIC_STATUS,
        payload: { values: true, key: chiave },
      });
    }

    dispatch({
      type: SET_TABLE_TABS_GENERIC_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_TABLE_TABS_GENERIC_STATUS,
      payload: { values: false, key: chiave },
    });
  };
};

/**
 *
 * @param {*} data a list of interest objects, each containing the interest's name and
 *                 three lists: unique users, converted users and conversion rate.
 *                 Each list contains an object for each date in the specified range.
 */
const computeTableInterestsTest = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_INTERESTS_TABLE_STATUS, payload: "loading" });

    dispatch({
      type: SET_INTERESTS_TABLE_VALUES,
      payload: { values: data, status: false },
    });

    dispatch({ type: SET_INTERESTS_TABLE_STATUS, payload: "complete" });
  };
};
