import {
  SET_FILTER_COMPONENT_STATUS,
  SET_FILTER_COMPONENT_VALUES,
  SET_FILTERS_ACTIVE_STATUS,
  SET_FILTERS_ACTIVE_VALUES,
  SET_FILTER_COMPONENT_COLOR_VALUES
} from "../../constants/ActionTypes";

const INIT_STATE = {
  filterComponent: {
    values: {},
    status: {},
  },
  filtersActive: {
    values: {},
    status: {},
  },
  filtersColor: {
    values: {}
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_FILTER_COMPONENT_STATUS: {
      return {
        ...state,
        filterComponent: {
          ...state.filterComponent,
          status: {
            ...state.filterComponent.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_FILTER_COMPONENT_VALUES: {
      return {
        ...state,
        filterComponent: {
          ...state.filterComponent,
          values: {
            ...state.filterComponent.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    case SET_FILTERS_ACTIVE_STATUS: {
      return {
        ...state,
        filtersActive: {
          ...state.filtersActive,
          status: {
            ...state.filtersActive.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_FILTERS_ACTIVE_VALUES: {
      return {
        ...state,
        filtersActive: {
          ...state.filtersActive,
          values: {
            ...state.filtersActive.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    case SET_FILTER_COMPONENT_COLOR_VALUES: {
      return {
        ...state,
        filtersColor: {
          ...state.filtersColor,
          values: {
            ...state.filtersColor.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    default:
      return state;
  }
};
