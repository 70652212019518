import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input } from "antd";
//import {useDispatch} from "react-redux";
import IntlMessages from "util/IntlMessages";
import { recover } from "../appRedux/actions";
import { useDispatch } from "react-redux";

const FormItem = Form.Item;

const Recover = (props) => {
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        dispatch(recover(values.email));
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="nature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p></p>
              <p></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="E-mail" />)}
              </FormItem>
              <p>
                <IntlMessages id="app.userAuth.forgot" />
              </p>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.send" />
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(Recover);

export default WrappedNormalLoginForm;
