import {
  SET_CUSTOMERS_VALUES,
  SET_CUSTOMERS_STATUS,
  SET_CUSTOMERS_PAGES_VALUES,
  SET_CUSTOMERS_PAGES_STATUS,
  SET_CUSTOMERS_ACTIVE_VALUES,
  SET_CUSTOMERS_ACTIVE_STATUS,
  SET_PAGE_ACTIVE_VALUES,
  SET_PAGE_ACTIVE_STATUS,
  SET_CUSTOMERS_SERVICE_STATUS,
  SET_CUSTOMERS_SERVICE_VALUES,
  SET_USER_INIT_STATE,
  SET_USER_INFO,
} from "../../constants/ActionTypes";

// Imposto come stato iniziale il customer dell'url
const queryString = require("query-string");
let parsed = queryString.parse(window.location.search);

const INIT_STATE = {
  customers: {
    values: JSON.parse(localStorage.getItem("customers")),
    status: "idle",
  },
  customerActive: {
    values: parsed['customer'] !== undefined ? parsed['customer'] : JSON.parse(localStorage.getItem("customer-active")),
    status: "idle",
  },
  customersPage: {
    values: JSON.parse(localStorage.getItem("list_pages")),
    status: "idle",
  },
  page_active: {
    values: "",
    status: "idle",
  },
  serviceCustomer: {
    values: {},
    status: "idle",
  },
  exportTable: {
    values: {},
  },
  userInfo: {
    values: JSON.parse(localStorage.getItem("userInfo")),
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INIT_STATE: {
      return {
        ...state,
        serviceCustomer: {
          values: {},
          status: "idle",
        },
      };
    }

    // CHART
    case SET_CUSTOMERS_VALUES: {
      return {
        ...state,
        customers: {
          ...state.customers,
          values: action.payload,
        },
      };
    }
    case SET_CUSTOMERS_STATUS: {
      return {
        ...state,
        customers: {
          ...state.customers,
          status: action.payload,
        },
      };
    }

    // CHART
    case SET_CUSTOMERS_PAGES_VALUES: {
      return {
        ...state,
        customersPage: {
          ...state.customersPage,
          values: action.payload,
        },
      };
    }
    case SET_CUSTOMERS_PAGES_STATUS: {
      return {
        ...state,
        customersPage: {
          ...state.customersPage,
          status: action.payload,
        },
      };
    }

    // CUSTOMER ACTIVE
    case SET_CUSTOMERS_ACTIVE_VALUES: {
      return {
        ...state,
        customerActive: {
          ...state.customerActive,
          values: action.payload,
        },
      };
    }
    case SET_CUSTOMERS_ACTIVE_STATUS: {
      return {
        ...state,
        customerActive: {
          ...state.customerActive,
          status: action.payload,
        },
      };
    }

    // PAGE ACTIVE
    case SET_PAGE_ACTIVE_VALUES: {
      return {
        ...state,
        page_active: {
          ...state.page_active,
          values: action.payload,
        },
      };
    }
    case SET_PAGE_ACTIVE_STATUS: {
      return {
        ...state,
        page_active: {
          ...state.page_active,
          status: action.payload,
        },
      };
    }

    // SERVICE
    case SET_CUSTOMERS_SERVICE_STATUS: {
      return {
        ...state,
        serviceCustomer: {
          ...state.serviceCustomer,
          status: action.payload,
        },
      };
    }
    case SET_CUSTOMERS_SERVICE_VALUES: {
      return {
        ...state,
        serviceCustomer: {
          ...state.serviceCustomer,
          values: action.payload,
        },
      };
    }

    // USER INFO
    // SERVICE
    case SET_USER_INFO: {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          values: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
