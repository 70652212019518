import React from "react";
import axios from "util/Api";
import {
  FETCH_ERROR,
  SET_LTV_OVERVIEW_VALUES,
  SET_LTV_OVERVIEW_STATUS, // OVERVIEW
  SET_LTV_BORDERS_VALUES, // BORDERS
  SET_LTV_BORDERS_STATUS,
  SET_LTV_HISTORICAL_TREND_VALUES, // HISTORICAL
  SET_LTV_HISTORICAL_TREND_STATUS,
  SET_LTV_SEGMENTS_STATUS, // SEGMENTS
  SET_LTV_SEGMENTS_VALUES,
  SET_LTV_BUBBLE_STATUS, // BUBBLE
  SET_LTV_BUBBLE_VALUES,
  SET_LTV_LOOKBACK_STATUS, // LOOKBACK
  SET_LTV_LOOKBACK_VALUES,
  SET_LTV_STATISTICS_STATUS, // STATISTICS
  SET_LTV_STATISTICS_VALUES,
  GET_EXPORT,
} from "../../constants/ActionTypes";

import moment from "moment";
import { validateDates } from "../../util/utilities";

export const getLtvData = (endpoint, template, currentTab, chiave, kExport, id) => {
  return (dispatch, getState) => {
    const customer = getState().user.customerActive.values;

    const { start_date, end_date } = getState().filters.dates;

    // Validiamo le date
    let { startDate, endDate } = validateDates(start_date, end_date);

    let day = endDate; //moment().format("YYYY-MM-DD");
    let prev = startDate; //moment().subtract(7, "days").format("YYYY-MM-DD");
    let url = "";
    
    let filterBub = getState().filterComp.filtersActive.values[id]

    let BupFilter = [];
    if (filterBub !== undefined && filterBub.length > 0) {
      filterBub.map((event) => {
        BupFilter.push(`${event}`);
      });
    }


    switch (template) {
      case "LtvHistorical":
        url = `${endpoint}?&customer=${customer}&tab=${currentTab}&start_date=${prev}&end_date=${day}`;
        break;
      case "LtvSegments":
        url = `${endpoint}?&customer=${customer}&tab=${currentTab}&start_date=${day}&end_date=${day}`;
        break;
      case "LtvBubble":
        url = `${endpoint}?&customer=${customer}&tab=${currentTab}&start_date=${day}&end_date=${day}&filter=${BupFilter}`;
        break;
      case "LtvLookback":
        url = `${endpoint}?&customer=${customer}&start_date=${day}&end_date=${day}`;
        break;
      case "LtvStatistics":
        url = `${endpoint}?&customer=${customer}&start_date=${day}&end_date=${day}`;
        break;
      default:
        url = `${endpoint}?&customer=${customer}&start_date=${day}&end_date=${day}`;
    }

    let exportTable = {
      url: process.env.REACT_APP_BACKEND_URL + url,
    };

    axios
      .get(url)
      .then(({ data }) => {
        switch (template) {
          case "LtvOverview": {
            if (data !== undefined) {
              dispatch(computeLtvOverview(data));
            } else {
              dispatch({ type: SET_LTV_OVERVIEW_STATUS, payload: "failed" });
            }
            break;
          }
          case "LtvBorders": {
            if (data !== undefined) {
              dispatch(computeLtvBorders(data["results"]));
            } else {
              dispatch({ type: SET_LTV_BORDERS_STATUS, payload: "failed" });
            }
            break;
          }
          case "LtvHistorical": {
            if (data !== undefined) {
              dispatch(computeLtvHistorical(data["results"], chiave));
            } else {
              dispatch({
                type: SET_LTV_HISTORICAL_TREND_STATUS,
                payload: { status: "failed", key: chiave },
              });
            }
            break;
          }
          case "LtvSegments": {
            if (data !== undefined) {
              // Export con tabs
              dispatch({
                type: GET_EXPORT,
                payload: { values: exportTable, key: kExport },
              });

              dispatch(computeLtvSegments(data, chiave));
            } else {
              dispatch({
                type: SET_LTV_SEGMENTS_STATUS,
                payload: { status: "failed", key: chiave },
              });
            }
            break;
          }
          case "LtvBubble": {
            if (data !== undefined) {
              // Export con tabs
              dispatch({
                type: GET_EXPORT,
                payload: { values: exportTable, key: kExport },
              });

              dispatch(computeLtvBubble(data["results"], chiave));
            } else {
              dispatch({
                type: SET_LTV_BUBBLE_STATUS,
                payload: { status: "failed", key: chiave },
              });
            }
            break;
          }
          case "LtvLookback": {
            if (data !== undefined) {
              dispatch(computeLtvLookback(data, chiave));
            } else {
              dispatch({
                type: SET_LTV_LOOKBACK_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "LtvStatistics": {
            if (data !== undefined) {
              dispatch(computeLtvStatistics(data["results"], chiave));
            } else {
              dispatch({
                type: SET_LTV_STATISTICS_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          default:
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
};

const computeLtvOverview = (content) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LTV_OVERVIEW_VALUES, payload: content });

    dispatch({ type: SET_LTV_OVERVIEW_STATUS, payload: "complete" });
  };
};

const computeLtvBorders = (content) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LTV_BORDERS_VALUES, payload: content });

    dispatch({ type: SET_LTV_BORDERS_STATUS, payload: "complete" });
  };
};

const computeLtvHistorical = (content, chiave) => {
  return (dispatch, getState) => {

    dispatch({
      type: SET_LTV_HISTORICAL_TREND_VALUES,
      payload: { values: content, key: chiave },
    });

    dispatch({
      type: SET_LTV_HISTORICAL_TREND_STATUS,
      payload: { status: "complete", key: chiave },
    });
  };
};

const computeLtvSegments = (content, chiave) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_LTV_SEGMENTS_VALUES,
      payload: { values: content, key: chiave },
    });
    dispatch({
      type: SET_LTV_SEGMENTS_STATUS,
      payload: { status: "complete", key: chiave },
    });
  };
};

const computeLtvBubble = (content, chiave) => {
  return (dispatch, getState) => {

    let new_content = []

    // console.log(content)

    dispatch({
      type: SET_LTV_BUBBLE_VALUES,
      payload: { values: content, key: chiave },
    });
    dispatch({
      type: SET_LTV_BUBBLE_STATUS,
      payload: { status: "complete", key: chiave },
    });
  };
};

const computeLtvLookback = (content) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LTV_LOOKBACK_VALUES, payload: content });

    dispatch({ type: SET_LTV_LOOKBACK_STATUS, payload: "complete" });
  };
};

const computeLtvStatistics = (content) => {
  return (dispatch, getState) => {
    dispatch({ type: SET_LTV_STATISTICS_VALUES, payload: content });

    dispatch({ type: SET_LTV_STATISTICS_STATUS, payload: "complete" });
  };
};
