import React from "react";
import moment from 'moment'
import {useDispatch} from "react-redux";
import { filters } from "../../appRedux/actions/Auth";
import { getFilterData } from "../../appRedux/actions";
import {Button, Row, Col} from "antd";

export const SmallDatePicker = () =>{

  const dispatch = useDispatch();

  const onclick = (dateString) => { 

    console.log('entro')

    dispatch(filters(dateString))
    dispatch(getFilterData('newDate',{
      start_date: dateString.start_date,
      end_date: dateString.end_date
    }))
  }

  return(
    <div className="dl-filter-small-datapicker">
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button id="1" onClick={() =>{
            dispatch(filters({
              start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))
            dispatch(getFilterData('newDate',{
              start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))}
          }>
            Yesterday
          </Button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button id="2" onClick={() =>{
            dispatch(filters({
              start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))
            dispatch(getFilterData('newDate',{
              start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))}
          }>
            Last 7 Days
          </Button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button id="3" onClick={() =>{
            dispatch(filters({
              start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))
            dispatch(getFilterData('newDate',{
              start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))}
          }>
            Last 7 Days
          </Button>
        </Col>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Button id="3" onClick={() =>{
            dispatch(filters({
              start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))
            dispatch(getFilterData('newDate',{
              start_date: moment().subtract(30, 'days').format('YYYY-MM-DD'),
              end_date: moment().subtract(1, 'days').format('YYYY-MM-DD')
            }))}
          }>
            Last 7 Days
          </Button>
        </Col>
      </Row>    
    </div>
        
    );
}