import {
  SET_LTV_INIT_STATE,
  SET_LTV_OVERVIEW_VALUES,
  SET_LTV_OVERVIEW_STATUS, // OVERVIEW
  SET_LTV_BORDERS_VALUES,// BORDERS
  SET_LTV_BORDERS_STATUS,
  SET_LTV_HISTORICAL_TREND_VALUES,// HISTORICAL
  SET_LTV_HISTORICAL_TREND_STATUS,
  SET_LTV_SEGMENTS_STATUS, // SEGMENTS
  SET_LTV_SEGMENTS_VALUES,
  SET_LTV_BUBBLE_STATUS, // BUBBLE
  SET_LTV_BUBBLE_VALUES,
  SET_LTV_LOOKBACK_STATUS, // LOOKBACK
  SET_LTV_LOOKBACK_VALUES,
  SET_LTV_STATISTICS_STATUS, // STATISTICS
  SET_LTV_STATISTICS_VALUES
} from "../../constants/ActionTypes";

const INIT_STATE = {
  overview: {
    values: undefined,
    status: "idle",
  },
  borders: {
    values: undefined,
    status: "idle",
  },
  historical: {
    values: {},
    status: {},
  },
  segments: {
    values: {},
    status: {},
  },
  bubble: {
    values: {},
    status: {},
  },
  lookback: {
    values: null,
    status: {},
  },
  statistics: {
    values: {},
    status: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_LTV_INIT_STATE: {
      return INIT_STATE;
    }

    // Overview
    case SET_LTV_OVERVIEW_STATUS: {
      return {
        ...state,
        overview: {
          ...state.overview,
          status: action.payload,
        },
      };
    }
    case SET_LTV_OVERVIEW_VALUES: {
      return {
        ...state,
        overview: {
          ...state.overview,
          values: action.payload,
        },
      };
    }

    // Borders
    case SET_LTV_BORDERS_STATUS: {
      return {
        ...state,
        borders: {
          ...state.borders,
          status: action.payload,
        },
      };
    }
    case SET_LTV_BORDERS_VALUES: {
      return {
        ...state,
        borders: {
          ...state.borders,
          values: action.payload,
        },
      };
    }

    // Historical
    case SET_LTV_HISTORICAL_TREND_STATUS: {
      return {
        ...state,
        historical: {
          ...state.historical,
          status: {
            ...state.historical.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_LTV_HISTORICAL_TREND_VALUES: {
      return {
        ...state,
        historical: {
          ...state.historical,
          values: {
            ...state.historical.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // Segments
    case SET_LTV_SEGMENTS_STATUS: {
      return {
        ...state,
        segments: {
          ...state.segments,
          status: {
            ...state.segments.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_LTV_SEGMENTS_VALUES: {
      return {
        ...state,
        segments: {
          ...state.segments,
          values: {
            ...state.segments.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // Segments
    case SET_LTV_BUBBLE_STATUS: {
      return {
        ...state,
        bubble: {
          ...state.bubble,
          status: {
            ...state.bubble.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_LTV_BUBBLE_VALUES: {
      return {
        ...state,
        bubble: {
          ...state.bubble,
          values: {
            ...state.bubble.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // Overview
    case SET_LTV_STATISTICS_STATUS: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          status: action.payload,
        },
      };
    }
    case SET_LTV_STATISTICS_VALUES: {
      return {
        ...state,
        statistics: {
          ...state.statistics,
          values: action.payload,
        },
      };
    }

    // Lookback
    case SET_LTV_LOOKBACK_STATUS: {
      return {
        ...state,
        lookback: {
          ...state.lookback,
          status: action.payload,
        },
      };
    }
    case SET_LTV_LOOKBACK_VALUES: {
      return {
        ...state,
        lookback: {
          ...state.lookback,
          values: action.payload,
        },
      };
    }

    default:
      return state;
  }
};
