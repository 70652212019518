import React, { useEffect, useState } from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import { LOGIN_FAILED } from "../constants/ActionTypes";

import { getFilterData } from "../appRedux/actions";

export const getDatesFromUrl = (
  start,
  end,
  dispatch,
  customerActive,
  history
) => {
  // formatto le date dell'url
  dispatch(getFilterData("newDate", { start_date: start, end_date: end }));
  history.push(
    `/${JSON.parse(localStorage.getItem("pageActive"))}?customer=${
      customerActive.values
    }&start_date=${start}&end_date=${end}`
  );

  return true;
};

const FormItem = Form.Item;

const SignIn = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { tokenValidity, loginFailed, accessToken } = useSelector(
    ({ auth }) => auth
  );
  const { customerActive } = useSelector((state) => state.user);
  const { dates } = useSelector((state) => state.filters);

  // Use for insert loading in to button signin
  const [loginButtonState, setLoginButtonState] = useState(false);

  // Function that calls the login
  const handleSubmit = (e) => {
    e.preventDefault();
    // Set loading button true
    setLoginButtonState(true);
    // Validate fields form
    props.form.validateFields((err, values) => {
      if (!err) {
        // Redux signin
        dispatch(userSignIn(values));
      }
    });
  };

  // Check loginFailed redux
  useEffect(() => {
    if (loginFailed) {
      // reset redux loginFailed flag
      dispatch({ type: LOGIN_FAILED, payload: false });
      setLoginButtonState(false);
    }
  }, [loginFailed, dispatch]);

  useEffect(() => {
    // this means we are authenticated
    if (accessToken !== null && tokenValidity) {
      // check dates from url field
      getDatesFromUrl(
        dates.start_date,
        dates.end_date,
        dispatch,
        customerActive,
        history
      );
    }
  }, [accessToken, tokenValidity, props.history, dispatch]);

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="nature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p></p>
              <p></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("email", {
                  initialValue: "",
                  rules: [
                    {
                      required: true,
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                  ],
                })(<Input placeholder="Email" />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator("password", {
                  initialValue: "",
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="password"/>)}
              </FormItem>
              <FormItem>
                <Link to="/recover">
                  <IntlMessages id="app.userAuth.forgotPassword" />
                </Link>
              </FormItem>
              <FormItem>
                <Button
                  type="primary"
                  id="submit"
                  className="gx-mb-0"
                  htmlType="submit"
                  loading={loginButtonState}
                >
                  <IntlMessages id="app.userAuth.signIn" />
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(SignIn);

export default WrappedNormalLoginForm;
