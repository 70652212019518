import React, { useState, useEffect } from "react";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

// libreria grafica antd
import { Drawer, Button, Card, Typography, Select, Layout } from "antd";
import IntlMessages from "util/IntlMessages";

// libreria grafica antd
import { FilterConversion } from "../../components/Filters/byConversionFunc";
import { FilterDevice } from "../../components/Filters/byDeviceFunc";
import { SmallDatePicker } from "../../components/Filters/SmallDatepicker";
import LargeDatePicker from "../../components/Filters/LargeDatepicker";
import { getUserActions } from "../../appRedux/actions/UserActions";

import { LoadingOutlined } from "@ant-design/icons";
import { customer } from "../../appRedux/actions";
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;
const { Header } = Layout;

const { Title } = Typography;

const optionList = (customers) => {
  var options_list = [];

  customers.map(function (dt_customer, idx) {
    options_list.push(
      <Option value={dt_customer} key={`${dt_customer}_${idx}`}>
        {dt_customer}
      </Option>
    );

    return true;
  });

  if (options_list.length > 1) {
    return options_list;
  } else {
    return <option></option>;
  }
};

/**
 * Function Topbar
 */
const Topbar = () => {
  const { customerActive } = useSelector((state) => state.user);
  // console.log("customerActive: ", customerActive);
  // let customerToDisplay = "";

  const [visible, setVisible] = useState(false);
  const [customerToDisplay, setCustomerToDisplay] = useState("");

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleChange = (value) => {
    // console.log(customerToDisplay);
    setCustomerToDisplay(value);
    dispatch(getUserActions("customerActiveChange", value));
    // dispatch(getUserActions("dashboard_pages", "", true, value));

    // L'ultimo parametro specifica che stiamo cambiando utente
    // in questo modo possiamo rimanere nella pagina attuale
    dispatch(
      getUserActions("dashboard_pages", "", true, value, undefined, true)
    );
  };

  // useEffect(() => {
  //   if (customerActive.status === "complete")
  //     dispatch(getUserActions("dashboard_pages", "", true, customerToDisplay));
  // }, [customerActive]);

  // Configurazione base

  const customers = JSON.parse(localStorage.getItem("list_customer"));
  const pageActive = JSON.parse(localStorage.getItem("pageActive"));
  const interest = JSON.parse(localStorage.getItem("interest"));
  const page = window.location.pathname;

  // customer to display should be customerActive if present in customers
  // customers[0] otherwise
  // customerToDisplay =
  //   customerActive !== undefined &&
  //   customerActive.values !== undefined &&
  //   customers !== undefined &&
  //   customers !== null &&
  //   customers.length > 0
  //     ? customers.includes(customerActive.values)
  //       ? customerActive.values
  //       : customers[0]
  //     : "";

  // console.log("customerToDisplay: ", customerToDisplay);

  const { width, navCollapsed, navStyle } = useSelector(
    ({ settings }) => settings
  );
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // console.log("customer to disp: ", customerActive.values);
  //   setCustomerToDisplay(customerActive.values);
  // }, [customerActive]);

  // useEffect(() => {
  //   console.log("customer to disp: ", customerToDisplay);
  //   // setCustomerToDisplay(customerActive.values);
  // }, [customerToDisplay]);

  return (
    <Header>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      <ul
        className={`gx-header-notifications ${
          width >= TAB_SIZE ? "dl-topbar-lg" : "dl-topbar-lx"
        }`}
      >
        <li>
          <Title className="dl-topbar2-title-page" level={2}>
            {pageActive === null ? (
              <Spin indicator={antIcon} />
            ) : pageActive !== "interest_detail" ? (
              <IntlMessages id={`sidebar.${page.replace("/", "")}`} />
            ) : (
              <IntlMessages id={interest} />
            )}
          </Title>
        </li>
        <li className="dl-topbar-customer-button gx-ml-auto">
          {customers !== null && customers.length > 1 ? (
            <li className="dl-topbar-customer-select">
              <Select
                // defaultValue={
                //   customerActive !== undefined &&
                //   customerActive.values !== undefined
                //     ? customerActive.values
                //     : ""
                // }
                value={customerActive.values}
                id="customer_selected"
                style={{ width: 150 }}
                onChange={(value)=> handleChange(value)}
              >
                {optionList(customers)}
              </Select>
            </li>
          ) : (
            <div />
          )}
          <Button
            className="dl-filter-button"
            type="default"
            onClick={showDrawer}
          >
            Filters
          </Button>
          <Drawer
            title="Filters"
            placement="right"
            closable={false}
            onClose={onClose}
            visible={visible}
          >
            <Card title="DATE RANGE PICKER">
              <div id="dl-filter-elements">
                {width >= TAB_SIZE ? <LargeDatePicker /> : <SmallDatePicker />}
              </div>
            </Card>

            {
              <Card title="FILTER" className="filter-2">
                <div id="dl-filter-elements-conversion">
                  <span>BY CONVERSION</span>
                  {FilterConversion()}
                </div>

                <div id="dl-filter-elements-device">
                  <span>BY DEVICE</span>
                  {FilterDevice()}
                </div>
              </Card>
            }
          </Drawer>
        </li>
      </ul>
    </Header>
  );
};

export default Topbar;
