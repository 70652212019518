import axios from "util/Api";
import { Events } from "../../components/BodyComponent/New/Events";
import {
  SET_FILTERS_ACTIVE_STATUS,
  SET_FILTERS_ACTIVE_VALUES,
  SET_FILTER_COMPONENT_STATUS,
  SET_FILTER_COMPONENT_VALUES,
} from "../../constants/ActionTypes";

export const getGenericData = (endpoint, callback, rangeData, events) => {
  return (dispatch, getState) => {
    const { start_date, end_date } = getState().filters.dates;

    const filterDevice2 = getState().filters.filterDevice.values;
    const filterConversions = getState().filters.filterConversions.values;

    const days = getState().filters.days.values;

    const customer = getState().user.customerActive.values;

    const pageActive = JSON.parse(localStorage.getItem("pageActive"));

    const interest = JSON.parse(localStorage.getItem("interest"));

    let url = "";
    let eventsFilter = [];
    if (events !== undefined && events.length > 0) {
      // events.map((event)=>{
      //   console.log(event)
      //   eventsFilter = eventsFilter + `&event:${event}`
      // })
      events.map((event) => {
        // console.log(event);
        eventsFilter.push(`${event}`);
      });
    }


    if (rangeData) {
      url = `${endpoint}?converted=${filterConversions}&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&events=${eventsFilter}`;
    } else {
      url = `${endpoint}?converted=${filterConversions}&customer=${customer}&events=${eventsFilter}`;
    }

    axios
      .get(url)
      .then(({ data }) => {
        try {
          // console.log(data)
          callback(data);
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGenericTabData = (endpoint, tab, callback) => {
  return (dispatch, getState) => {
    const { start_date, end_date } = getState().filters.dates;

    const filterDevice2 = getState().filters.filterDevice.values;
    const filterConversions = getState().filters.filterConversions.values;

    const days = getState().filters.days.values;

    const customer = getState().user.customerActive.values;

    const pageActive = JSON.parse(localStorage.getItem("pageActive"));

    const interest = JSON.parse(localStorage.getItem("interest"));

    let url = "";

    url = `${endpoint}?converted=${filterConversions}&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&tab=${tab}`;

    axios
      .get(url)
      .then(({ data }) => {
        try {
          callback(data);
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};

export const getGenericFilterData = (endpoint, callback) => {
  return (dispatch, getState) => {
    const { start_date, end_date } = getState().filters.dates;

    const days = getState().filters.days.values;

    const customer = getState().user.customerActive.values;

    let url = "";

    url = `${endpoint}?customer=${customer}&days=${days}&start_date=${start_date}&end_date=${end_date}`;

    axios
      .get(url)
      .then(({ data }) => {
        try {
          callback(data);
        } catch (error) {
          console.error(error);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
};


export const updateFilter = (value, id) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_FILTERS_ACTIVE_VALUES,
      payload: {
        key: id,
        values: value,
      },
    });
    dispatch({
      type: SET_FILTERS_ACTIVE_STATUS,
      payload: { key: id, status: "complete" },
    });
  };
};