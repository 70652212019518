import React from "react";
import ut from "./utilities";
import IntlMessages from "./IntlMessages";

/**
 * Funzione che ci permette di abbreviare il numero sull'asse delle Y
 */
function nFormatter(num, digits) {
  if (num > 999) {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  } else return num;
}

const RADIAN = Math.PI / 180;
const CustomLabelPieChartGeneric = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomTooltipFill = ({ active, payload, label, name }) => {
  if (payload !== null) {
    var tooltip = [];

    if (payload !== undefined) {
      payload.map(function (item) {
        let conversions_name = ["conversion", "conversions", "conversion rate"];

        // Check if the value is in %
        tooltip.push(
          <div
            style={{
              color:
                item.payload.fill === undefined ? item.fill : item.payload.fill,
            }}
          >
            {conversions_name.includes(name) ? (
              <div className={`tooltip-sparkline`}>
                <div className="tooltip-sparkline_date">
                  {item.payload.dates}{" "}
                </div>
                <div className="tooltip-sparkline_content">
                  <div className="tooltip-sparkline_message">
                    <IntlMessages id={`tooltip.` + name} />
                  </div>
                  <div className="tooltip-sparkline_value">
                    {": " + ut.format_number(item.value, 2) + " %"}
                  </div>
                </div>
              </div>
            ) : (
              <div className={`tooltip-sparkline`}>
                <div className="tooltip-sparkline_date">
                  {" "}
                  {item.payload.dates}
                </div>
                <div className="tooltip-sparkline_content">
                  <div className="tooltip-sparkline_message">
                    <IntlMessages id={`tooltip.` + name} />
                  </div>
                  <div className="tooltip-sparkline_value">
                    {": " + ut.format_number(item.value, 2)}
                  </div>
                </div>
              </div>
            )}
          </div>
        );

        return true;
      });

      if (active) {
        return <div className={`custom-tooltip border-${name}`}>{tooltip}</div>;
      }
    }
  }
  return null;
};

const CustomTooltipSankeyFill = ({ active, payload, label, name }) => {
  if (payload !== null) {
    var tooltip = [];
    if (active) {
      tooltip.push(
        <div className={`tooltip-sankey`}>
          <div className="tooltip-sankey_name">{payload[0].name}</div>
          <div className="tooltip-sankey_value">
            {ut.format_number(payload[0].payload.payload.value, 2) + " %"}
          </div>
        </div>
      );
      return <div className={`custom-tooltip border-${name}`}>{tooltip}</div>;
    }
  }
  return null;
};

const CustomTooltipPieFill = ({ active, payload, label, name }) => {
  if (payload !== null) {
    var tooltip = [];

    if (payload !== undefined) {
      payload.map(function (item) {
        tooltip.push(
          <div className={`tooltip-pie`}>
            <div className="tooltip-pie_name">{item.name}</div>
            <div className="tooltip-pie_message">
              <IntlMessages id={`tooltip.` + name} />:
            </div>
            <div className="tooltip-pie_value">
              {ut.format_number(item.value, 2)}
            </div>
          </div>
        );

        return true;
      });

      if (active) {
        return <div className={`custom-tooltip border-users`}>{tooltip}</div>;
      }
    }
  }
  return null;
};

const CustomTooltipLineInterestFill = ({ active, payload, label, name }) => {
  if (payload !== null) {
    var tooltip = [];

    if (payload !== undefined) {
      payload.map(function (item) {
        let conversions_name = ["conversion", "conversions", "conversion rate"];

        name = item.payload.metric;

        // Check if the value is in %
        tooltip.push(
          <div
            style={{
              color:
                item.payload.fill === undefined ? item.fill : item.payload.fill,
            }}
          >
            {conversions_name.includes(name) ? (
              <div className={`tooltip-sparkline`}>
                <div className="tooltip-sparkline_date">
                  {item.payload.date}{" "}
                </div>
                <div className="tooltip-sparkline_content">
                  <div className="tooltip-sparkline_message">
                    <IntlMessages id={`tooltip.` + name} />
                  </div>
                  <div className="tooltip-sparkline_value">
                    {": " + ut.format_number(item.value, 5) + " %"}
                  </div>
                </div>
              </div>
            ) : (
              <div className={`tooltip-sparkline`}>
                <div className="tooltip-sparkline_date">
                  {" "}
                  {item.payload.date}
                </div>
                <div className="tooltip-sparkline_content">
                  <div className="tooltip-sparkline_message">
                    <IntlMessages id={`tooltip.` + name} />
                  </div>
                  <div className="tooltip-sparkline_value">
                    {": " + ut.format_number(item.value, 2)}
                  </div>
                </div>
              </div>
            )}
          </div>
        );

        return true;
      });

      if (active) {
        return <div className={`custom-tooltip border-${name}`}>{tooltip}</div>;
      }
    }
  }
  return null;
};

const CustomTooltipFillGeneric = ({ active, payload, label }) => {
  if (payload !== null) {
    var tooltip = [];
    if (payload !== undefined) {
      payload.map(function (item) {
        tooltip.push(
          <div
            style={{
              color:
                item.payload.fill === undefined ? item.fill : item.payload.fill,
            }}
          >
            <IntlMessages id={`tooltip.` + item.dataKey} />
            {": " + ut.format_number(item.value, 2)}
          </div>
        );

        return true;
      });

      if (active) {
        return <div className="custom-tooltip">{tooltip}</div>;
      }
    }
  }
  return null;
};

const CustomTooltipStroke = ({ active, payload, label }) => {
  if (payload !== null) {
    var tooltip = [];

    let conversions_name = [
      "conversion",
      "conversions",
      "conversion rate",
      "conv_rate",
      "Conversion Rate",
      "Budget",
    ];
    let revenue_name = ["budget", "revenue"];

    if (payload !== undefined) {
      payload.map(function (item, idx) {
        tooltip.push(
          conversions_name.includes(item.dataKey) ? (
            <div style={{ color: item.color }}>
              <IntlMessages
                key={item.dataKey + idx}
                id={`tooltip.` + item.dataKey}
              />
              {": " + ut.format_number(item.value, 2) + " %"}
            </div>
          ) : revenue_name.includes(item.dataKey) ? (
            <div style={{ color: item.color }}>
              <IntlMessages
                key={item.dataKey + idx}
                id={`tooltip.` + item.dataKey}
              />
              {": " + ut.format_number(item.value, 2) + " €"}
            </div>
          ) : (
            <div style={{ color: item.color }}>
              <IntlMessages
                key={item.dataKey + idx}
                id={`tooltip.` + item.dataKey}
              />
              {": " + ut.format_number(item.value, 2)}
            </div>
          )
        );

        return true;
      });
      if (active) {
        return (
          <div className="custom-tooltip">
            <div>{label}</div>
            {tooltip}
          </div>
        );
      }
    }
  }

  return null;
};

const CustomTooltipStrokeSpark = ({ active, payload, label }) => {
  if (payload !== null) {
    var tooltip = [];
    payload.map(function (item, idx) {
      tooltip.push(
        <div>
          {item.payload.date}
          <div style={{ color: item.color }}>
            {"Value: " + ut.format_number(item.value, 2)}
          </div>
        </div>
      );
      return true;
    });
    if (active) {
      return <div className="custom-tooltip">{tooltip}</div>;
    }
  }
  return null;
};

const CustomTickChartGeneric = (props) => {
  const { x, y, value, fill, payload, textAnchor } = props;

  console.log(props)

  return (
    <text
      className="ChartGeneric-Label"
      x={x}
      y={y}
      fill={fill}
      text-anchor={textAnchor}
    >
      {nFormatter(payload.value)}
    </text>
  );
};

function CustomColorfulLegendText(value, payload) {
  return (
    <span style={{ color: payload.payload.stroke }}>
      {<IntlMessages id={`legend.` + value} />}
    </span>
  );
}

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul>
      {payload.map((entry, index) => (
        <li key={`item-${index}`}>{entry.value}</li>
      ))}
    </ul>
  );
};

const CustomTickChartGenericXaxis = (props) => {
  const { x, y, value, fill, payload, textAnchor } = props;

  return (
    <text
      className="ChartGeneric-xaxis-values"
      x={x}
      y={y + 15}
      fill={fill}
      text-anchor={textAnchor}
    >
      {payload.value}
    </text>
  );
};

const CustomTickChartGenericYaxis = (props) => {
  console.log(props);
  const { x, y, value, fill, payload, textAnchor } = props;

  return (
    <text
      className="ChartGeneric-xaxis-values"
      x={x}
      y={y}
      fill={fill}
      text-anchor="left"
      angle="vertical"
    >
      {payload.value}
    </text>
  );
};

const CustomTooltipFillGeneric_XY = ({ active, payload, label }) => {
  if (payload !== null) {
    var tooltip = [];
    if (payload !== undefined) {
      payload.map(function (item) {
        tooltip.push(
          <div>
            <div>{item.payload.date}</div>
            <div
              style={{
                color:
                  item.payload.fill === undefined
                    ? item.fill
                    : item.payload.fill,
              }}
            >
              <IntlMessages id={`tooltip.` + item.dataKey} />
              {": " + ut.format_number(item.value, 2)}
            </div>
          </div>
        );

        return true;
      });

      if (active) {
        return <div className="custom-tooltip">{tooltip}</div>;
      }
    }
  }
  return null;
};

const CustomRandomColor = (dict, colorList, setColor) => {
  let condition = true;
  let color = 0;

  let new_color = colorList;

  // console.log(dict);

  while (true) {
    color = Math.floor(Math.random() * 10);

    if (colorList.includes(color)) {
      console.log(color, " esiste");
    } else {
      console.log(color, "non esiste");
      new_color.push(color)
      setColor(new_color)
      return color;
    }
  }
};

export default {
  CustomLabelPieChartGeneric,
  CustomTooltipFill,
  CustomTooltipStroke,
  CustomColorfulLegendText,
  CustomTickChartGeneric,
  renderLegend,
  CustomTooltipFillGeneric,
  CustomTickChartGenericXaxis,
  CustomTooltipFillGeneric_XY,
  CustomTooltipStrokeSpark,
  CustomTooltipPieFill,
  CustomTooltipLineInterestFill,
  CustomTooltipSankeyFill,
  CustomTickChartGenericYaxis,
  CustomRandomColor,
};
