// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

//Auth const
export const SIGNUP_USER = "SIGNUP_USER";
export const SIGNUP_USER_SUCCESS = "SIGNUP_USER_SUCCESS";
export const SIGNIN_GOOGLE_USER = "SIGNIN_GOOGLE_USER";
export const SIGNIN_GOOGLE_USER_SUCCESS = "SIGNIN_GOOGLE_USER_SUCCESS";
export const SIGNIN_FACEBOOK_USER = "SIGNIN_FACEBOOK_USER";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "SIGNIN_FACEBOOK_USER_SUCCESS";
export const SIGNIN_TWITTER_USER = "SIGNIN_TWITTER_USER";
export const SIGNIN_TWITTER_USER_SUCCESS = "SIGNIN_TWITTER_USER_SUCCESS";
export const SIGNIN_GITHUB_USER = "SIGNIN_GITHUB_USER";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_success";
export const SIGNIN_USER = "SIGNIN_USER";
export const SIGNIN_USER_SUCCESS = "SIGNIN_USER_SUCCESS";
export const SIGNOUT_USER = "SIGNOUT_USER";
export const SIGNOUT_USER_SUCCESS = "SIGNOUT_USER_SUCCESS";
export const INIT_URL = "INIT_URL";
export const USER_DATA = "user_data";
export const USER_TOKEN_SET = "user_token_set";

export const SET_CHART_INIT_STATE = "SET_CHART_INIT_STATE";
// Update Filtri
export const FILTERS = "FILTERS";
export const CUSTOMER = "CUSTOMER";
export const SET_FILTERS_VALUES = "SET_FILTERS_VALUES";
export const SET_FILTERS_STATUS = "SET_FILTERS_STATUS";
export const SET_DATES_STATUS = "SET_DATES_STATUS";
export const SET_DATES_VALUES = "SET_DATES_VALUES";

// User
export const SET_CUSTOMERS_VALUES = "SET_CUSTOMERS_VALUES";
export const SET_CUSTOMERS_STATUS = "SET_CUSTOMERS_STATUS";
export const SET_CUSTOMERS_PAGES_VALUES = "SET_CUSTOMERS_PAGES_VALUES";
export const SET_CUSTOMERS_PAGES_STATUS = "SET_CUSTOMERS_PAGES_STATUS";
export const SET_CUSTOMERS_ACTIVE_VALUES = "SET_CUSTOMERS_ACTIVE_VALUES";
export const SET_CUSTOMERS_ACTIVE_STATUS = "SET_CUSTOMERS_ACTIVE_STATUS";
export const SET_PAGE_ACTIVE_VALUES = "SET_PAGE_ACTIVE_VALUES";
export const SET_PAGE_ACTIVE_STATUS = "SET_PAGE_ACTIVE_STATUS";
export const SET_CUSTOMERS_SERVICE_STATUS = "SET_CUSTOMERS_SERVICE_STATUS";
export const SET_CUSTOMERS_SERVICE_VALUES = "SET_CUSTOMERS_SERVICE_VALUES";
export const SET_USER_INIT_STATE = "SET_USER_INIT_STATE";

export const SET_USER_INFO = "SET_USER_INFO";
//
export const ERROR_BACKEND = "ERROR_BACKEND";

// Signin
export const USER_ACCESS_TOKEN_SET = "USER_ACCESS_TOKEN_SET";
export const USER_REFRESH_TOKEN_SET = "USER_REFRESH_TOKEN_SET";
export const ACCESS_TOKEN_VALIDITY = "TOKEN_VALIDITY";
export const VALIDATING_TOKEN = "VALIDATING_TOKEN";
export const LOGIN_FAILED = "LOGIN_FAILED";
export const SET_DAYS_VALUES = "SET_DAYS_VALUES";
export const SET_DAYS_STATUS = "SET_DAYS_STATUS";

// Charts Actions
export const SET_CHART_STATUS = "SET_CHART_STATUS";
export const SET_CHART_DATA = "SET_CHART_DATA";
export const SET_LINE_CHART_STATUS = "SET_LINE_CHART_STATUS";
export const SET_LINE_CHART_VALUES = "SET_LINE_CHART_VALUES";
export const SET_LINE_CHART_GENERIC_STATUS = "SET_LINE_CHART_GENERIC_STATUS";
export const SET_LINE_CHART_GENERIC_VALUES = "SET_LINE_CHART_GENERIC_VALUES";
export const SET_LINE_EVENT_CHART_STATUS = "SET_LINE_EVENT_CHART_STATUS";
export const SET_LINE_EVENT_CHART_VALUES = "SET_LINE_EVENT_CHART_VALUES";
export const SET_HEAT_MAP_CHART_STATUS = "SET_HEAT_MAP_CHART_STATUS";
export const SET_HEAT_MAP_CHART_VALUES = "SET_HEAT_MAP_CHART_VALUES";
export const SET_SANKEY_STATUS = "SET_SANKEY_STATUS";
export const SET_SANKEY_VALUES = "SET_SANKEY_VALUES";
export const SET_TOTALS_ELEMETS_STATUS = "SET_TOTALS_ELEMETS_STATUS";
export const SET_TOTALS_ELEMETS_VALUES = "SET_TOTALS_ELEMETS_VALUES";
export const SET_TABLE_SIMPLE_STATUS = "SET_TABLE_SIMPLE_STATUS";
export const SET_TABLE_SIMPLE_VALUES = "SET_TABLE_SIMPLE_VALUES";
export const SET_CHART_GENERIC_VALUES = "SET_CHART_GENERIC_VALUES";
export const SET_PIE_CHART_GENERIC_STATUS = "SET_PIE_CHART_GENERIC_STATUS";
export const SET_PIE_CHART_GENERIC_VALUES = "SET_PIE_CHART_GENERIC_VALUES";

export const SET_LINE_TEST_STATUS = "SET_LINE_TEST_STATUS";
export const SET_LINE_TEST_VALUES = "SET_LINE_TEST_VALUES";
export const SET_LINE_CHART_GENERIC_MULTIPLE_VALUES =
  "SET_LINE_CHART_GENERIC_MULTIPLE_VALUES";
export const SET_LINE_CHART_GENERIC_MULTIPLE_STATUS =
  "SET_LINE_CHART_GENERIC_MULTIPLE_STATUS";
export const SET_USER_TECH_TRAVEL_STATUS = "SET_USER_TECH_TRAVEL_STATUS";
export const SET_USER_TECH_TRAVEL_VALUES = "SET_USER_TECH_TRAVEL_VALUES";
export const SET_BAR_GENERIC_STATUS = "SET_BAR_GENERIC_STATUS";
export const SET_BAR_GENERIC_VALUES = "SET_BAR_GENERIC_VALUES";
export const SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES =
  "SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES";
export const SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS =
  "SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS";

export const SET_CONVERSIONS_GENERIC_STATUS_TRENDS =
  "SET_CONVERSIONS_GENERIC_STATUS_TRENDS";
export const SET_CONVERSIONS_GENERIC_VALUES = "SET_CONVERSIONS_GENERIC_VALUES";

// TABLES
export const SET_INTERESTS_TABLE_STATUS = "SET_INTERESTS_TABLE_STATUS";
export const SET_INTERESTS_TABLE_VALUES = "SET_INTERESTS_TABLE_VALUES";
export const SET_TABLE_INTERESTS_GENERIC_STATUS =
  "SET_TABLE_INTERESTS_GENERIC_STATUS";
export const SET_TABLE_INTERESTS_GENERIC_VALUES =
  "SET_TABLE_INTERESTS_GENERIC_VALUES";
export const SET_TOTALS_PREDICTION_ELEMETS_VALUES =
  "SET_TOTALS_PREDICTION_ELEMETS_VALUES";
export const SET_TOTALS_PREDICTION_ELEMETS_STATUS =
  "SET_TOTALS_PREDICTION_ELEMETS_STATUS";
export const SET_TABLE_TABS_GENERIC_STATUS = "SET_TABLE_TABS_GENERIC_STATUS";
export const SET_TABLE_TABS_GENERIC_VALUES = "SET_TABLE_TABS_GENERIC_VALUES";

export const SET_PAGINATION_TOTAL_RETRIEVED = "SET_PAGINATION_TOTAL_RETRIEVED";
export const SET_PAGINATION_TABLE_LOADING = "SET_PAGINATION_TABLE_LOADING";
export const SET_TABLE_CURRENT_TAB = "SET_TABLE_CURRENT_TAB";

// TOTALS
export const SET_TOTALS_GENERIC_STATUS = "SET_TOTALS_GENERIC_STATUS";
export const SET_TOTALS_GENERIC_VALUES = "SET_TOTALS_GENERIC_VALUES";

// FILTERS
export const SET_FILTERS_CONVERSIONS_VALUES = "SET_FILTERS_CONVERSIONS_VALUES";
export const SET_FILTERS_CONVERSIONS_STATUS = "SET_FILTERS_CONVERSIONS_STATUS";

// GENERIC
export const SET_BAR_CHART_GENERIC_STATUS = "SET_BAR_CHART_GENERIC_STATUS";
export const SET_BAR_CHART_GENERIC_VALUES = "SET_BAR_CHART_GENERIC_VALUES";

//
export const SET_QUERY_STRING = "SET_QUERY_STRING";
export const SET_TABLE_INIT_STATE = "SET_TABLE_INIT_STATE";
export const GET_EXPORT = "GET_EXPORT";

export const SET_PAGINATION_TABLE_STATUS = "SET_PAGINATION_TABLE_STATUS";
export const SET_PAGINATION_TABLE_VALUES = "SET_PAGINATION_TABLE_VALUES";

// LTV
// INIT STATE
export const SET_LTV_INIT_STATE = "SET_LTV_INIT_STATE";
// OVERVIEW
export const SET_LTV_OVERVIEW_VALUES = "SET_LTV_OVERVIEW_VALUES";
export const SET_LTV_OVERVIEW_STATUS = "SET_LTV_OVERVIEW_STATUS";
// BORDERS
export const SET_LTV_BORDERS_VALUES = "SET_LTV_BORDERS_VALUES";
export const SET_LTV_BORDERS_STATUS = "SET_LTV_BORDERS_STATUS";
// HISTORICAL_TREND
export const SET_LTV_HISTORICAL_TREND_VALUES =
  "SET_LTV_HISTORICAL_TREND_VALUES";
export const SET_LTV_HISTORICAL_TREND_STATUS =
  "SET_LTV_HISTORICAL_TREND_STATUS";

// SEGMENTS
export const SET_LTV_SEGMENTS_VALUES = "SET_LTV_SEGMENTS_VALUES";
export const SET_LTV_SEGMENTS_STATUS = "SET_LTV_SEGMENTS_STATUS";

// BUBBLE
export const SET_LTV_BUBBLE_VALUES = "SET_LTV_BUBBLE_VALUES";
export const SET_LTV_BUBBLE_STATUS = "SET_LTV_BUBBLE_STATUS";

// LOOKBACK
export const SET_LTV_LOOKBACK_VALUES = "SET_LTV_LOOKBACK_VALUES";
export const SET_LTV_LOOKBACK_STATUS = "SET_LTV_LOOKBACK_STATUS";

// STATISTICS
export const SET_LTV_STATISTICS_VALUES = "SET_LTV_STATISTICS_VALUES";
export const SET_LTV_STATISTICS_STATUS = "SET_LTV_STATISTICS_STATUS";

// Strategy
export const SET_STRATEGIES_VALUES = "SET_STRATEGIES_VALUES";
export const SET_STRATEGIES_STATUS = "SET_STRATEGIES_STATUS";

export const SET_STRATEGY_STEP_VALUES = "SET_STRATEGY_STEP_VALUES";
export const SET_STRATEGY_STEP_STATUS = "SET_STRATEGY_STEP_STATUS";

export const SET_AUDIENCE_STEP_VALUES = "SET_AUDIENCE_STEP_VALUES";
export const SET_AUDIENCE_STEP_STATUS = "SET_AUDIENCE_STEP_STATUS";

export const SET_GOAL_STEP_VALUES = "SET_GOAL_STEP_VALUES";
export const SET_GOAL_STEP_STATUS = "SET_GOAL_STEP_STATUS";

export const SET_ACTIVATION_STEP_VALUES = "SET_ACTIVATION_STEP_VALUES";
export const SET_ACTIVATION_STEP_STATUS = "SET_ACTIVATION_STEP_STATUS";

export const SET_STRATEGY_INIT_STATE = "SET_STRATEGY_INIT_STATE";

export const SET_RADAR_GENERIC_STATUS = "SET_RADAR_GENERIC_STATUS";
export const SET_RADAR_GENERIC_VALUES = "SET_RADAR_GENERIC_VALUES";

export const SET_BAR_LATERAL_GENERIC_STATUS = "SET_BAR_LATERAL_GENERIC_STATUS";
export const SET_BAR_LATERAL_GENERIC_VALUES = "SET_BAR_LATERAL_GENERIC_VALUES";

export const SET_LINE_GENERIC_STATUS = "SET_LINE_GENERIC_STATUS";
export const SET_LINE_GENERIC_VALUES = "SET_LINE_GENERIC_VALUES";

export const SET_TAB_BAR_GENERIC_STATUS = "SET_TAB_BAR_GENERIC_STATUS";
export const SET_TAB_BAR_GENERIC_VALUES = "SET_TAB_BAR_GENERIC_VALUES";

export const SET_ON_GOOGLE_ADS_STATUS = "SET_ON_GOOGLE_ADS_STATUS";

export const SET_ON_GOOGLE_ADS_VALUES = "SET_ON_GOOGLE_ADS_VALUES";

export const SET_EVENTS_VALUES = "SET_EVENTS_VALUES";
export const SET_EVENTS_STATUS = "SET_EVENTS_STATUS";
export const SET_EVENTS_VALUES_VALUES = "SET_EVENTS_VALUES_VALUES";
export const SET_EVENTS_VALUES_STATUS = "SET_EVENTS_VALUES_STATUS";

export const SET_FILTER_COMPONENT_STATUS = "SET_FILTER_COMPONENT_STATUS";
export const SET_FILTER_COMPONENT_VALUES = "SET_FILTER_COMPONENT_VALUES";

export const SET_FILTERS_ACTIVE_STATUS = "SET_FILTERS_ACTIVE_STATUS";
export const SET_FILTERS_ACTIVE_VALUES = "SET_FILTERS_ACTIVE_VALUES";

export const SET_FILTER_COMPONENT_COLOR_VALUES = "SET_FILTER_COMPONENT_COLOR_VALUES";
