import {
  ACCESS_TOKEN_VALIDITY,
  USER_ACCESS_TOKEN_SET,
  USER_REFRESH_TOKEN_SET,
  VALIDATING_TOKEN,
  SIGNOUT_USER_SUCCESS,
  FETCH_ERROR,
  HIDE_MESSAGE,
  ERROR_BACKEND,
} from "../../constants/ActionTypes";
import axios from "../../util/Api";
import { getUserActions } from "../actions";
import { message } from "antd";

const refreshAccessToken = (store, refreshToken) => {
  axios
    .post("api/token/refresh/", {
      refresh: refreshToken,
    })
    .then(({ data }) => {
      if (data) {
        localStorage.setItem("accessToken", JSON.stringify(data.access));
        localStorage.setItem("refreshToken", JSON.stringify(data.refresh));
        axios.defaults.headers.common["authorization"] =
          "Bearer " + data.access;
        localStorage.setItem(
          "authorization",
          JSON.stringify("Bearer " + data.access)
        );
        // dispatch actions
        store.dispatch({ type: USER_ACCESS_TOKEN_SET, payload: data.access }); // set the access token
        store.dispatch({ type: USER_REFRESH_TOKEN_SET, payload: data.refresh }); // set the refresh token
        store.dispatch({ type: VALIDATING_TOKEN, payload: false });
      }
    })
    .catch((error) => {
      // Access token cannot be refreshed. Logout the user.
      store.dispatch({ type: ACCESS_TOKEN_VALIDITY, payload: false });
      store.dispatch({ type: VALIDATING_TOKEN, payload: false });
      localStorage.clear();
      store.dispatch({ type: SIGNOUT_USER_SUCCESS });
    });
};

const checkTokenValidity = (store) => {
  const accessToken = JSON.parse(localStorage.getItem("accessToken"));
  if (accessToken) {
    // signal the start of the validation process
    store.dispatch({ type: VALIDATING_TOKEN, payload: true });
    // setTimeout(() => {
    axios
      .post("api/token/verify/", {
        token: accessToken,
      })
      .then((response) => {
        // signal the end of the validation process
        // store.dispatch({ type: VALIDATING_TOKEN, payload: false });

        // needed when reloading or manually entering a URL
        store.dispatch({ type: ACCESS_TOKEN_VALIDITY, payload: true });

        // update axios bearer token
        axios.defaults.headers.common["authorization"] =
          "Bearer " + accessToken;
        localStorage.setItem(
          "authorization",
          JSON.stringify("Bearer " + accessToken)
        );

        // signal the end of the validation process
        store.dispatch({ type: VALIDATING_TOKEN, payload: false });
      })
      .catch((error) => {
        // Here we should try refreshing the token
        const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));
        if (refreshToken) refreshAccessToken(store, refreshToken);
      });
    // }, 1000);
  }
};

/**
/**
* This middleware checks the access token's validity each time
* a new location within the application is requested or a
* fetch request has failed. Moreover,
* it dispatches appropriate actions to change its validity.
*/
export const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
  // console.log("action type: ", action.type);
  // console.log("action type: ", typeof action.type);

  switch (action.type) {
    case "TOGGLE_COLLAPSE_MENU": {
      console.log("Collapse");
      break;
    }
    case "SET_FILTERS_ACTIVE_VALUES": {
      break;
    }
    case "@@router/LOCATION_CHANGE": {
      checkTokenValidity(store);
      break;
    }
    case "SET_CUSTOMERS_ACTIVE_STATUS": {
      store.dispatch(getUserActions("testInitAll"));
      break;
    }
    case FETCH_ERROR: {
      const error = action.payload;
      // if request failed due to unauthorized status, verify token's validity
      if (error.response && [403, 401].includes(error.response.status)) {
        checkTokenValidity(store);
      } else if (error.response && [400].includes(error.response.status)) {
        // failed login
        store.dispatch({ type: ACCESS_TOKEN_VALIDITY, payload: false });
        store.dispatch({ type: VALIDATING_TOKEN, payload: false });
        store.dispatch({ type: HIDE_MESSAGE, payload: false });
        return message.error("wrong credentials");
      } else if (error.response && [404].includes(error.response.status)) {
        store.dispatch({ type: ERROR_BACKEND, payload: true });
        return message.error("System error");
      }
      break;
    }
    default:
      break;
  }

  return next(action);
};
