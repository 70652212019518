import {
  INIT_URL, SIGNOUT_USER_SUCCESS, USER_DATA, USER_TOKEN_SET, FILTERS, CUSTOMER,
  LOGIN_FAILED,USER_ACCESS_TOKEN_SET,USER_REFRESH_TOKEN_SET,ACCESS_TOKEN_VALIDITY,VALIDATING_TOKEN
} from "../../constants/ActionTypes";
import axios from '../../util/Api'

const INIT_STATE = {
  accessToken: JSON.parse(localStorage.getItem('accessToken')),
  refreshToken: JSON.parse(localStorage.getItem('refreshToken')),
  initURL: '',
  authUser: JSON.parse(localStorage.getItem('user')),
  filter: {},
  loginFailed: false,
  tokenValidity: false,
  validatingToken: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case INIT_URL: {
      axios.defaults.headers.common['authorization'] = INIT_STATE.token

      
      return {...state, initURL: action.payload};
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        token: null,
        authUser: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        authUser: action.payload,
      };
    }

    case USER_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }

    case FILTERS: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case CUSTOMER: {
      return {
        ...state,
        filter: action.payload,
      };
    }

    case LOGIN_FAILED: {
      return {
        ...state,
        loginFailed: action.payload
      }
    }

    case USER_ACCESS_TOKEN_SET: {
      return {
        ...state,
        accessToken: action.payload, // action.payload is the access token
      };
    }

    case USER_REFRESH_TOKEN_SET: {
      return {
        ...state,
        refreshToken: action.payload, // action.payload is the access token
      };
    }

    case ACCESS_TOKEN_VALIDITY: {
      return {
        ...state,
        tokenValidity: action.payload
      }
    }

    case VALIDATING_TOKEN: {
      return {
        ...state,
        validatingToken: action.payload
      }
    }

    default:
      return state;
  }
}
