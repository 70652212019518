import {
  SET_INTERESTS_TABLE_STATUS,
  SET_INTERESTS_TABLE_VALUES,
  SET_TABLE_INTERESTS_GENERIC_STATUS,
  SET_TABLE_INTERESTS_GENERIC_VALUES,
  SET_TABLE_SIMPLE_STATUS,
  SET_TABLE_SIMPLE_VALUES,
  SET_TABLE_TABS_GENERIC_STATUS,
  SET_TABLE_TABS_GENERIC_VALUES,
  SET_TABLE_INIT_STATE,
  GET_EXPORT,
  SET_PAGINATION_TABLE_STATUS,
  SET_PAGINATION_TABLE_VALUES,
  SET_PAGINATION_TOTAL_RETRIEVED,
  SET_PAGINATION_TABLE_LOADING,
  SET_TABLE_CURRENT_TAB,
  SET_ON_GOOGLE_ADS_STATUS,
  SET_ON_GOOGLE_ADS_VALUES
} from "../../constants/ActionTypes";

const INIT_STATE = {
  tableInterests: {
    values: { values: null, status: true },
    status: "idle",
  },
  interestGeneric: {
    values: {},
    status: {},
  },
  tableTabsGeneric: {
    values: {},
    status: {},
  },
  tableSimple: {
    values: {},
    status: {},
  },
  exportTable: {
    values: {},
  },
  paginationTables: {
    values: {},
    status: {},
    totalRetrieved: {},
  },
  onGoogleADS: {
    values: {},
    status: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_TABLE_INIT_STATE: {
      return INIT_STATE;
    }

    // CHART
    case SET_INTERESTS_TABLE_STATUS: {
      return {
        ...state,
        tableInterests: {
          ...state.tableInterests,
          status: action.payload,
        },
      };
    }
    case SET_INTERESTS_TABLE_VALUES: {
      return {
        ...state,
        tableInterests: {
          ...state.tableInterests,
          values: {
            values: action.payload.values,
            status: action.payload.status,
          },
        },
      };
    }

    // TEST INTERESTS
    case SET_TABLE_INTERESTS_GENERIC_STATUS: {
      return {
        ...state,
        interestGeneric: {
          ...state.interestGeneric,
          status: {
            ...state.interestGeneric.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_TABLE_INTERESTS_GENERIC_VALUES: {
      return {
        ...state,
        interestGeneric: {
          ...state.interestGeneric,
          values: {
            ...state.interestGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // TEST INTERESTS
    case SET_TABLE_TABS_GENERIC_STATUS: {
      return {
        ...state,
        tableTabsGeneric: {
          ...state.tableTabsGeneric,
          status: {
            ...state.tableTabsGeneric.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_TABLE_TABS_GENERIC_VALUES: {
      return {
        ...state,
        tableTabsGeneric: {
          ...state.tableTabsGeneric,
          values: {
            ...state.tableTabsGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // TEST INTERESTS
    case SET_TABLE_SIMPLE_STATUS: {
      return {
        ...state,
        tableSimple: {
          ...state.tableSimple,
          status: {
            ...state.tableSimple.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_TABLE_SIMPLE_VALUES: {
      return {
        ...state,
        tableSimple: {
          ...state.tableSimple,
          values: {
            ...state.tableSimple.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // EXPORT
    case GET_EXPORT: {
      return {
        ...state,
        exportTable: {
          ...state.exportTable,
          values: {
            ...state.exportTable.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // TEST Pagination Tables
    case SET_PAGINATION_TABLE_STATUS: {
      return {
        ...state,
        paginationTables: {
          ...state.paginationTables,
          status: {
            ...state.paginationTables.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    case SET_PAGINATION_TABLE_LOADING: {
      return {
        ...state,
        paginationTables: {
          ...state.paginationTables,
          status: {
            ...state.paginationTables.status,
            [action.payload.key]: {
              ...state.paginationTables.status[action.payload.key],
              loading: action.payload.value,
            },
          },
        },
      };
    }

    case SET_TABLE_CURRENT_TAB: {
      return {
        ...state,
        paginationTables: {
          ...state.paginationTables,
          status: {
            ...state.paginationTables.status,
            [action.payload.key]: {
              ...state.paginationTables.status[action.payload.key],
              currentTab: action.payload.value,
            },
          },
        },
      };
    }

    case SET_PAGINATION_TABLE_VALUES: {
      return {
        ...state,
        paginationTables: {
          ...state.paginationTables,
          values: {
            ...state.paginationTables.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // case DELETE_PAGINATION_TABLE_STATUS: {
    //   return {
    //     ...state,
    //     paginationTables: {
    //       ...state.paginationTables,
    //       values: {
    //         ...state.paginationTables.values,
    //         [action.payload.key]: undefined,
    //       },
    //     },
    //   };
    // }

    // case DELETE_PAGINATION_TABLE_VALUES: {
    //   return {
    //     ...state,
    //     paginationTables: {
    //       ...state.paginationTables,
    //       values: {
    //         ...state.paginationTables.values,
    //         [action.payload.key]: action.payload.values,
    //       },
    //     },
    //   };
    // }

    case SET_PAGINATION_TOTAL_RETRIEVED: {
      return {
        ...state,
        paginationTables: {
          ...state.paginationTables,
          totalRetrieved: {
            ...state.paginationTables.totalRetrieved,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }

    // OnGoogleADS
    case SET_ON_GOOGLE_ADS_STATUS: {
      return {
        ...state,
        onGoogleADS: {
          ...state.onGoogleADS,
          status: {
            ...state.onGoogleADS.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_ON_GOOGLE_ADS_VALUES: {
      return {
        ...state,
        onGoogleADS: {
          ...state.onGoogleADS,
          values: {
            ...state.onGoogleADS.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    default:
      return state;
  }
};
