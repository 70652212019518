import { 
    SET_FILTERS_VALUES, SET_FILTERS_STATUS, FETCH_START, FETCH_SUCCESS, SET_DATES_STATUS, SET_DATES_VALUES, 
    SET_FILTERS_CONVERSIONS_STATUS, SET_FILTERS_CONVERSIONS_VALUES, SET_DAYS_STATUS, SET_DAYS_VALUES,
} from '../../constants/ActionTypes';
import { getUserActions } from './UserActions';


export const getFilterData = (title,values) => {
    return (dispatch) => {


        dispatch({type: FETCH_START});

        switch (title) {
            case 'filterDevice':{

                dispatch({type: SET_FILTERS_STATUS, payload: 'loading'})
                dispatch({type: SET_FILTERS_VALUES, payload: values})
                
                dispatch({type: SET_FILTERS_STATUS, payload: 'complete'})

                dispatch({type: FETCH_SUCCESS});
                break;
            }
            case 'filterConversions':{

                dispatch({type: SET_FILTERS_CONVERSIONS_STATUS, payload: 'loading'})
                dispatch({type: SET_FILTERS_CONVERSIONS_VALUES, payload: values})
                
                dispatch({type: SET_FILTERS_CONVERSIONS_STATUS, payload: 'complete'})

                dispatch({type: FETCH_SUCCESS});
                break;
            }
            case 'newDate':{

                dispatch({type: SET_DATES_STATUS, payload: 'loading'})
                dispatch({type: SET_DAYS_STATUS, payload: 'loading'})

                dispatch(getUserActions('testInitAll'))

                dispatch({type: SET_DATES_VALUES, payload: { startDate: values.start_date, endDate: values.end_date }})
                
                const date1 = new Date(values.start_date);
                const date2 = new Date(values.end_date);
                const diffTime = Math.abs(date2 - date1);
                const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                
                localStorage.setItem("days", JSON.stringify(diffDays));

                dispatch({type: SET_DAYS_VALUES, payload: diffDays})

                dispatch({type: SET_DATES_STATUS, payload: 'complete'})
                dispatch({type: SET_DAYS_STATUS, payload: 'complete'})

 
                localStorage.setItem("start_date", JSON.stringify(values.start_date));
                localStorage.setItem("end_date", JSON.stringify(values.end_date));

                dispatch({type: FETCH_SUCCESS});
                break;
            }
            default: 
        }
        
    }
}

