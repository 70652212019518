import React from "react";
import axios from "util/Api";
import {
  SET_CUSTOMERS_VALUES,
  SET_CUSTOMERS_STATUS,
  SET_CUSTOMERS_PAGES_VALUES,
  SET_CUSTOMERS_PAGES_STATUS,
  SET_CUSTOMERS_ACTIVE_VALUES,
  SET_CUSTOMERS_ACTIVE_STATUS,
  SET_CUSTOMERS_SERVICE_VALUES,
  SET_CUSTOMERS_SERVICE_STATUS,
  SET_CHART_INIT_STATE,
  SET_USER_INIT_STATE,
  SET_TABLE_INIT_STATE,
  SET_PAGE_ACTIVE_VALUES,
  SET_PAGE_ACTIVE_STATUS,
  SET_STRATEGY_INIT_STATE,
  SET_USER_INFO,
  FETCH_ERROR,
} from "../../constants/ActionTypes";
import { message, Tag } from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";

export const getUserActions = (
  endpoint,
  data,
  flag,
  customerActive,
  customerUrl,
  changingCustomer
) => {
  return (dispatch,getState) => {

    switch (endpoint) {
      case "dashboard_customers": {
        /**
         * Solo durante il login per ottenere i customer attivi dell'utente.
         */
        dispatch(computeUserCustomer(endpoint, flag));
        break;
      }
      case "dashboard_pages": {
        dispatch(computeCustomerPage(customerActive, flag, changingCustomer));
        break;
      }
      case "customerActiveChange": {
        // console.log(axios.defaults.headers.common);

        // console.log("change select item");

        // console.log('entro')
        dispatch({ type: SET_CUSTOMERS_ACTIVE_STATUS, payload: "loading" });
        dispatch({ type: SET_CUSTOMERS_ACTIVE_VALUES, payload: data });
        localStorage.setItem("customer-active", JSON.stringify(data));
        dispatch({ type: SET_CUSTOMERS_ACTIVE_STATUS, payload: "complete" });
        break;
      }
      case "get_customer_services": {
        dispatch(computeCustomerService(endpoint));
        break;
      }
      case "checkCustomer": {
        dispatch(computeCheckCustomer());
        break;
      }
      case "userInfo": {
        break;
      }
      case "testInitAll": {
        dispatch({ type: SET_CHART_INIT_STATE, payload: {} });
        dispatch({ type: SET_STRATEGY_INIT_STATE, payload: {} });
        dispatch({ type: SET_USER_INIT_STATE, payload: {} });
        dispatch({ type: SET_TABLE_INIT_STATE, payload: {} });
        break;
      }
      case "exportCSV": {
        try {
          dispatch(computeExportCSV(data));
        } catch (err) {
          message.error("An error occurred during table export.");
        }
        break;
      }
      default:
    }
  };
};

export const getUserActionNew = (endpoint, template) => {
  return (dispatch) => {
    let url = `${endpoint}/`;

    axios
      .get(url)
      .then(({ data }) => {
        //console.log(data);

        switch (template) {
          case "userInfo": {
            localStorage.setItem("userInfo", JSON.stringify(data[0]));
            dispatch({ type: SET_USER_INFO, payload: data[0] });
            break;
          }
          default:
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
};

export const computeCheckCustomer = () => {
  return (dispatch, getState) => {
    // Recupero la lista dei customer per questo utente
    let list_customer = JSON.parse(localStorage.getItem("list_customer"));

    // Controllo se esiste la lista dei customer
    if (list_customer !== undefined && list_customer !== null) {
      // Controllo se il customer attivo esiste all'interno della lista
      // Altrimenti inserisco come customer il primo customer nella lista e di conseguenza la prima pagina di questo customer
      if (list_customer.includes(getState().user.customerActive.values)) {
        //console.log("UserAction: include");
        dispatch(
          getUserActions(
            "dashboard_pages",
            "",
            true,
            getState().user.customerActive.values,
            undefined,
            false
          )
        );
      } else {
        //console.log("UserAction: not include");
        dispatch({
          type: SET_CUSTOMERS_ACTIVE_VALUES,
          payload: list_customer[0],
        });
        dispatch(
          getUserActions(
            "dashboard_pages",
            "",
            true,
            list_customer[0],
            undefined,
            false
          )
        );
      }
    } else {
      // Rifare chiamata customer
    }
  };
};

/**
 * Questa funzione determina in quale pagina andare al cambio di utente.
 * Il cambio si verifica sia quando lo si effettua manualmente tramite
 * il select nella topbar, sia al login quando si imposta l'utente manualmente.
 * NON viene chiamata al cambio di customer dall'URL
 * @param {*} customerActive
 * @param {*} flag
 * @param {bool} changingCustomer determina se stiamo cambiando customer o meno.
 */
export const computeCustomerPage = (customerActive, flag, changingCustomer) => {
  return (dispatch, getState) => {
    /**
     * Salvo lo stato 'loading' su redux nelle chiamate:
     *  - customers_pages
     */
    // dispatch({ type: SET_CUSTOMERS_PAGES_STATUS, payload: "loading" });

    /**
     * Prendo da redux il nome del customer attivo
     */

    if (flag) {
      if (axios.defaults.headers.common["authorization"] === undefined) {
        axios.defaults.headers.common["authorization"] = JSON.parse(
          localStorage.getItem("authorization")
        );
      }

      /**
       * Recupero le pagina del customer attualmente attivo
       */
      axios
        .get(`/api/dashboard_pages/?customer=${customerActive}`)
        .then(({ data }) => {
          if (data.length > 0) {
            /**
             * In ordine:
             *  - Inizializzo le variabili:
             *      - list_pages (lista dei nomi delle pagine)
             *      - list_components (lista delle pagine con i propri componenti)
             *      - pages_dict (un dizionario con i nomi delle pagine e la sua icona)
             *  - Ciclo e ottengo i valori
             */
            var list_pages = [];
            var list_components = [];
            var pages_dict = {};

            data.map(function (page) {
              list_pages.push(page.page_id);
              list_components.push({
                page_id: page.page_id,
                components: page.components,
              });
              pages_dict[page.page_id] = {
                page_id: page.page_id,
                icon: page.page_icon,
              };
              return true;
            });

            /**
             * Memorizzo sul localStorage:
             *  - customer_pages
             *  - list_components
             *  - list_pages
             *  - service_active
             */

            localStorage.setItem("customer_pages", JSON.stringify(data));
            localStorage.setItem(
              "list_components",
              JSON.stringify(list_components)
            );
            localStorage.setItem("list_pages", JSON.stringify(list_pages));
            localStorage.setItem("pages_dict", JSON.stringify(pages_dict));

            /**
             * Salvo su redux lo status di complete e la lista delle pagine
             */
            dispatch({ type: SET_CUSTOMERS_PAGES_VALUES, payload: list_pages });

            /**
             * Eseguo il controllo sulla pagina attiva
             */
            // è sempre vuoto perché da qualche parte viene settato a / l'url
            let page_active = window.location.pathname.replace("/", ""); // gargabe

            // Andiamo alla prima pagina del customer solo se abbiamo cambiato customer
            // (changingCustomer === true); altrimenti no.
            if (changingCustomer) {
              localStorage.setItem("pageActive", JSON.stringify(list_pages[0]));
              dispatch({
                type: SET_PAGE_ACTIVE_VALUES,
                payload: list_pages[0],
              });
              // dispatch({ type: SET_PAGE_ACTIVE_STATUS, payload: "complete" });
            } else if (
              page_active === "" ||
              page_active === null ||
              page_active === "null"
            ) {
              // recupera pagina attiva dal local storage
              let activePage = JSON.parse(localStorage.getItem("pageActive"));

              // active page deve essere inclusa nelle pagine del customer, altrimenti
              // lo rimandiamo alla prima
              if (!list_pages.includes(activePage)) {
                activePage = list_pages[0];
              }

              localStorage.setItem("pageActive", JSON.stringify(activePage));

              dispatch({
                type: SET_PAGE_ACTIVE_VALUES,
                payload: activePage,
              });
            } else {
              localStorage.setItem("pageActive", JSON.stringify(page_active));
              dispatch({
                type: SET_PAGE_ACTIVE_VALUES,
                payload: page_active,
              });
            }

            dispatch({ type: SET_CUSTOMERS_PAGES_STATUS, payload: "complete" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
    }
  };
};

export const computeUserCustomer = (endpoint, flag) => {
  return (dispatch) => {
    /**
     * Salvo lo stato 'loading' su redux nelle chiamate:
     *  - customers
     *  - customerActive (utilizzato come customer attivo in pagina)
     */
    dispatch({ type: SET_CUSTOMERS_STATUS, payload: "loading" });
    dispatch({ type: SET_CUSTOMERS_ACTIVE_STATUS, payload: "loading" });

    if (flag) {
      axios
        .get(`/api/${endpoint}/`)
        .then(({ data }) => {
          /**
           * Controllo se l'utente ha dei customer attivi
           */
          if (data.length > 0) {
            /**
             * In ordine:
             *  - Inizializzo le variabili:
             *      - list_customer (lista dei customer attivi per questo utente)
             *      - customers_proprieties
             *      - service_active (serivizzi attivi per i customer)
             *  - Ciclo la risposta e ottengo i valori per list_customer e customers_properties
             *  - Ciclo customers_properties e otengo i servizi attivi e li salvo in service_active
             */
            var list_customer = [];
            var customers_properties = {};
            var service_active = {};

            data.map(function (customer) {
              list_customer.push(customer.customer_id);
              customers_properties[customer.customer_id] = customer;
              return true;
            });

            customers_properties[list_customer[0]].services.map(function (
              item
            ) {
              service_active[item.service_id] = item;
              return true;
            });

            /**
             * Creo una variabile results che contenga tutti i valori
             */
            var results = {
              customersProperties: customers_properties,
              listCustomer: list_customer,
              customerActive: list_customer[0],
            };

            /**
             * Memorizzo sul localStorage:
             *  - dashboard_customers
             *  - customers_properties
             *  - list_customer
             *  - service_active
             */
            localStorage.setItem("dashboard_customers", JSON.stringify(data));
            localStorage.setItem(
              "customers_properties",
              JSON.stringify(customers_properties)
            );
            localStorage.setItem(
              "list_customer",
              JSON.stringify(list_customer)
            );
            localStorage.setItem(
              "service_active",
              JSON.stringify(service_active)
            );

            /**
             * Memorizzo su Redux:
             *  - Results
             *  - REFRESH
             */
            dispatch({ type: SET_CUSTOMERS_VALUES, payload: results });

            /**
             * Inizializzo la variabile che conterrà le query string
             */
            const queryString = require("query-string");
            let parsed = queryString.parse(window.location.search);

            if (
              parsed["customer"] === undefined ||
              parsed["customer"] === "null" ||
              parsed["customer"] === null ||
              parsed["customer"] === ""
            ) {
              dispatch(computeCustomerPage(list_customer[0], true));
              localStorage.setItem(
                "customer-active",
                JSON.stringify(list_customer[0])
              );
              dispatch({
                type: SET_CUSTOMERS_ACTIVE_VALUES,
                payload: list_customer[0],
              });
              dispatch({
                type: SET_CUSTOMERS_ACTIVE_STATUS,
                payload: "complete",
              });
            } else {
              dispatch(computeCustomerPage(parsed["customer"], flag));
              dispatch({
                type: SET_CUSTOMERS_ACTIVE_VALUES,
                payload: parsed["customer"],
              });
              dispatch({
                type: SET_CUSTOMERS_ACTIVE_STATUS,
                payload: "complete",
              });
              localStorage.setItem(
                "customer-active",
                JSON.stringify(parsed["customer"])
              );
            }
          }
        })
        .catch(function (error) {
          dispatch({ type: SET_CUSTOMERS_STATUS, payload: "failed" });
        });
    } else {
    }
  };
};

export const computeCustomerService = (endpoint) => {
  return (dispatch, getState) => {
    const { customerActive } = getState().user;

    dispatch({ type: SET_CUSTOMERS_SERVICE_STATUS, payload: "loading" });

    axios
      .get(`/api/${endpoint}?customer=${customerActive.values}`)
      .then(({ data }) => {
        let new_results = [];

        data.map((item, i) => {
          new_results.push({
            ...item,
            key: i,
            id: i,
          });

          return true; // React
        });

        const columns = [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            width: "30%",
          },

          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: "60%",
          },
          {
            title: "Active",
            key: "active",
            dataIndex: "active",
            width: "10%",
            render: (text, record) =>
              text === true ? (
                <Tag color="blue">Active</Tag>
              ) : (
                <Tag color="red">Inactive</Tag>
              ),
          },
        ];

        dispatch({
          type: SET_CUSTOMERS_SERVICE_VALUES,
          payload: { columns: columns, data: new_results },
        });

        dispatch({ type: SET_CUSTOMERS_SERVICE_STATUS, payload: "complete" });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

const computeExportCSV = (data) => {
  return (dispatch, getState) => {
    /*
            endpoint: "/dashboard/api/components/get_prediction_report"
            id: "ads_report"
        */
    console.log("Data: ", data);
    //console.log(getState().table.exportTable.values[data.id]);
    // {url: "http://127.0.0.1:8000//dashboard/api/components/get_prediction_report?converted=all&customer=lafeltrinelli&days=29&device=all&start_date=2020-11-16&end_date=2020-12-15&page=1"}

    const requestBody = {
      url: getState().table.exportTable.values[data.id].url,
      // get rid of customer name from component_id (data.id)
      component_id: data.father === null ? data.id.split("-")[0] : data.father,
      is_child: data.is_child,
      endpoint: data.endpoint,
      type: "table",
    };

    axios
      .post(`dashboard/api/components/download`, requestBody, {
        responseType: "blob",
      })
      .then((result) => {
        //console.log(result);
        const fileURL = window.URL.createObjectURL(new Blob([result.data]));
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", "audience_data_export.csv");
        document.body.appendChild(fileLink);
        fileLink.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
