import { message } from 'antd';

function success(mss){
    message.success(mss);
};

function error(mss){
    message.error(mss);
};

function warning(mss){
    message.warning(mss);
};

export default {
    success,
    error,
    warning
};