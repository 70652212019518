import {
	SET_STRATEGIES_VALUES,
	SET_STRATEGIES_STATUS,
	SET_STRATEGY_STEP_VALUES,
	SET_STRATEGY_STEP_STATUS,
	SET_AUDIENCE_STEP_VALUES,
	SET_AUDIENCE_STEP_STATUS,
	SET_GOAL_STEP_VALUES,
	SET_GOAL_STEP_STATUS,
	SET_ACTIVATION_STEP_STATUS,
	SET_ACTIVATION_STEP_VALUES,
	SET_STRATEGY_INIT_STATE,
} from "../../constants/ActionTypes";

const INIT_STATE = {
	strategiesStep: {
		values: null,
		status: "idle",
	},
	audienceStep: {
		values: null,
		status: "idle",
	},
	goalStep: {
		values: null,
		status: "idle",
	},
	activationStep: {
		values: null,
		status: "idle",
	},
	strategies: {
		values: null,
		status: "idle"
	}
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {

		case SET_STRATEGY_INIT_STATE: {
      return {
        ...state,
        strategies: {
          values: null,
          status: "idle",
        },
      };
    }

		// Strategy
		case SET_STRATEGIES_STATUS: {
			return {
				...state,
				strategies: {
					...state.strategies,
					status: action.payload,
				},
			};
		}

		case SET_STRATEGIES_VALUES: {
			return {
				...state,
				strategies: {
					...state.strategies,
					values: action.payload,
				},
			};
		}

		// Strategy
		case SET_STRATEGY_STEP_STATUS: {
			return {
				...state,
				strategiesStep: {
					...state.strategiesStep,
					status: action.payload,
				},
			};
		}

		case SET_STRATEGY_STEP_VALUES: {
			return {
				...state,
				strategiesStep: {
					...state.strategiesStep,
					values: action.payload,
				},
			};
		}

		// Strategy
		case SET_AUDIENCE_STEP_STATUS: {
			return {
				...state,
				audienceStep: {
					...state.audienceStep,
					status: action.payload,
				},
			};
		}

		case SET_AUDIENCE_STEP_VALUES: {
			return {
				...state,
				audienceStep: {
					...state.audienceStep,
					values: action.payload,
				},
			};
		}

		// Goal
		case SET_GOAL_STEP_STATUS: {
			return {
				...state,
				goalStep: {
					...state.goalStep,
					status: action.payload,
				},
			};
		}

		case SET_GOAL_STEP_VALUES: {
			return {
				...state,
				goalStep: {
					...state.goalStep,
					values: action.payload,
				},
			};
		}

		// Activation
		case SET_ACTIVATION_STEP_STATUS: {
			return {
				...state,
				activationStep: {
					...state.activationStep,
					status: action.payload,
				},
			};
		}

		case SET_ACTIVATION_STEP_VALUES: {
			return {
				...state,
				activationStep: {
					...state.activationStep,
					values: action.payload,
				},
			};
		}

		default:
			return state;
	}
};
