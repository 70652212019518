import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  FILTERS,
  CUSTOMER,
  LOGIN_FAILED,
  USER_ACCESS_TOKEN_SET,
  USER_REFRESH_TOKEN_SET,
  ACCESS_TOKEN_VALIDITY,
} from "../../constants/ActionTypes";
import axios from "util/Api";
import { getUserActionNew, getUserActions } from "./UserActions";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignUp = ({ email, password, name }) => {
  //console.log(email, password);
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/register", {
        email: email,
        password: password,
        name: name,
      })
      .then(({ data }) => {
        //console.log("data:", data);
        if (data.result) {
          localStorage.setItem(
            "token",
            JSON.stringify(data.token.access_token)
          );
          axios.defaults.headers.common["authorization"] =
            "Bearer " + data.token.access_token;
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_TOKEN_SET, payload: data.token.access_token });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          //console.log("payload: data.error", data.error);
          dispatch({ type: FETCH_ERROR, payload: "Network Error" });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log("Error****:", error.message);
      });
  };
};

export const userSignIn = ({ email, password }) => {
  return (dispatch, getState) => {
    axios
      .post("/api/token/", {
        email: email,
        password: password,
      })
      .then(({ data }) => {
        //console.log("userSignIn: ", data);
        if (data) {
          /**
           * Memorizzo sul localStorage:
           *  - EMAIL
           *  - TOKEN
           *  - REFRESH
           */
          localStorage.setItem("email", JSON.stringify(email));
          localStorage.setItem("accessToken", JSON.stringify(data.access));
          localStorage.setItem("refreshToken", JSON.stringify(data.refresh));

          /**
           * Memorizzo su Redux:
           *  - TOKEN
           *  - REFRESH
           */
          dispatch({ type: USER_ACCESS_TOKEN_SET, payload: data.access });
          dispatch({ type: USER_REFRESH_TOKEN_SET, payload: data.refresh });

          /**
           * Memorizzo base endpoint su AXIOS
           */
          axios.defaults.headers.common["authorization"] =
            "Bearer " + data.access;

          /**
           * Effettuo chiamate base:
           *  - dashboard_customers
           *  - dashboard_pages
           */
          dispatch(getUserActionNew("profile", "userInfo"));
          dispatch(getUserActions("dashboard_customers", [], true));

          dispatch({ type: ACCESS_TOKEN_VALIDITY, payload: true });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        //console.log(error)

        dispatch({ type: FETCH_ERROR, payload: error });

        dispatch({ type: LOGIN_FAILED, payload: true });

        //console.log("Error****:", error.message);
      });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .post("auth/me")
      .then(({ data }) => {
        //console.log("userSignIn: ", data);
        if (data.result) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: USER_DATA, payload: data.user });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        //console.log("Error****:", error.message);
      });
  };
};

export const userCustomer = () => {
  return (dispatch) => {
    axios
      .get("/api/dashboard_customers/")
      .then(({ data }) => {
        if (data.length > 0) {
          localStorage.setItem("dashboard_customers", JSON.stringify(data));

          var list_customer = [];
          var customers_properties = {};

          data.map(function (customer) {
            list_customer.push(customer.customer_id);
            customers_properties[customer.customer_id] = customer;
          });

          localStorage.setItem(
            "customers_properties",
            JSON.stringify(customers_properties)
          );
          localStorage.setItem("list_customer", JSON.stringify(list_customer));

          // Customer Attivo
          localStorage.setItem(
            "customer-active",
            JSON.stringify(list_customer[0])
          );

          // Servizi attivi
          var service_active = {};

          customers_properties[list_customer[0]].services.map(function (item) {
            service_active[item.service_id] = item;
          });

          localStorage.setItem(
            "service_active",
            JSON.stringify(service_active)
          );
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const customerPages = () => {
  return (dispatch) => {
    const customer_id = JSON.parse(localStorage.getItem("customer-active"));
    //console.log(customer_id)
    dispatch({ type: FETCH_START });
    axios
      .get(`/api/dashboard_pages?customer=${customer_id}`)
      .then(({ data }) => {
        //console.log(data)
        localStorage.setItem("customer_pages", JSON.stringify(data));

        var list_pages = [];
        var list_components = [];

        data.map(function (page) {
          list_pages.push(page.page_id);
          list_components.push({
            page_id: page.page_id,
            components: page.components,
          });

          return true;
        });

        localStorage.setItem(
          "list_components",
          JSON.stringify(list_components)
        );
        localStorage.setItem("list_pages", JSON.stringify(list_pages));
      })
      .catch(function (error) {});
  };
};

export const userServices = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    axios
      .get("/api/token/")
      .then(({ data }) => {})
      .catch(function (error) {});
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    localStorage.clear();
    dispatch({ type: SIGNOUT_USER_SUCCESS });
    dispatch({ type: ACCESS_TOKEN_VALIDITY, payload: false });
  };
};

// Update Filtri
export const filters = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    localStorage.setItem("filters", JSON.stringify(data));
    localStorage.setItem("updateDate", JSON.stringify(true));
    dispatch({ type: FILTERS, payload: data });
  };
};

export const customer = (data) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    localStorage.setItem("customer-active", JSON.stringify(data));
    const customers_properties = JSON.parse(
      localStorage.getItem("customers_properties")
    );

    var service_active = {};

    customers_properties[data].services.map(function (item) {
      service_active[item.service_id] = item;
    });

    localStorage.setItem("service_active", JSON.stringify(service_active));

    const pages = JSON.parse(localStorage.getItem("list_pages"));
    
    console.log('############################# 5', pages)

    window.history.replaceState({}, null, `/${pages[0]}`);
    window.location.reload();

    dispatch(customerPages());
    dispatch({ type: CUSTOMER, payload: data });
  };
};

export const recover = (data) => {
  return (dispatch) => {
    axios
      .post(`/api/reset_password/`, {
        email: data,
      })
      .then(function (response) {})
      .catch(function (error) {
        console.log(error);
      });
  };
};

export const newPassword = (data) => {
  return (dispatch) => {
    axios
      .post(`/api/reset_password/confirm/`, {
        token: data.token,
        password: data.password,
      })
      .then(function (response) {
        window.location.href = "/signin";
      })
      .catch(function (error) {
        console.log(error);
      });
  };
};
