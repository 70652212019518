import React from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilterData } from "../../appRedux/actions/FilterActions";

export const FilterDevice = () => {
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const dispatch = useDispatch();

  const { filterDevice } = useSelector((state) => state.filters);

  const onChange = (e) => {
    dispatch(getFilterData("filterDevice", e.target.value));
  };

  return (
    <Radio.Group onChange={onChange} value={filterDevice.values}>
      <Radio style={radioStyle} value={"all"}>
        All
      </Radio>
      <Radio style={radioStyle} value={"mobile"}>
        Smartphone
      </Radio>
      <Radio style={radioStyle} value={"tablet"}>
        Tablet
      </Radio>
      <Radio style={radioStyle} value={"laptop"}>
        PC
      </Radio>
    </Radio.Group>
  );
};
