import React from "react";
import { Form } from "@ant-design/compatible";
import "@ant-design/compatible/assets/index.css";
import { Button, Input, message } from "antd";
//import {useDispatch} from "react-redux";

import IntlMessages from "util/IntlMessages";
import { newPassword } from "../appRedux/actions";
import { useDispatch } from "react-redux";

const FormItem = Form.Item;

const success = () => {
  message.success("This is a success message");
};

const error = () => {
  message.error("This is an error message");
};

const NewPassword = (props) => {
  const dispatch = useDispatch();

  // query string
  const queryString = require("query-string");
  const parsedHash = queryString.parse(window.location.search);

  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      let psw = values.password;
      let Max = 0;
      let Min = 0;
      let N = 0;
      let C = psw.length;

      for (var i = 0; i < psw.length; i++) {
        let item = psw.charAt(i);
        if (!Number.isNaN(item * 1)) {
          N++;
        } else if (item === item.toLowerCase()) {
          Min++;
        } else if (item === item.toUpperCase()) {
          Max++;
        }
      }

      if (Min > 0 && Max > 0 && N > 0 && C >= 8) {
        dispatch(
          newPassword({ token: parsedHash.token, password: values.password })
        );
      } else {
        error();
      }
    });
  };

  const { getFieldDecorator } = props.form;

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/login.png")} alt="nature" />
            </div>
            <div className="gx-app-logo-wid">
              <h1>
                <IntlMessages id="app.userAuth.signIn" />
              </h1>
              <p></p>
              <p></p>
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")} />
            </div>
          </div>
          <div className="gx-app-login-content">
            <Form
              onSubmit={handleSubmit}
              className="gx-signin-form gx-form-row0"
            >
              <FormItem>
                {getFieldDecorator("password", {
                  initialValue: "",
                  rules: [
                    { required: true, message: "Please input your Password!" },
                  ],
                })(<Input type="password" placeholder="Password" />)}
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  <IntlMessages id="app.userAuth.send" />
                </Button>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

const WrappedNormalLoginForm = Form.create()(NewPassword);

export default WrappedNormalLoginForm;
