import React from "react";
import axios from "util/Api";
import { Line, XAxis, YAxis, Pie, Bar, Label, Text } from "recharts";
import {
  SET_CHART_STATUS,
  SET_SANKEY_VALUES,
  SET_SANKEY_STATUS,
  SET_LINE_CHART_VALUES,
  SET_LINE_CHART_STATUS,
  SET_HEAT_MAP_CHART_VALUES,
  SET_HEAT_MAP_CHART_STATUS,
  SET_LINE_EVENT_CHART_VALUES,
  SET_LINE_EVENT_CHART_STATUS,
  SET_TOTALS_ELEMETS_STATUS,
  SET_TOTALS_ELEMETS_VALUES,
  SET_LINE_CHART_GENERIC_STATUS,
  SET_TABLE_SIMPLE_VALUES,
  SET_TABLE_SIMPLE_STATUS,
  FETCH_ERROR,
  SET_PIE_CHART_GENERIC_STATUS,
  SET_PIE_CHART_GENERIC_VALUES,
  SET_LINE_TEST_STATUS,
  SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
  SET_LINE_CHART_GENERIC_MULTIPLE_VALUES,
  SET_TOTALS_PREDICTION_ELEMETS_STATUS,
  SET_TOTALS_PREDICTION_ELEMETS_VALUES,
  SET_USER_TECH_TRAVEL_STATUS,
  SET_USER_TECH_TRAVEL_VALUES,
  SET_BAR_GENERIC_STATUS,
  SET_BAR_GENERIC_VALUES,
  SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES,
  SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
  SET_TOTALS_GENERIC_STATUS,
  SET_TOTALS_GENERIC_VALUES,
  SET_BAR_CHART_GENERIC_STATUS,
  SET_BAR_CHART_GENERIC_VALUES,
  SET_CONVERSIONS_GENERIC_VALUES,
  SET_CONVERSIONS_GENERIC_STATUS_TRENDS,
} from "../../constants/ActionTypes";
import { Card, Col, Row } from "antd";
import moment from "moment";
import ut from "../../util/utilities";
import custom from "../../util/CustomRecharts";
import IntlMessages from "../../util/IntlMessages";
import { RightCircleOutlined } from "@ant-design/icons";
import { result } from "lodash";
import {
  PREDICTION_COLOR,
  PRODUCT_COLOR,
} from "../../constants/Color/VarColor";

const gridStyle = {
  width: "100%",
  textAlign: "center",
};

const gridStyle_product = {
  width: "50%",
  textAlign: "center",
};

export const getChartData = (
  endpoint,
  template,
  properties,
  chart,
  event,
  chiave,
  campaign,
  scale,
  componentTitle
) => {
  return (dispatch, getState) => {
    const { start_date, end_date } = getState().filters.dates;

    const filterDevice2 = getState().filters.filterDevice.values;
    const filterConversions = getState().filters.filterConversions.values;

    const days = getState().filters.days.values;

    const customer = getState().user.customerActive.values;

    const pageActive = JSON.parse(localStorage.getItem("pageActive"));

    const interest = JSON.parse(localStorage.getItem("interest"));

    let url = "";

    if (pageActive === "interest_detail") {
      const queryString = require("query-string");
      let parsed = queryString.parse(window.location.search);

      let name =
        interest !== null && interest !== undefined
          ? interest
          : parsed["interest"];
      name = name.split("> ");

      let interestFather = "";
      let interestSon = name[name.length - 1].replace("&", "%26");

      if (name.length > 2) {
        name.pop();
        for (var i in name) {
          if (i < name.length - 1) {
            interestFather =
              interestFather + name[i].replace("&", "%26") + "> ";
          } else {
            interestFather = interestFather + name[i].replace("&", "%26");
          }
        }
      } else if (name.length > 1) {
        name.pop();
        interestFather = name[0].replace("&", "%26");
      } else {
        interestFather = "";
      }

      url = `${endpoint}?converted=${filterConversions}&customer=${customer}&days=30&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&filters=&event=&father=${interestFather}&interest=${interestSon}`;
    } else if (pageActive === "dem_cart" || pageActive === "dem_cart_promo") {
      url = `${endpoint}?campaign=${campaign}&converted=all&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}`;
    } else {
      url = `${endpoint}?converted=${filterConversions}&customer=${customer}&days=${days}&device=${filterDevice2}&start_date=${start_date}&end_date=${end_date}&filters=&event=${event}`;

      // possibilità di aggiungere filtri all'URL sulla base del componente
      switch (componentTitle) {
        case "TopCompanies":
          url += "&top=1";
          break;
        default:
          break;
      }
    }

    axios
      .get(url)
      .then(({ data }) => {
        let chartContent = data;

        switch (template) {
          case "BarGeneric": {
            if (data !== undefined) {
              dispatch(
                computeBarGeneric(chartContent["results"], start_date, end_date)
              );
            } else {
              dispatch({ type: SET_BAR_GENERIC_STATUS, payload: "failed" });
            }
            break;
          }
          case "LineTest": {
            if (data !== undefined) {
              //dispatch(computeLineTest(data, properties))
              dispatch(
                computeConversionGeneric(data, properties, chiave, scale)
              );
            } else {
              dispatch({ type: SET_LINE_TEST_STATUS, payload: "failed" });
            }
            break;
          }
          case "LineChartGenericMultiple": {
            if (chartContent !== undefined) {
              dispatch(
                computeLineChartGenericMultiple(
                  chartContent,
                  properties,
                  chiave,
                  scale
                )
              );
            } else {
              dispatch({
                type: SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "LineChart": {
            if (chartContent !== undefined) {
              dispatch(
                computeLineChart(chartContent["results"], properties, chiave)
              );
            } else {
              dispatch({ type: SET_LINE_CHART_STATUS, payload: "failed" });
            }
            break;
          }
          case "LineChartGeneric": {
            if (chartContent !== undefined) {
              dispatch(
                computeLineChartGeneric(
                  chartContent["results"],
                  properties,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_LINE_CHART_GENERIC_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "LineEventChart": {
            if (chartContent !== undefined) {
              dispatch(computeLineEventChart(chartContent));
            } else
              dispatch({
                type: SET_LINE_EVENT_CHART_STATUS,
                payload: "failed",
              });
            break;
          }
          case "HeatMapChart": {
            if (
              chartContent !== undefined &&
              chartContent["results"].length > 0
            ) {
              dispatch(computeHeatMapChart(chartContent["results"]));
            } else
              dispatch({ type: SET_HEAT_MAP_CHART_STATUS, payload: "failed" });
            break;
          }
          case "SankeyChart": {
            if (
              chartContent !== undefined &&
              chartContent["results"].length > 0
            ) {
              dispatch(computeSankeyChart(chartContent["results"]));
            } else dispatch({ type: SET_SANKEY_STATUS, payload: "failed" });
            break;
          }
          case "ProductsTotals": {
            if (
              chartContent !== undefined &&
              chartContent["results"].length > 0
            ) {
              dispatch(computeProductsTotals(chartContent["results"]));
            } else {
              dispatch({
                type: SET_TOTALS_PREDICTION_ELEMETS_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "PredictionTotals": {
            if (chartContent !== undefined) {
              dispatch(computeProductsTotalsPrediction(chartContent));
            } else {
              dispatch({
                type: SET_TOTALS_PREDICTION_ELEMETS_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "TableSimple": {
            if (chartContent !== undefined) {
              dispatch(computeTableSimple(chartContent["results"]));
            } else {
              dispatch({ type: SET_TOTALS_ELEMETS_STATUS, payload: "failed" });
            }
            break;
          }
          case "PieChartGeneric": {
            if (
              chartContent !== undefined &&
              chartContent["results"].length > 0
            ) {
              dispatch(
                computePieChartGeneric(
                  chartContent["results"],
                  properties,
                  componentTitle,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_PIE_CHART_GENERIC_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "UserTechTravel": {
            if (chartContent !== undefined) {
              dispatch(computeUserTechTravel(chartContent["results"]));
            } else {
              dispatch({
                type: SET_USER_TECH_TRAVEL_STATUS,
                payload: "failed",
              });
            }
            break;
          }
          case "LineChartGenericOneScale": {
            if (chartContent !== undefined) {
              dispatch(
                computeLineChartGenericOneScale(
                  chartContent,
                  properties,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
                payload: { values: "failed", key: chiave },
              });
            }
            break;
          }
          case "LineChartGenericOneScaleAccuracy": {
            if (chartContent !== undefined) {
              dispatch(
                computeLineChartGenericOneScaleAccuracy(
                  chartContent["results"],
                  properties,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
                payload: { values: "failed", key: chiave },
              });
            }
            break;
          }
          // GENERIC
          case "TotalsGeneric": {
            if (chartContent !== undefined) {
              dispatch(
                computeTotalsGeneric(
                  chartContent["results"],
                  properties,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_TOTALS_GENERIC_STATUS,
                payload: { values: "failed", key: chiave },
              });
            }
            break;
          }
          // GENERIC
          case "BarChartGeneric": {
            if (
              chartContent !== undefined &&
              chartContent["results"].length > 0
            ) {
              dispatch(
                computeBarChartGeneric(
                  chartContent["results"],
                  properties,
                  chiave
                )
              );
            } else {
              dispatch({
                type: SET_BAR_CHART_GENERIC_STATUS,
                payload: { values: "failed", key: chiave },
              });
            }
            break;
          }
          default:
        }
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: FETCH_ERROR, payload: error });
      });
  };
};

export const getTableData = (
  endpoint,
  secondary_endpoint,
  template,
  properties,
  chart,
  event
) => {
  return (dispatch) => {
    const filters = JSON.parse(localStorage.getItem("filters"));
    const customer = JSON.parse(localStorage.getItem("customer-active"));

    if (endpoint === undefined) {
      var url = `${secondary_endpoint}?customer=${customer}&start_date=${filters.start_date}&end_date=${filters.end_date}`;
    } else {
      url = `${endpoint}?customer=${customer}&start_date=${filters.start_date}&end_date=${filters.end_date}`;
    }

    if (properties.hasOwnProperty("additional_query_params")) {
      var events = properties.additional_query_params.event;
      for (var i = 0; i < events.length; i++) {
        url = url.concat("&event=", events[i]);
      }
    }

    axios
      .get(url)
      .then(({ data }) => {
        //dispatch({type: SET_CHART_STATUS, payload: 'complete'})

        const chartContent = data["results"]["content"];

        switch (template) {
          case "TableSimple": {
            if (chartContent !== undefined && chartContent.length > 0) {
              dispatch(computeTableSimple(chartContent));
            } else {
              dispatch({ type: SET_TOTALS_ELEMETS_STATUS, payload: "failed" });
            }
            break;
          }
          default:
        }
      })
      .catch((error) => {
        dispatch({ type: SET_CHART_STATUS, payload: "failed" });
      });
  };
};

export const CustomizedLabel = (props) => {
  //console.log(props)

  //<text cy="140" offset="5" x="1414" y="153" transform="rotate(90, 1414, 153)" class="recharts-text label-converted_users" text-anchor="middle"><tspan x="1414" dy="0.355em">Converted</tspan></text>
  return (
    <Text
      x={props.value.x}
      y={props.value.y}
      dx={0}
      dy={0}
      textAnchor="middle"
      width={300}
      height={props.viewBox.heght}
      transform={props.value.rotation}
      className={props.value.className}
    >
      {props.value.label}
    </Text>
  );
};

function getTotalNumber(values) {
  //console.log(stato)

  var totals = [];

  var results = values;

  results.map(function (item) {
    totals.push({
      date: item["Date"],
      "Unique Users": item["Unique Users"],
      Converted: item["Converted"],
      conv_rate: ut.getConversionRate(item["Converted"], item["Unique Users"]),
    });
    return true; // React
  });

  return totals;
}

function freccia(a, b) {
  var c = ut.get_formatted_perc_ratio(a - b, b);
  if (c > ut.get_formatted_perc_ratio(0)) {
    return (
      <div className="value">
        <div className="g-arrow">
          <div className="valore">{"+" + c}</div>
        </div>
        <div className="dataRange">{"vs previous period"}</div>
      </div>
    );
  } else if (c < ut.get_formatted_perc_ratio(0)) {
    return (
      <div className="value">
        <div className="r-arrow">
          <div className="valore">{c}</div>
        </div>
        <div className="dataRange">{"vs previous period"}</div>
      </div>
    );
  } else if (c === ut.get_formatted_perc_ratio(0)) {
    return (
      <div className="value">
        <div>
          <div className="valore">{c}</div>
        </div>
        <div className="dataRange">{"vs previous period"}</div>
      </div>
    );
  }
}

function getTotalNumber2(values, dates) {
  var res_dates = [];
  var res_users = [];
  var res_converted = [];
  var res_conversion_rate = [];
  var tot_users = 0;
  var tot_converted = 0;
  var res_dates_prev = [];

  var tot_users_prev = 0;
  var tot_converted_prev = 0;
  var avg_conversion_rate = 0;

  var results = values["results"];

  if (results !== undefined && results.length > 0) {
    results.forEach(function (item) {
      res_dates.push(item["Date"]);
      res_users.push(item["Unique Users"]);
      res_converted.push(item["Converted"]);
      let conversion_rate = ut.getConversionRate(
        item["Converted"],
        item["Unique Users"]
      );
      res_conversion_rate.push(conversion_rate);
      tot_users += item["Unique Users"];
      tot_converted += item["Converted"];
    });
  }
  var prev_results = values["prev"];
  //console.log('prev_results')
  //console.log(prev_results)
  if (prev_results !== undefined && prev_results.length > 0) {
    //console.log('KO')
    prev_results.forEach(function (item) {
      res_dates_prev.push(item["Date"]);
      tot_users_prev += item["Unique Users"];
      tot_converted_prev += item["Converted"];
    });
  }

  //Calcolo Medie

  var days = res_dates.length;
  var days_prev = res_dates_prev.length;

  var avg_users = 0;
  var avg_converted = 0;
  var avg_users_prev = 0;
  var avg_converted_prev = 0;

  if (days > 0) {
    avg_users = Math.round(tot_users / days);
    avg_converted = Math.round(tot_converted / days);
  }

  if (days_prev > 0) {
    avg_users_prev = Math.round(tot_users_prev / days_prev);
    avg_converted_prev = Math.round(tot_converted_prev / days_prev);
  }

  avg_conversion_rate = ut.getConversionRate(avg_converted, avg_users);
  var avg_conversion_rate_prev = ut.getConversionRate(
    avg_converted_prev,
    avg_users_prev
  );

  var Converted = avg_converted;

  var end_date = dates.end_date;
  var start_date = dates.start_date;

  //console.log(tot_users)
  // return (
  //   <div class="element_1">
  //     <div className="title_div title-users" id="avg_unique_users">
  //       <RightCircleOutlined className="icon-users" />
  //       AVG. DAILY UNIQUE USERS
  //     </div>
  //     <div className="elements">
  //       <div className="number">{ut.format_number(avg_users)}</div>
  //       <div className="percentage">{freccia(avg_users, avg_users_prev)}</div>
  //     </div>
  //     <div className="date date-conversion-div">
  //       <div className="actual-date">
  //         {moment(start_date).format("DD MMM YYYY") +
  //           " - " +
  //           moment(end_date).format("DD MMM YYYY")}
  //       </div>
  //       <div className="past-date">
  //         {moment(res_dates_prev[0]).format("DD MMM YYYY") +
  //           " - " +
  //           moment(res_dates_prev[days_prev - 1]).format("DD MMM YYYY")}
  //       </div>
  //     </div>
  //     <div className="title_div title-conversion-rate" id="avg_daily_conv_rate">
  //       <RightCircleOutlined className="icon-conversion-rate" /> AVG. DAILY
  //       CONVERSION RATE
  //     </div>
  //     <div className="elements">
  //       <div className="number">
  //         {ut.format_number(avg_conversion_rate) + "%"}
  //       </div>
  //       <div className="percentage">
  //         {freccia(avg_conversion_rate, avg_conversion_rate_prev)}
  //       </div>
  //     </div>
  //     <div className="date date-conversion-div">
  //       <div className="actual-date">
  //         {moment(start_date).format("DD MMM YYYY") +
  //           " - " +
  //           moment(end_date).format("DD MMM YYYY")}
  //       </div>
  //       <div className="past-date">
  //         {moment(res_dates_prev[0]).format("DD MMM YYYY") +
  //           " - " +
  //           moment(res_dates_prev[days_prev - 1]).format("DD MMM YYYY")}
  //       </div>
  //     </div>
  //     <div className="title_div title-converted" id="avg_daily_conv_users">
  //       <RightCircleOutlined className="icon-converted" /> AVG. DAILY CONVERTED
  //       USERS
  //     </div>
  //     <div className="elements">
  //       <div className="number">{ut.format_number(Converted, 2)}</div>
  //       <div className="percentage">
  //         {freccia(tot_converted, tot_converted_prev)}
  //       </div>
  //     </div>
  //     <div className="date date-conversion-div">
  //       <div className="actual-date">
  //         {moment(start_date).format("DD MMM YYYY") +
  //           " - " +
  //           moment(end_date).format("DD MMM YYYY")}
  //       </div>
  //       <div className="past-date">
  //         {moment(res_dates_prev[0]).format("DD MMM YYYY") +
  //           " - " +
  //           moment(res_dates_prev[days_prev - 1]).format("DD MMM YYYY")}
  //       </div>
  //     </div>
  //   </div>
  // );

  return (
    <Card className="card-trend-prev">
      <Card.Grid
        hoverable={false}
        style={gridStyle}
        className="card-trend-value-users"
      >
        <div className="title_div title-users" id="avg_unique_users">
          AVG. DAILY UNIQUE USERS
        </div>
        <div className="elements">
          <div className="number">{ut.format_number(avg_users)}</div>
          <div className="percentage">{freccia(avg_users, avg_users_prev)}</div>
        </div>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={gridStyle}
        className="card-trend-value-converted"
      >
        <div className="title_div title-converted" id="avg_daily_conv_users">
          AVG. DAILY CONVERTED USERS
        </div>
        <div className="elements">
          <div className="number">{ut.format_number(Converted, 2)}</div>
          <div className="percentage">
            {freccia(tot_converted, tot_converted_prev)}
          </div>
        </div>
      </Card.Grid>
      <Card.Grid
        hoverable={false}
        style={gridStyle}
        className="card-trend-value-conv_rate"
      >
        <div
          className="title_div title-conversion-rate"
          id="avg_daily_conv_rate"
        >
          AVG. DAILY CONVERSION RATE
        </div>
        <div className="elements">
          <div className="number">
            {ut.format_number(avg_conversion_rate) + "%"}
          </div>
          <div className="percentage">
            {freccia(avg_conversion_rate, avg_conversion_rate_prev)}
          </div>
        </div>
      </Card.Grid>
      <Card.Grid hoverable={false} style={gridStyle}>
        <div className="date">
          {moment(start_date).format("DD MMM YYYY") +
            " - " +
            moment(end_date).format("DD MMM YYYY")}
        </div>
      </Card.Grid>
    </Card>
  );
}

const computeConversionGeneric = (content, properties, chiave, scale) => {
  return (dispatch, getState) => {
    dispatch({
      type: SET_CONVERSIONS_GENERIC_STATUS_TRENDS,
      payload: { values: "loading", key: chiave },
    });

    let generic_xAxis = [];
    let generic_lines = [];
    let response = {};

    const dates = getState().filters.dates;

    try {
      let date = [];

      let conversion_rate = getTotalNumber(content["results"]);

      content.results.map(function (item) {
        date.push(item.date);
        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );

      /* TEST */
      for (let i in scale) {
        generic_xAxis.push(
          <YAxis
            className={scale[i].className}
            dataKey={scale[i].dataKey}
            width={90}
            orientation={scale[i].orientation}
            stroke={scale[i].color}
            fill={scale[i].color}
            strokeWidth={2}
            yAxisId={scale[i].yAxisId}
            tick={custom.CustomTickChartGeneric}
            allowDecimals={true}
            //interval={'preserveEnd'}
            //domain={scale[i].domain !== undefined ? [scale[i].domain.min, scale[i].domain.max] : [0,'auto']}
            //domain={[0,'auto']}
          >
            <Label
              className={scale[i].className}
              angle={scale[i].orientation === "right" ? 90 : -90}
            >
              {scale[i].label}
            </Label>
          </YAxis>
        );
      }

      for (let item in properties) {
        // generic_xAxis.push(
        //     <YAxis id={properties[item].className}
        //         dataKey={properties[item].dataKey}
        //         orientation={properties[item].orientation}
        //         stroke={properties[item].color}
        //         yAxisId={properties[item].yAxisId}
        //         tick={custom.CustomTickChartGeneric} />
        // )
        if (properties[item].barSize !== undefined) {
          generic_lines.push(
            <Bar //className={properties[item].className}
              dataKey={properties[item].dataKey}
              barSize={properties[item].barSize}
              fill={properties[item].color}
              stroke={properties[item].color}
              strokeWidth={2}
              yAxisId={properties[item].yAxisId}
            />
          );
        } else {
          generic_lines.push(
            <Line
              className={properties[item].className}
              dataKey={properties[item].dataKey}
              barSize={properties[item].barSize}
              stroke={properties[item].color}
              fill={properties[item].color}
              strokeWidth={2}
              yAxisId={properties[item].yAxisId}
              type="monotone"
            />
          );
        }
      }

      response = {
        values: conversion_rate,
        totals: getTotalNumber2(content, dates),
        xAxis: generic_xAxis,
        lines: generic_lines,
      };
    } catch (error) {
      console.log("failed");
      dispatch({
        type: SET_CONVERSIONS_GENERIC_STATUS_TRENDS,
        payload: { values: "loading", key: chiave },
      });
    }

    dispatch({
      type: SET_CONVERSIONS_GENERIC_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_CONVERSIONS_GENERIC_STATUS_TRENDS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeLineChartGenericMultiple = (
  content,
  properties,
  chiave,
  scale
) => {
  return (dispatch) => {
    dispatch({
      type: SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let generic_xAxis = [];
    let generic_lines = [];
    let response = {};

    const pageActive = JSON.parse(localStorage.getItem("pageActive"));

    Object.size = function (obj) {
      var size = 0,
        key;
      for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
      }

      return size;
    };

    let value_content =
      content.prev === undefined && Object.size(content.prev) === 0
        ? content
        : content.results;

    try {
      let date = [];

      value_content.map(function (item) {
        date.push(item.date);

        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );

      if (
        pageActive !== "prediction" &&
        pageActive !== "interest_detail" &&
        pageActive !== "products"
      ) {
        for (let item in properties) {
          generic_xAxis.push(
            <YAxis
              id={properties[item].className}
              dataKey={properties[item].dataKey}
              orientation={properties[item].orientation}
              stroke={properties[item].color}
              yAxisId={properties[item].yAxisId}
              tick={custom.CustomTickChartGeneric}
            />
          );
          if (properties[item].barSize !== undefined) {
            generic_lines.push(
              <Bar //className={properties[item].className}
                dataKey={properties[item].dataKey}
                barSize={properties[item].barSize}
                fill={properties[item].color}
                stroke={properties[item].color}
                stroke-width={2}
                yAxisId={properties[item].yAxisId}
              />
            );
          } else {
            generic_lines.push(
              <Line //className={properties[item].className}
                dataKey={properties[item].dataKey}
                barSize={properties[item].barSize}
                stroke={properties[item].color}
                stroke-width={2}
                yAxisId={properties[item].yAxisId}
                type="monotone"
              />
            );
          }
        }

        response = {
          values: value_content,
          xAxis: generic_xAxis,
          lines: generic_lines,
        };
      } else {
        for (let i in scale) {
          generic_xAxis.push(
            <YAxis
              id={scale[i].className}
              dataKey={scale[i].dataKey}
              width={90}
              orientation={scale[i].orientation}
              stroke={scale[i].color}
              strokeWidth={1}
              yAxisId={scale[i].yAxisId}
              tick={custom.CustomTickChartGeneric}
            >
              <Label
                className={scale[i].className}
                angle={scale[i].orientation === "right" ? 90 : -90}
              >
                {scale[i].label}
              </Label>
            </YAxis>
          );
        }

        for (let item in properties) {
          if (properties[item].barSize !== undefined) {
            generic_lines.push(
              <Bar //className={properties[item].className}
                dataKey={properties[item].dataKey}
                barSize={properties[item].barSize}
                fill={properties[item].color}
                stroke={properties[item].color}
                strokeWidth={2}
                yAxisId={properties[item].yAxisId}
              />
            );
          } else {
            generic_lines.push(
              <Line //className={properties[item].className}
                dataKey={properties[item].dataKey}
                barSize={properties[item].barSize}
                stroke={properties[item].color}
                strokeWidth={2}
                yAxisId={properties[item].yAxisId}
                type="monotone"
              />
            );
          }
        }

        response = {
          values: value_content,
          xAxis: generic_xAxis,
          lines: generic_lines,
        };
      }
    } catch (error) {
      console.log("failed");
      console.log(error);
      dispatch({
        type: SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
        payload: { values: "loading", key: chiave },
      });
    }

    dispatch({
      type: SET_LINE_CHART_GENERIC_MULTIPLE_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeLineChartGenericOneScale = (content, properties, chiave) => {
  return (dispatch) => {
    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let generic_xAxis = [];
    let generic_yAxis = [];
    let generic_lines = [];
    let response = {};

    try {
      let date = [];

      content.map(function (item) {
        date.push(item.date);

        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );
      generic_yAxis.push(
        <YAxis type="number" yAxisId={1} tick={custom.CustomTickChartGeneric} />
      );
      for (let item in properties) {
        generic_lines.push(
          <Line
            className={properties[item].className}
            dataKey={properties[item].dataKey}
            barSize={properties[item].barSize}
            stroke={PRODUCT_COLOR[properties[item].color]}
            fill={PRODUCT_COLOR[properties[item].color]}
            stroke-width={2}
            yAxisId={properties[item].yAxisId}
            type="monotone"
          />
        );
      }

      response = {
        values: content,
        xAxis: generic_xAxis,
        yAxis: generic_yAxis,
        lines: generic_lines,
      };
    } catch (error) {
      console.log("failed");
      console.log(error);
      dispatch({
        type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }

    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeLineChartGenericOneScaleAccuracy = (
  content,
  properties,
  chiave
) => {
  return (dispatch) => {
    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let generic_xAxis = [];
    let generic_yAxis = [];
    let generic_lines = [];
    let response = {};

    try {
      let date = [];

      content.map(function (item) {
        date.push(item.date);

        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );
      generic_yAxis.push(
        <YAxis
          type="number"
          domain={[0, 100]}
          yAxisId={1}
          tick={custom.CustomTickChartGeneric}
        />
      );

      properties.data.map((item) => {
        generic_lines.push(
          <Line
            dataKey={item.dataKey}
            stroke={PREDICTION_COLOR[item.color]}
            fill={PREDICTION_COLOR[item.color]}
            stroke-width={2}
            yAxisId={item.yAxisId}
            type="monotone"
          />
        );
        return true; // React
      });

      response = {
        values: content,
        xAxis: generic_xAxis,
        yAxis: generic_yAxis,
        lines: generic_lines,
      };
    } catch (error) {
      console.log("failed");
      console.log(error);
      dispatch({
        type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }

    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeSankeyChart = (content) => {
  return (dispatch) => {
    dispatch({ type: SET_SANKEY_STATUS, payload: "loading" });

    let sankeyValues = {
      nodes: [
        {
          name: "Users",
        },
      ],
      links: [],
    };

    try {
      content.map((item, i) => {
        sankeyValues.nodes.push({
          name: item.node,
        });
        sankeyValues.links.push({
          source: 0,
          target: i + 1,
          // value: item.avgUniqueUsers,
          value: item.users,
        });
        return true; // React
      });
    } catch (error) {
      dispatch({ type: SET_SANKEY_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_SANKEY_VALUES, payload: sankeyValues });
    localStorage.setItem("sankeyValues", JSON.stringify(sankeyValues));

    dispatch({ type: SET_SANKEY_STATUS, payload: "complete" });
  };
};

const computeLineChart = (content, properties, chiave) => {
  return (dispatch) => {
    dispatch({ type: SET_LINE_CHART_STATUS, payload: "loading" });

    let generic_xAxis = [];
    let generic_lines = [];
    let response = {};

    try {
      let date = [];

      content.map(function (item) {
        date.push(item.date);

        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );

      /* TEST */
      for (let item in properties) {
        if (chiave !== "machine_learning_training_accuracy") {
          generic_xAxis.push(
            <YAxis
              id={properties[item].className}
              dataKey={properties[item].dataKey}
              orientation={properties[item].orientation}
              yAxisId={properties[item].yAxisId}
              tick={custom.CustomTickChartGeneric}
            />
          );
          generic_lines.push(
            <Line
              className={properties[item].className}
              dataKey={properties[item].dataKey}
              stroke={properties[item].stroke}
              stroke-width={2}
              yAxisId={properties[item].yAxisId}
              type="monotone"
            />
          );
        }

        generic_lines.push(
          <Line dataKey={properties[item].dataKey} type="monotone" />
        );
      }

      if (chiave === "machine_learning_training_accuracy") {
        generic_xAxis.push(<YAxis yAxisId={1} type="number" domain={[0, 1]} />);
      }

      response = {
        values: content,
        xAxis: generic_xAxis,
        lines: generic_lines,
      };
    } catch (error) {
      console.log("failed");
      dispatch({ type: SET_LINE_CHART_STATUS, payload: "failed" });
    }

    dispatch(
      { type: SET_LINE_CHART_VALUES, payload: response },
      { type: SET_LINE_CHART_VALUES, chiave: "test" }
    );

    dispatch({ type: SET_LINE_CHART_STATUS, payload: "complete" });
  };
};

const computeLineChartGeneric = (content, properties) => {
  return (dispatch) => {
    dispatch({ type: SET_LINE_CHART_STATUS, payload: "loading" });

    let results = [];

    try {
    } catch (error) {
      console.log("failed");
      dispatch({ type: SET_LINE_CHART_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_LINE_CHART_VALUES, payload: results });

    dispatch({ type: SET_LINE_CHART_STATUS, payload: "complete" });
  };
};

const computeHeatMapChart = (content) => {
  return (dispatch) => {
    dispatch({ type: SET_HEAT_MAP_CHART_STATUS, payload: "loading" });

    let data_array = [];
    let contentNew = [];

    try {
      content.map(function (object) {
        var keys = Object.keys(object);
        var values = Object.values(object);
        for (var i = 0; i <= 23; i++) {
          data_array.push([object.weekday, parseInt(keys[i], 10), values[i]]);
        }
        return true;
      });

      const day = {
        0: "Mon",
        1: "Tue",
        2: "Wed",
        3: "Thu",
        4: "Fri",
        5: "Sat",
        6: "Sun",
      };

      data_array.map(function (item) {
        contentNew.push({
          hour: String(item[1]),
          weekday: day[item[0]],
          value: item[2],
        });

        return true; // React
      });
    } catch (error) {
      console.log("failed");
      dispatch({ type: SET_HEAT_MAP_CHART_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_HEAT_MAP_CHART_VALUES, payload: contentNew });

    dispatch({ type: SET_HEAT_MAP_CHART_STATUS, payload: "complete" });
  };
};

const computeLineEventChart = (content) => {
  return (dispatch) => {
    let arrayValue = [];
    let prev_arrayValue = [];
    let results = {};

    dispatch({ type: SET_LINE_EVENT_CHART_STATUS, payload: "loading" });

    try {
      let date = [];
      let open = [];
      let click = [];
      let close = [];

      content["results"].popupOpen.map((item) => {
        date.push(item.date);
        open.push(item.users);

        return true; // React
      });
      content["results"].popupClose.map((item) => {
        close.push(item.users);

        return true; // React
      });
      content["results"].popupClick.map((item) => {
        click.push(item.users);

        return true; // React
      });

      for (let i = 0; i < date.length; i++) {
        arrayValue.push({
          date: date[i],
          open: open[i],
          close: close[i],
          click: click[i],
        });
      }

      let prev_date = [];
      let prev_open = [];
      let prev_click = [];
      let prev_close = [];

      content["prev"].popupOpen.map((item) => {
        prev_date.push(item.date);
        prev_open.push(item.users);
      });
      content["prev"].popupClose.map((item) => {
        prev_close.push(item.users);
      });
      content["prev"].popupClick.map((item) => {
        prev_click.push(item.users);
      });

      for (let i = 0; i < date.length; i++) {
        prev_arrayValue.push({
          date: prev_date[i],
          open: prev_open[i],
          close: prev_close[i],
          click: prev_click[i],
        });
      }

      results = {
        prev: prev_arrayValue,
        current: arrayValue,
      };
    } catch (error) {
      console.log("failed");
      dispatch({ type: SET_LINE_EVENT_CHART_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_LINE_EVENT_CHART_VALUES, payload: results });

    dispatch({ type: SET_LINE_EVENT_CHART_STATUS, payload: "complete" });
  };
};

const computeProductsTotals = (content) => {
  return (dispatch) => {
    dispatch({ type: SET_TOTALS_ELEMETS_STATUS, payload: "loading" });

    let response = [];

    try {
      //console.log(content)

      response.push(
        <div className="OnGoogleADS">
          <div className="element">
            <span>Transactions: </span>
            <Text className="element_child">
              {ut.format_number(content[0].Transactions, 2)}
            </Text>
          </div>
          <div className="element">
            <span>PageViews: </span>
            <Text className="element_child">
              {ut.format_number(content[0].ProductPageviews, 2)}
            </Text>
          </div>
          <div className="element">
            <span> Sold Items: </span>
            <Text className="element_child">
              {ut.format_number(content[0].Sold, 2)}
            </Text>
          </div>
          <div className="element">
            <span>Revenue: </span>
            <Text className="element_child">
              {ut.format_number(content[0].Revenue, 2) + " €"}
            </Text>
          </div>
        </div>
      );

      // response.push(
      //   <div className="product-overview">
      //     <div>
      //       <div className="title_div" id="avg_unique_users">
      //         Transactions
      //       </div>
      //       <div className="elements_products">
      //         <div className="number">
      //           {ut.format_number(content[0].Transactions, 2)}
      //         </div>
      //       </div>
      //     </div>
      //     <div>
      //       <div className="title_div" id="avg_unique_users">
      //         PageViews
      //       </div>
      //       <div className="elements_products">
      //         <div className="number">
      //           {ut.format_number(content[0].ProductPageviews, 2)}
      //         </div>
      //       </div>
      //     </div>
      //     <div>
      //       <div className="title_div" id="avg_unique_users">
      //         Sold Items
      //       </div>
      //       <div className="elements_products">
      //         <div className="number">
      //           {ut.format_number(content[0].Sold, 2)}
      //         </div>
      //       </div>
      //     </div>
      //     <div>
      //       <div className="title_div" id="avg_unique_users">
      //         Revenue
      //       </div>
      //       <div className="elements_products">
      //         <div className="number">
      //           {ut.format_number(content[0].Revenue, 2) + " €"}
      //         </div>
      //       </div>
      //     </div>
      //   </div>
      // );
    } catch (error) {
      console.log("failed");
      dispatch({ type: SET_TOTALS_ELEMETS_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_TOTALS_ELEMETS_VALUES, payload: response });

    dispatch({ type: SET_TOTALS_ELEMETS_STATUS, payload: "complete" });
  };
};

const computeProductsTotalsPrediction = (content) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTALS_PREDICTION_ELEMETS_STATUS,
      payload: "loading",
    });
    let response = {};
    try {
      const dataSource = [
        {
          key: "1",
          name: "Overal Traffic",
          avarage_users: content.totals.average_users,
          rate: content.totals.bouce_rate,
          transactions: content.totals.transactions,
          revenue: content.totals.revenue,
          conversion_rate: content.totals.conversion_rate,
        },
        {
          key: "2",
          name: "Prediction Segment",
          avarage_users: content.segment.average_users,
          rate: content.segment.bouce_rate,
          transactions: content.segment.transactions,
          revenue: content.segment.revenue,
          conversion_rate: content.segment.conversion_rate,
          total_avarage_users: content.totals.average_users,
          total_rate: content.totals.bouce_rate,
          total_transactions: content.totals.transactions,
          total_revenue: content.totals.revenue,
          total_conversion_rate: content.totals.conversion_rate,
        },
      ];

      const columns = [
        {
          title: "",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Avg. Daily Unique Users",
          dataIndex: "avarage_users",
          key: "avarage_users",
          //className: 'avarage_users',
          render: (text, record, index, row) => {
            if (record.key === "2") {
              return (
                <div>
                  <div style={{ "font-weight": "bold" }}>
                    {ut.format_number(text, 2)}
                  </div>
                  <small>
                    % of total: about{" "}
                    {(
                      (record.avarage_users / record.total_avarage_users) *
                      100
                    ).toFixed(2)}{" "}
                    %
                  </small>
                </div>
              );
            } else {
              return (
                <div>
                  <div style={{ "font-weight": "bold" }}>
                    {ut.format_number(text, 2)}
                  </div>
                </div>
              );
            }
          },
        },
        {
          title: "Bounce Rate",
          dataIndex: "rate",
          key: "rate",
          //className: 'rate',
          render: (text, record, index, row) => {
            if (record.key === "2") {
              return (
                <div>
                  <div>{text} %</div>
                  <small>Avg. for view: {record.total_rate} %</small>
                </div>
              );
            } else {
              return (
                <div>
                  <div>{text} %</div>
                </div>
              );
            }
          },
        },
        {
          title: "Transactions",
          dataIndex: "transactions",
          key: "transactions",
          //className: 'transactions',
          render: (text, record, index, row) => {
            if (record.key === "2") {
              return (
                <div>
                  <div>{ut.format_number(text, 2)}</div>
                  <small>
                    % of total: about{" "}
                    {(
                      (record.transactions / record.total_transactions) *
                      100
                    ).toFixed(2)}{" "}
                    %
                  </small>
                </div>
              );
            } else {
              return (
                <div>
                  <div>{ut.format_number(text, 2)}</div>
                </div>
              );
            }
          },
        },
        {
          title: "Revenue",
          dataIndex: "revenue",
          key: "revenue",
          //className: 'revenue',
          render: (text, record, index, row) => {
            if (record.key === "2") {
              return (
                <div>
                  <div>{ut.format_number(text, 2)} €</div>
                  <small>
                    % of total: about{" "}
                    {((record.revenue / record.total_revenue) * 100).toFixed(2)}{" "}
                    % ({ut.format_number(record.total_revenue)} €)
                  </small>
                </div>
              );
            } else {
              return (
                <div>
                  <div>{ut.format_number(text, 2)} €</div>
                </div>
              );
            }
          },
        },
        {
          title: "Conversion Rate",
          dataIndex: "conversion_rate",
          key: "conversion_rate",
          //className: 'conversion_rate',
          render: (text, record, index, row) => {
            if (record.key === "2") {
              return (
                <div>
                  <div>{text} %</div>
                  <small>Avg. for view: {record.total_conversion_rate} %</small>
                </div>
              );
            } else {
              return (
                <div>
                  <div>{text} %</div>
                </div>
              );
            }
          },
        },
      ];

      response = {
        dataSource: dataSource,
        columns: columns,
      };
    } catch (error) {
      console.log("failed");
      dispatch({
        type: SET_TOTALS_PREDICTION_ELEMETS_STATUS,
        payload: "failed",
      });
    }

    dispatch({ type: SET_TOTALS_PREDICTION_ELEMETS_VALUES, payload: response });

    dispatch({
      type: SET_TOTALS_PREDICTION_ELEMETS_STATUS,
      payload: "complete",
    });
  };
};

const computeTableSimple = (content) => {
  return (dispatch) => {
    dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "loading" });

    let response = [];

    try {
      //console.log(content)

      response = {
        values: content,
        loading: false,
      };
    } catch (error) {
      //console.log('failed')
      dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_TABLE_SIMPLE_VALUES, payload: response });

    dispatch({ type: SET_TABLE_SIMPLE_STATUS, payload: "complete" });
  };
};

const computePieChartGeneric = (
  content,
  properties,
  componentTitle,
  chiave
) => {
  return (dispatch, getState) => {
    //console.log("Compute: chiave " + chiave)

    dispatch({
      type: SET_PIE_CHART_GENERIC_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let days = getState().filters.days.values;

    if (days === null) days = JSON.parse(localStorage.getItem("days"));

    let results2 = [];
    let results = [];
    let lum = 0.02;

    try {
      content.map(function (element, i) {
        // console.log("Pie element: ", element);
        let pieSlice = {};

        switch (componentTitle) {
          case "Device":
            pieSlice = {
              name: element.device,
              value: element["avgUniqueUsers"],
              fill: ut.setColorLum("#0d71cc", lum),
            };

            lum = lum + 0.28;
            break;
          case "TopCompanies":
            pieSlice = {
              name: element.company,
              value: element.total_users,
              fill: ut.setColorLum("#0d71cc", lum),
            };

            lum = lum + 0.08;
            break;

          default:
            break;
        }

        results.push(pieSlice);

        return true;
      });

      results2.push(
        <Pie
          dataKey="value"
          isAnimationActive={true}
          data={results}
          cx={"50%"}
          cy={"60%"}
          outerRadius={"80%"}
          innerRadius={"40%"}
          fill="#8884d8"
          //label
          labelLine={false}
          label={custom.CustomLabelPieChartGeneric}
        />
      );
    } catch (error) {
      dispatch({
        type: SET_PIE_CHART_GENERIC_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }

    dispatch({
      type: SET_PIE_CHART_GENERIC_VALUES,
      payload: { values: results2, key: chiave },
    });

    dispatch({
      type: SET_PIE_CHART_GENERIC_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeUserTechTravel = (content) => {
  //console.log(content)

  return (dispatch) => {
    dispatch({ type: SET_USER_TECH_TRAVEL_STATUS, payload: "loading" });

    let results_tech = {
      max: 0,
    };
    let results_travel = {
      max: 0,
    };

    try {
      content.tech.map((tech) => {
        results_tech[tech.node] = tech;
        results_tech["max"] = results_tech["max"] + tech.users;

        return true; // React
      });
      content.travelers.map((travel) => {
        results_travel[travel.node] = travel;
        results_travel["max"] = results_travel["max"] + travel.users;

        return true; // React
      });
    } catch (error) {
      console.log(error);
      dispatch({ type: SET_USER_TECH_TRAVEL_STATUS, payload: "failed" });
    }

    dispatch({
      type: SET_USER_TECH_TRAVEL_VALUES,
      payload: { tech: results_tech, travel: results_travel },
    });

    dispatch({ type: SET_USER_TECH_TRAVEL_STATUS, payload: "complete" });
  };
};

const computeBarGeneric = (content, start_date, end_date) => {
  //console.log(content)
  return (dispatch, getState) => {
    const { start_date, end_date } = getState().filters.dates;

    dispatch({ type: SET_BAR_GENERIC_STATUS, payload: "loading" });

    let results = [];

    var date1 = moment(start_date);
    var date2 = moment(end_date);
    var days = date2.diff(date1, "days") + 1;

    try {
      content.map(function (item) {
        if (item.node !== "IT") {
          item.users = item.users / days;
          results.push(item);
          if (results.length > 10) {
            results.sort(function (a, b) {
              return b.users - a.users;
            });
            results.pop();
          }
        }
        return true;
      });
    } catch (error) {
      //console.log(error)
      dispatch({ type: SET_BAR_GENERIC_STATUS, payload: "failed" });
    }

    dispatch({ type: SET_BAR_GENERIC_VALUES, payload: results });

    dispatch({ type: SET_BAR_GENERIC_STATUS, payload: "complete" });
  };
};

// TOTALS

function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

// GENERIC
const computeTotalsGeneric = (content, properties, chiave) => {
  return (dispatch) => {
    dispatch({
      type: SET_TOTALS_GENERIC_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let response = [];

    try {
      let totals = {};

      properties.data.map((item) => {
        if (item.calculated === undefined) {
          totals[item.id] = 0;
        } else {
          totals[item.id] = item.calculated;
        }

        return true; // React
      });

      content.map((item, i) => {
        for (let tot in totals) {
          if (isJson(totals[tot])) {
            if (item[tot] !== undefined) totals[tot] = totals[tot] + item[tot];
          }
        }

        return true; // React
      });

      for (let tot in totals) {
        if (isJson(totals[tot]) === false) {
          totals[tot] = ut.get_formatted_perc_ratio(
            totals[totals[tot].id1],
            totals[totals[tot].id2]
          );
        }
      }

      for (let item in totals) {
        //console.log(totals[item])

        response.push(
          <Col span={24 / properties.data.length}>
            <Card
              title={<IntlMessages id={`totals.${item}`} />}
              bordered={false}
              style={{ height: 50 }}
            >
              <p>
                {ut.format_number(totals[item], 2)}{" "}
                {item === "revenue" ? " €" : <div></div>}
              </p>
            </Card>
          </Col>
        );
      }
    } catch (error) {
      console.log("failed");
      console.log(error);
      dispatch({
        type: SET_TOTALS_GENERIC_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }

    dispatch({
      type: SET_TOTALS_GENERIC_VALUES,
      payload: { values: response, key: chiave },
    });

    dispatch({
      type: SET_TOTALS_GENERIC_STATUS,
      payload: { values: "complete", key: chiave },
    });
  };
};

const computeBarChartGeneric = (content, properties, chiave) => {
  return (dispatch) => {
    dispatch({
      type: SET_BAR_CHART_GENERIC_STATUS,
      payload: { values: "loading", key: chiave },
    });

    let generic_xAxis = [];
    let generic_lines = [];
    let response = {};

    try {
      let date = [];

      content.map(function (item) {
        date.push(item.date);

        return true; // React
      });

      generic_xAxis.push(
        <XAxis
          id="xaxisColor"
          dataKey="date"
          padding={{ left: 20, right: 20, top: 10 }}
        />
      );

      for (let item in properties) {
        generic_xAxis.push(<YAxis />);
        generic_lines.push(
          <Bar
            className={properties[item].className}
            dataKey={properties[item].dataKey}
            fill={properties[item].fill}
            stackId={properties[item].stackId}
            yAxisId={properties[item].yAxisId}
            type="monotone"
          />
        );
      }

      response = {
        values: content,
        xAxis: generic_xAxis,
        lines: generic_lines,
      };

      dispatch({
        type: SET_BAR_CHART_GENERIC_VALUES,
        payload: { values: response, key: chiave },
      });

      dispatch({
        type: SET_BAR_CHART_GENERIC_STATUS,
        payload: { values: "complete", key: chiave },
      });
    } catch (error) {
      console.log("failed");
      console.log(error);
      dispatch({
        type: SET_BAR_CHART_GENERIC_STATUS,
        payload: { values: "failed", key: chiave },
      });
    }
  };
};
