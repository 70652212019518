import React from "react";
import moment from "moment";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { filters } from "../../appRedux/actions/Auth";
import { getFilterData } from "../../appRedux/actions";
import { SET_HEAT_MAP_CHART_STATUS } from "../../constants/ActionTypes";
import { useHistory } from "react-router-dom";
import mss from "../../util/Message";

const { RangePicker } = DatePicker;

const FilterDatePicker = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { dates } = useSelector((state) => state.filters);
  const { customerActive } = useSelector((state) => state.user);

  function disabledDate(current) {
    let customDate = moment().subtract(6, "month").format("YYYY-MM-DD");
    return (
      (current && current < moment(customDate, "YYYY-MM-DD")) ||
      current > moment().subtract(1, "days")
    );
  }

  const onChangeDate = (date, dateString) => {
    let new_date = {};

    new_date.start_date = dateString[0];
    new_date.end_date = dateString[1];

    dispatch({ type: SET_HEAT_MAP_CHART_STATUS, payload: "idle" });

    // Update Filtri
    dispatch(filters(new_date));

    let pageActive = JSON.parse(localStorage.getItem("pageActive"));

    history.push(
      `/${pageActive}?customer=${customerActive.values}&start_date=${new_date.start_date}&end_date=${new_date.end_date}`
    );

    dispatch(
      getFilterData("newDate", {
        start_date: new_date.start_date,
        end_date: new_date.end_date,
      })
    );
  };

  return (
    <RangePicker
      className="gx-mb-2 gx-w-90"
      disabledDate={disabledDate}
      onChange={onChangeDate}
      ranges={{
        "Last 7 Days": [
          moment().subtract(8, "days"),
          moment().subtract(1, "days"),
        ],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
          moment().subtract(1, "month").startOf("month"),
          moment().subtract(1, "month").endOf("month"),
        ],
        "Last 30 days": [moment().subtract(30, "days"), moment()],
        "Last 90 days": [
          moment().subtract(3, "month").startOf("month"),
          moment(),
        ],
      }}
    />
  );
};

export default FilterDatePicker;
