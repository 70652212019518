import {
  SET_FILTERS_STATUS,
  SET_FILTERS_VALUES,
  SET_DATES_VALUES,
  SET_DATES_STATUS,
  SET_FILTERS_CONVERSIONS_VALUES,
  SET_FILTERS_CONVERSIONS_STATUS,
  SET_DAYS_VALUES,
  SET_DAYS_STATUS
} from "../../constants/ActionTypes";
import moment from "moment";

const queryString = require("query-string");
let parsed = queryString.parse(window.location.search);

const INIT_STATE = {
  filterDevice: {
    values: "all",
    status: "idle",
  },
  filterConversions: {
    values: "all",
    status: "idle",
  },
  dates: {
    start_date:
      parsed["start_date"] !== undefined
        ? parsed["start_date"]
        : moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date:
      parsed["end_date"] !== undefined &&
      moment(parsed["end_date"]).isSameOrBefore(moment().format("YYYY-MM-DD"))
        ? parsed["end_date"]
        : moment().format("YYYY-MM-DD"),
    updated: false,
  },
  days: {
    values: JSON.parse(localStorage.getItem("days")),
    status: "idle",
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    // CHART
    case SET_FILTERS_VALUES: {
      return {
        ...state,
        filterDevice: {
          ...state.filterDevice,
          values: action.payload,
        },
      };
    }

    case SET_FILTERS_STATUS: {
      return {
        ...state,
        filterDevice: {
          ...state.filterDevice,
          status: action.payload,
        },
      };
    }

    case SET_FILTERS_CONVERSIONS_VALUES: {
      return {
        ...state,
        filterConversions: {
          ...state.filterConversions,
          values: action.payload,
        },
      };
    }

    case SET_FILTERS_CONVERSIONS_STATUS: {
      return {
        ...state,
        filterConversions: {
          ...state.filterConversions,
          status: action.payload,
        },
      };
    }

    // CHART
    case SET_DATES_VALUES: {
      return {
        ...state,
        dates: {
          ...state.dates,
          start_date: action.payload.startDate,
          end_date: action.payload.endDate,
        },
      };
    }

    case SET_DATES_STATUS: {
      return {
        ...state,
        dates: {
          ...state.dates,
          updated: action.payload,
        },
      };
    }

    case SET_DAYS_VALUES: {
      return {
        ...state,
        days: {
          ...state.days,
          values: action.payload,
        },
      };
    }

    case SET_DAYS_STATUS: {
      return {
        ...state,
        days: {
          ...state.days,
          status: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
