import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "util/asyncComponent";

const App = ({ match }) => {

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        {/** Service **/}
        <Route
          path={`${match.url}services`}
          component={asyncComponent(() => import("./Services"))}
        />
        <Route
          path={`${match.url}summary`}
          component={asyncComponent(() => import("./Strategies/Summary"))}
        />
        {/* <Route
          path={`${match.url}strategy`}
          component={asyncComponent(() => import("./Strategies/Strategy"))}
        />
        <Route
          path={`/loading`}
          component={asyncComponent(() => import("./Loader"))}
        />

        {/** Pixel */}
        <Route
          path={`${match.url}overview`}
          component={asyncComponent(() => import("./Page"))}
        />
        <Route
          path={`${match.url}acquisition`}
          component={asyncComponent(() => import("./Page"))}
        />
        <Route
          path={`${match.url}behaviour`}
          component={asyncComponent(() => import("./Page/index"))}
        />

        <Route
          path={`${match.url}interest_detail`}
          component={asyncComponent(() => import("./Page"))}
        />

        {/** Prediction */}
        <Route
          path={`${match.url}prediction`}
          component={asyncComponent(() => import("./Page"))}
        />
        {/** Products */}
        <Route
          path={`${match.url}products`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}dem_cart`}
          component={asyncComponent(() => import("./Page"))}
        />
        <Route
          path={`${match.url}dem_cart_promo`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}test`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}interests`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}anomaly_detection`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}content_promoted`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}error_tracking`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}ltv`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}web_traffic`}
          component={asyncComponent(() => import("./Page"))}
        />

        <Route
          path={`${match.url}component_test`}
          component={asyncComponent(() => import("./Page"))}
        />
        {/* <Route path={`${match.url}loading`} component={asyncComponent(() => import('./Loader'))} /> */}

        {/** DEVEL */}
        {/* <Route
          path={`${match.url}strategy_summary`}
          component={asyncComponent(() => import("./Page"))}
        />
        <Route
          path={`${match.url}strategy`}
          component={asyncComponent(() => import("./Page"))}
        /> */}
      </Switch>
    </div>
  );
};

export default App;
