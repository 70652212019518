import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from "url-search-params";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn, { getDatesFromUrl } from "../SignIn";
import Recover from "../Recover";
import NewPassword from "../NewPassword";
import {
  getUserActions,
} from "../../appRedux/actions";
import {
  SET_CUSTOMERS_ACTIVE_STATUS,
  SET_CUSTOMERS_ACTIVE_VALUES,
} from "../../constants/ActionTypes";
import { validateDates } from "../../util/utilities";
import Loading from "./Loading";

const App = () => {
  const location = useLocation();
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const history = useHistory();

  const { locale, navStyle, layoutType } = useSelector(
    ({ settings }) => settings
  );
  const { tokenValidity, validatingToken, initURL } = useSelector(
    ({ auth }) => auth
  );
  const { dates } = useSelector((state) => state.filters);

  const { customerActive, page_active, customersPage } = useSelector(
    (state) => state.user
  );

  const queryString = require("query-string");

  useEffect(() => {
    // read URL and parse customer
    let parsed = queryString.parse(window.location.search);
    let customer = parsed["customer"];
    //console.log("Parsed customer: ", customer);

    // check if customer is valid
    let list_customer = JSON.parse(localStorage.getItem("list_customer"));
    //console.log("Customers list: ", list_customer);

    if (customer !== undefined && customer !== null) {
      if (list_customer) {
        if (
          !list_customer.includes(customer) || // e.g "BUBU" not included in list
          !customer.length > 0 // "" is not a valid customer
        ) {
          customer = list_customer[0];
        }
        // dispatch it to redux
        dispatch({ type: SET_CUSTOMERS_ACTIVE_VALUES, payload: customer });
        // dispatch({ type: SET_CUSTOMERS_ACTIVE_STATUS, payload: "complete" });

        // save it to LS
        localStorage.setItem("customer-active", JSON.stringify(customer));
      }
    }
  }, []);

  // Lettura da URL
  useEffect(() => {
    if (
      // validatingToken === false && // prima era false
      customerActive.values !== null &&
      customerActive.values.length > 0
    ) {
      // dispatch(getUserActions('checkCustomer'))
      // Recupero la lista dei customer per questo utente
      let list_customer = JSON.parse(localStorage.getItem("list_customer"));

      // Controllo se esiste la lista dei customer
      if (list_customer !== undefined && list_customer !== null) {
        // Controllo se il customer attivo esiste all'interno della lista
        // Altrimenti inserisco come customer il primo customer nella lista e di conseguenza la prima pagina di questo customer
        if (list_customer.includes(customerActive.values)) {
          //console.log("UserAction: include");
          dispatch(
            getUserActions(
              "dashboard_pages",
              "",
              true,
              customerActive.values,
              undefined, // customerURL
              false
            )
          );
        } else {
          dispatch(
            getUserActions(
              "dashboard_pages",
              "",
              true,
              list_customer[0],
              undefined,
              false
            )
          );
        }

        dispatch({ type: SET_CUSTOMERS_ACTIVE_STATUS, payload: "idle" });
      } else {
        // Rifare chiamata customer
      }
    }
  }, [customerActive.values]);

  useEffect(() => {
    // possiamo entrare solo se abbiamo un customer attivo e una pagina
    // appena la pagina carica sono vuoti ("")
    if (customerActive.values !== null && customerActive.values.length > 0 && page_active.values.length > 0) {
      // console.log("Sono dentro");
      let { startDate, endDate } = validateDates(
        dates.start_date,
        dates.end_date
      );
      let destination = page_active.values;

      // quando aggiorni/premi invio sull'URL, redux si svuota e page_active.values è vuoto
      if (customersPage.values && !customersPage.values.includes(destination)) {
        
        destination = customersPage.values[0];
      }

      history.push(
        `/${destination}?customer=${customerActive.values}&start_date=${startDate}&end_date=${endDate}`
      );
      // dispatch({ type: SET_PAGE_ACTIVE_STATUS, payload: "idle" });
    }
  }, [page_active.values, customerActive.values]);

  useEffect(() => {
    let link = document.createElement("link");
    link.type = "text/css";
    link.rel = "stylesheet";
    link.href = "/css/style.css";
    link.className = "gx-style";
    document.body.appendChild(link);
  });

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [dispatch, initURL, layoutType, navStyle]);

  const setLayoutType = () => {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  };

  const setNavStyle = () => {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  };

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <Route exact path={`/signin`} component={SignIn} />
          <Route exact path="/recover" component={Recover} />
          <Route exact path="/new_password" component={NewPassword} />
          {/* The access to the MainApp component is restricted. This means that
              access is allowed only to authorized users.
              We show a loader while we check for the token validity.
              Then, based on its validity value we send the user to the signin page
              or to the MainApp component.
          */}

          {validatingToken ? (
            <Loading />
          ) : tokenValidity === true ? (
            <MainApp
              to={{
                pathname: `${match.url}`,
                search: `?customer=${customerActive.values}&start_date=${dates.start_date}&end_date=${dates.end_date}`,
                state: { from: location },
              }}
            >
              {/* {" "} */}
              {/* {alert("ciao")}{" "} */}
            </MainApp>
          ) : (
            <Redirect
              to={{
                pathname: `/signin`,
                search: `?customer=${customerActive.values}&start_date=${dates.start_date}&end_date=${dates.end_date}`,
                state: { from: location },
              }}
            />
          )}
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default memo(App);
