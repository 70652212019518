// LTV
export const LTV_COLOR = {
    monetary: '#00796B', //
    recency: '#cb391f', //
    frequency: '#735971', //
    're-engage': '#AA3E98',
    frequent: '#86C332',
    high_potential: '#254E70', //
    top_clients: '#f3642b', //
    're-engage_clients': '#AA3E98',
    'frequent_clients': '#86C332',
    'high_potential_clients': '#254E70', //
    'top_clients_clients': '#f3642b' //
}

export const POPUP_COLOR = {
    open: '#1BE7FF',
    close: '#D64550',
    click: '#377771'
}

export const PRODUCT_COLOR = {
    'detail': '#1bff32',
    'add_to_cart': '#ff1b82',
    'purchase': '#9d1cff',
    'Transactions': '#45558d',
    'ProductPageviews': '#61458d',
    'AddToCart': '#458d7d'
}

export const PREDICTION_COLOR = {
    'accuracy_score': '#1BE7FF',
    'precision_score': '#D64550',
    'recall_score': '#377771',
    "f1_score": '#f3642b'
}

export const VAR_COLOR = {
    'user': '#0484f5'
}

export const EVENT = {
    'productdetail': '#1bff32',
    'addtocart': '#ff1b82',
    'purchase': '#9d1cff',
    'checkout': '#45558d',
    'pageView': '#61458d',
    'registration': '#458d7d',
    'wishlist': '#f3642b',
    'newsletter': '#428AD6',
    'popupOpen': '#42D6B9',
    'popupClose': '#D64288',
    'popupClick': '#D68542'
}

export const RANDOM_COLOR = {
    0: '#1bff32',
    1: '#ff1b82',
    2: '#9d1cff',
    3: '#45558d',
    4: '#61458d',
    5: '#458d7d',
    6: '#C1292E',
    7: '#f3642b',
    8: '#42D6B9',
    9: '#D64288'
}