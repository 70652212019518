import {
  SET_CHART_DATA,
  SET_CHART_STATUS,
  SET_SANKEY_STATUS,
  SET_SANKEY_VALUES,
  SET_LINE_CHART_VALUES,
  SET_LINE_CHART_STATUS,
  SET_HEAT_MAP_CHART_VALUES,
  SET_HEAT_MAP_CHART_STATUS,
  SET_LINE_EVENT_CHART_VALUES,
  SET_LINE_EVENT_CHART_STATUS,
  SET_TOTALS_ELEMETS_STATUS,
  SET_TOTALS_ELEMETS_VALUES,
  SET_LINE_CHART_GENERIC_VALUES,
  SET_LINE_CHART_GENERIC_STATUS,
  SET_CHART_GENERIC_VALUES,
  SET_PIE_CHART_GENERIC_STATUS,
  SET_PIE_CHART_GENERIC_VALUES,
  SET_LINE_TEST_STATUS,
  SET_LINE_TEST_VALUES,
  SET_LINE_CHART_GENERIC_MULTIPLE_STATUS,
  SET_LINE_CHART_GENERIC_MULTIPLE_VALUES,
  SET_CHART_INIT_STATE,
  SET_TOTALS_PREDICTION_ELEMETS_STATUS,
  SET_TOTALS_PREDICTION_ELEMETS_VALUES,
  SET_USER_TECH_TRAVEL_STATUS,
  SET_USER_TECH_TRAVEL_VALUES,
  SET_BAR_GENERIC_STATUS,
  SET_BAR_GENERIC_VALUES,
  SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES,
  SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS,
  SET_TOTALS_GENERIC_STATUS,
  SET_TOTALS_GENERIC_VALUES,
  SET_BAR_CHART_GENERIC_VALUES,
  SET_BAR_CHART_GENERIC_STATUS,
  SET_CONVERSIONS_GENERIC_STATUS_TRENDS,
  SET_CONVERSIONS_GENERIC_VALUES,
  SET_RADAR_GENERIC_STATUS,
  SET_RADAR_GENERIC_VALUES,
  SET_BAR_LATERAL_GENERIC_STATUS,
  SET_BAR_LATERAL_GENERIC_VALUES,
  SET_LINE_GENERIC_STATUS,
  SET_LINE_GENERIC_VALUES,
  SET_TAB_BAR_GENERIC_STATUS,
  SET_TAB_BAR_GENERIC_VALUES,
  SET_EVENTS_VALUES,
  SET_EVENTS_STATUS,
  SET_EVENTS_VALUES_VALUES,
  SET_EVENTS_VALUES_STATUS
} from "../../constants/ActionTypes";

const status_array = ["loading", "complete", "idle", "failed"];

const INIT_STATE = {
  chart: {
    values: JSON.parse(localStorage.getItem("chartData")),
    status: "idle",
  },
  ConversionGeneric: {
    values: {},
    status_totals: {},
    status_trends: {},
  },
  sankey: {
    values: null,
    status: "idle",
  },
  lineChartGeneric: {
    values: {},
  },
  lineChartGenericMultiple: {
    values: {},
    status: {},
  },
  lineChart: {
    values: null,
    xAxis: null,
    lines: null,
    status: "idle",
  },
  lineEventChart: {
    values: null,
    status: "idle",
  },
  heatMapChart: {
    values: null,
    status: "idle",
  },
  productsTotals: {
    values: null,
    status: "idle",
  },
  predictionTotals: {
    values: null,
    status: "idle",
  },
  userTechTravel: {
    values: {
      tech: null,
      travel: null,
    },
    status: "idle",
  },
  chartGeneric: {
    values: {},
  },
  pieChartGeneric: {
    values: {},
    status: {},
  },
  lineTest: {
    values: {
      prev: null,
      content: null,
    },
    status: "idle",
  },
  barGeneric: {
    values: null,
    status: "idle",
  },
  lineChartGenericOneScale: {
    values: {},
    status: "idle",
  },
  // GENERIC
  totalsGeneric: {
    values: {},
    status: {},
  },
  barChartGeneric: {
    values: {},
    status: {},
  },
  radarGeneric: {
    values: {},
    status: {},
  },
  barLateralGeneric: {
    values: {},
    status: {},
  },
  lineGeneric: {
    values: {},
    status: {},
  },
  tabBarGeneric: {
    values: {},
    status: {},
  },
  events: {
    values: {},
    status: {},
  },
  eventsValues: {
    values: {},
    status: {},
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CHART_INIT_STATE: {
      return INIT_STATE;
    }

    // TEST INTERESTS
    case SET_LINE_CHART_GENERIC_MULTIPLE_STATUS: {
      return {
        ...state,
        lineChartGenericMultiple: {
          ...state.lineChartGenericMultiple,
          status: {
            ...state.lineChartGenericMultiple.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_LINE_CHART_GENERIC_MULTIPLE_VALUES: {
      return {
        ...state,
        lineChartGenericMultiple: {
          ...state.lineChartGenericMultiple,
          values: {
            ...state.lineChartGenericMultiple.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // CHART
    case SET_CHART_STATUS: {
      return {
        ...state,
        chart: {
          ...state.chart,
          status: action.payload,
        },
      };
    }
    case SET_CHART_DATA: {
      return {
        ...state,
        chart: {
          ...state.chart,
          values: action.payload,
        },
      };
    }
    // LINEEVENTCHART
    case SET_LINE_EVENT_CHART_STATUS: {
      return {
        ...state,
        lineEventChart: {
          ...state.lineEventChart,
          status: action.payload,
        },
      };
    }
    case SET_LINE_EVENT_CHART_VALUES: {
      return {
        ...state,
        lineEventChart: {
          ...state.lineEventChart,
          values: action.payload,
        },
      };
    }
    // HEATMAPCHART
    case SET_HEAT_MAP_CHART_STATUS: {
      return {
        ...state,
        heatMapChart: {
          ...state.heatMapChart,
          status: action.payload,
        },
      };
    }
    case SET_HEAT_MAP_CHART_VALUES: {
      return {
        ...state,
        heatMapChart: {
          ...state.heatMapChart,
          values: action.payload,
        },
      };
    }
    // SANKEY
    case SET_SANKEY_STATUS: {
      return {
        ...state,
        sankey: {
          ...state.sankey,
          status: action.payload,
        },
      };
    }
    case SET_SANKEY_VALUES: {
      return {
        ...state,
        sankey: {
          ...state.sankey,
          values: action.payload,
        },
      };
    }
    // SANKEY
    case SET_PIE_CHART_GENERIC_STATUS: {
      return {
        ...state,
        pieChartGeneric: {
          ...state.pieChartGeneric,
          status: {
            ...state.pieChartGeneric.status,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_PIE_CHART_GENERIC_VALUES: {
      return {
        ...state,
        pieChartGeneric: {
          ...state.pieChartGeneric,
          values: {
            ...state.pieChartGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    // PRODUCTSTOTALS
    case SET_TOTALS_ELEMETS_STATUS: {
      return {
        ...state,
        productsTotals: {
          ...state.productsTotals,
          status: action.payload,
        },
      };
    }
    case SET_TOTALS_ELEMETS_VALUES: {
      return {
        ...state,
        productsTotals: {
          ...state.productsTotals,
          values: action.payload,
        },
      };
    }
    // PREDICTIONTOTALS
    case SET_TOTALS_PREDICTION_ELEMETS_STATUS: {
      return {
        ...state,
        predictionTotals: {
          ...state.predictionTotals,
          status: action.payload,
        },
      };
    }
    case SET_TOTALS_PREDICTION_ELEMETS_VALUES: {
      return {
        ...state,
        predictionTotals: {
          ...state.predictionTotals,
          values: action.payload,
        },
      };
    }
    // LINECHART
    case SET_LINE_CHART_STATUS: {
      return {
        ...state,
        lineChart: {
          ...state.lineChart,
          status: action.payload,
        },
      };
    }
    case SET_LINE_CHART_VALUES: {
      return {
        ...state,
        lineChart: {
          ...state.lineChart,
          values: action.payload.values,
          xAxis: action.payload.xAxis,
          lines: action.payload.lines,
        },
      };
    }
    // LINECHART
    case SET_LINE_CHART_GENERIC_STATUS: {
      return {
        ...state,
        lineChart: {
          ...state.lineChart,
          status: action.payload,
        },
      };
    }
    case SET_LINE_CHART_GENERIC_VALUES: {
      return {
        ...state,
        lineChartGeneric: {
          ...state.lineChartGeneric,
          values: action.payload,
        },
      };
    }
    // USERTECHTRAVEL
    case SET_USER_TECH_TRAVEL_STATUS: {
      //console.log(action.payload)
      return {
        ...state,
        userTechTravel: {
          ...state.userTechTravel,
          status: action.payload,
        },
      };
    }
    case SET_USER_TECH_TRAVEL_VALUES: {
      //console.log(action.payload)
      return {
        ...state,
        userTechTravel: {
          ...state.userTechTravel,
          values: {
            tech: action.payload.tech,
            travel: action.payload.travel,
          },
        },
      };
    }
    //BARGENERIC
    case SET_BAR_GENERIC_STATUS: {
      //console.log(action.payload)
      return {
        ...state,
        barGeneric: {
          ...state.barGeneric,
          status: action.payload,
        },
      };
    }
    case SET_BAR_GENERIC_VALUES: {
      return {
        ...state,
        barGeneric: {
          ...state.barGeneric,
          values: action.payload,
        },
      };
    }
    //
    case SET_CHART_GENERIC_VALUES: {
      return {
        ...state,
        chartGeneric: {
          ...state.chartGeneric,
          values: {
            ...state.chartGeneric.values,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }
    // LINETEST
    case SET_LINE_TEST_STATUS: {
      return {
        ...state,
        lineTest: {
          ...state.lineTest,
          status: action.payload,
        },
      };
    }
    case SET_LINE_TEST_VALUES: {
      return {
        ...state,
        lineTest: {
          ...state.lineTest,
          values: {
            prev: action.payload.prev,
            content: action.payload.content,
          },
        },
      };
    }
    //
    case SET_LINE_CHART_GENERIC_ONE_SCALE_STATUS: {
      return {
        ...state,
        lineChartGenericOneScale: {
          ...state.lineChartGenericOneScale,
          status: {
            ...state.lineChartGenericOneScale.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_LINE_CHART_GENERIC_ONE_SCALE_VALUES: {
      return {
        ...state,
        lineChartGenericOneScale: {
          ...state.lineChartGenericOneScale,
          values: {
            ...state.lineChartGenericOneScale.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    // GENERIC
    case SET_TOTALS_GENERIC_STATUS: {
      return {
        ...state,
        totalsGeneric: {
          ...state.totalsGeneric,
          status: {
            ...state.totalsGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_TOTALS_GENERIC_VALUES: {
      return {
        ...state,
        totalsGeneric: {
          ...state.totalsGeneric,
          values: {
            ...state.totalsGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_BAR_CHART_GENERIC_STATUS: {
      if (status_array.includes(action.payload.values)) {
        //console.log(action.payload.key + ', ' + action.payload.values)

        return {
          ...state,
          barChartGeneric: {
            ...state.barChartGeneric,
            status: {
              ...state.barChartGeneric.status,
              [action.payload.key]: action.payload.values,
            },
          },
        };
      }
    }
    case SET_BAR_CHART_GENERIC_VALUES: {
      return {
        ...state,
        barChartGeneric: {
          ...state.barChartGeneric,
          values: {
            ...state.barChartGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    // CONVERSIONS
    case SET_CONVERSIONS_GENERIC_STATUS_TRENDS: {
      return {
        ...state,
        ConversionGeneric: {
          ...state.ConversionGeneric,
          status_trends: {
            ...state.ConversionGeneric.status_trends,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }
    case SET_CONVERSIONS_GENERIC_VALUES: {
      return {
        ...state,
        ConversionGeneric: {
          ...state.ConversionGeneric,
          values: {
            ...state.ConversionGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // NEW VERSION DANIELE

    // RADAR
    case SET_RADAR_GENERIC_STATUS: {
      return {
        ...state,
        radarGeneric: {
          ...state.radarGeneric,
          status: {
            ...state.radarGeneric.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_RADAR_GENERIC_VALUES: {
      return {
        ...state,
        radarGeneric: {
          ...state.radarGeneric,
          values: {
            ...state.radarGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // BAR
    case SET_BAR_LATERAL_GENERIC_STATUS: {
      return {
        ...state,
        barLateralGeneric: {
          ...state.barLateralGeneric,
          status: {
            ...state.barLateralGeneric.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_BAR_LATERAL_GENERIC_VALUES: {
      return {
        ...state,
        barLateralGeneric: {
          ...state.barLateralGeneric,
          values: {
            ...state.barLateralGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // RADAR
    case SET_LINE_GENERIC_STATUS: {
      return {
        ...state,
        lineGeneric: {
          ...state.lineGeneric,
          status: {
            ...state.lineGeneric.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_LINE_GENERIC_VALUES: {
      return {
        ...state,
        lineGeneric: {
          ...state.lineGeneric,
          values: {
            ...state.lineGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // TabBarGeneric
    case SET_TAB_BAR_GENERIC_STATUS: {
      return {
        ...state,
        tabBarGeneric: {
          ...state.tabBarGeneric,
          status: {
            ...state.tabBarGeneric.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_TAB_BAR_GENERIC_VALUES: {
      return {
        ...state,
        tabBarGeneric: {
          ...state.tabBarGeneric,
          values: {
            ...state.tabBarGeneric.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    // Events
    case SET_EVENTS_STATUS: {
      return {
        ...state,
        events: {
          ...state.events,
          status: {
            ...state.events.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_EVENTS_VALUES: {
      return {
        ...state,
        events: {
          ...state.events,
          values: {
            ...state.events.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    case SET_EVENTS_VALUES_STATUS: {
      return {
        ...state,
        eventsValues: {
          ...state.eventsValues,
          status: {
            ...state.eventsValues.status,
            [action.payload.key]: action.payload.status,
          },
        },
      };
    }
    case SET_EVENTS_VALUES_VALUES: {
      return {
        ...state,
        eventsValues: {
          ...state.eventsValues,
          values: {
            ...state.eventsValues.values,
            [action.payload.key]: action.payload.values,
          },
        },
      };
    }

    default:
      return state;
  }
};
