import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { message, Select, Spin, Tag } from "antd";

import { LoadingOutlined } from "@ant-design/icons";

import { getGenericData } from "../../../appRedux/actions/NewAction";
import {
  SET_EVENTS_VALUES,
  SET_EVENTS_STATUS,
  SET_EVENTS_VALUES_VALUES,
  SET_EVENTS_VALUES_STATUS,
} from "../../../constants/ActionTypes";
import { NewLineGeneric } from "../../Graph/New/NewLineGeneric";
import { EVENT } from "../../../constants/Color/VarColor";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Option } = Select;

export const Events = ({ data }) => {
  const dispatch = useDispatch();

  const { events } = useSelector((state) => state.chart);
  const { eventsValues } = useSelector((state) => state.chart);

  const [eventList, setEventList] = useState([]);

  const [eventsSelected, setEventsSelected] = useState([]);

  useEffect(() => {
    dispatch(
      getGenericData(data.component_endpoint, (response) => {
        // console.log(response);

        try {
          let children = [];
          let defaultEvent = [];

          // response["results"].map((event) => {
          //   children.push(<Option key={event}>{event}</Option>);
          // });

          // defaultEvent.push(response["results"][0]);

          response["results"].map((event) => {
            children.push({ value: event });
          });

          defaultEvent.push(response["results"][0]);
          setEventsSelected(defaultEvent);
          setEventList(children);
        } catch (err) {
          console.log(err);
        }

        dispatch({
          type: SET_EVENTS_VALUES,
          payload: { key: data.component_id, values: response["results"] },
        });
        dispatch({
          type: SET_EVENTS_STATUS,
          payload: { key: data.component_id, status: "complete" },
        });
      }),
      false
    );
  }, []);

  function handleChange(value) {
    setEventsSelected(value);
  }

  function tagRender(props) {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={
          EVENT[label] !== undefined
            ? EVENT[label]
            : "#" + Math.floor(Math.random() * 16777215).toString(16)
        }
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 4 }}
      >
        {label}
      </Tag>
    );
  }

  return (
    <div id={data.component_id}>
      {events.values[data.component_id] !== undefined &&
      events.status[data.component_id] !== undefined &&
      events.values[data.component_id].length > 0 &&
      events.status[data.component_id] === "complete" &&
      eventList.length > 0 ? (
        <div>
          <div className="event_filter">
            <span>{"Select Events: "}</span>
            <Select
              mode="multiple"
              allowClear
              style={{ width: "35%" }}
              placeholder="Please select"
              defaultValue={eventsSelected}
              maxTagCount={3}
              onChange={handleChange}
              tagRender={tagRender}
              options={eventList}
              className="event_selector"
            />
            {/* {eventList}
            </Select> */}
          </div>

          <NewLineGeneric
            data={data}
            id={data.component_id}
            filter={eventsSelected}
          />
        </div>
      ) : (
        <div style={{ width: "100%", height: 206 }} className="spin_components">
          <Spin indicator={antIcon} />
        </div>
      )}
    </div>
  );
};
