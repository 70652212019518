import {combineReducers} from "redux";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import {connectRouter} from 'connected-react-router'
import ChartReducer from "./ChartReducer";
import FilterReducer from "./FilterReducer";
import { SIGNOUT_USER_SUCCESS } from "../../constants/ActionTypes";
import UserReducer from "./UserReducer";
import TableReducer from "./TableReducer";
import LtvReducer from "./LtvReducer";
import StrategyReducer from "./StrategyReducer";
import FilterComponents from "./FilterComponents";

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  commonData: Common,
  chart: ChartReducer,
  filters: FilterReducer,
  user: UserReducer,
  table: TableReducer,
  ltv: LtvReducer,
  strategy: StrategyReducer,
  filterComp: FilterComponents
});

export const rootReducer = history => (state, action) => {
  // console.log(state, action)

  if (action.type === SIGNOUT_USER_SUCCESS) {
    state = undefined
    // Only way to make sure no cache or personal information
    // is leaked to the next user after the log out
    // https://stackoverflow.com/a/50263511
    
    window.location.assign(window.location.origin + '/signin');

    localStorage.clear()
    window.location.reload() 
  }

  return appReducer(history)(state, action);
}