import React, { useEffect, useState } from "react";
import {
  Legend,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  Line,
} from "recharts";
import { useDispatch, useSelector } from "react-redux";
import { getGenericData } from "../../../appRedux/actions";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  SET_LINE_GENERIC_STATUS,
  SET_LINE_GENERIC_VALUES,
} from "../../../constants/ActionTypes";
import custom from "../../../util/CustomRecharts";
import ChartCompose from "../../Functions/ChartCompose";
import {
  SET_EVENTS_VALUES_VALUES,
  SET_EVENTS_VALUES_STATUS,
} from "../../../constants/ActionTypes";
import { EVENT } from "../../../constants/Color/VarColor";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const NewLineGeneric = ({ data, id, filter }) => {
  const dispatch = useDispatch();

  const [lines, setLines] = useState([]);
  const { eventsValues } = useSelector((state) => state.chart);

  useEffect(() => {
    dispatch(
      getGenericData(
        data.component_secondary_endpoint,
        (response) => {
          try {
            let newLines = [];

            filter.map((metric) => {
              newLines.push(<Line type="monotone" dataKey={metric} stroke={EVENT[metric] !== undefined
                ? EVENT[metric]
                : "#" + Math.floor(Math.random() * 16777215).toString(16)}/>);
            });

            setLines(newLines);

            dispatch({
              type: SET_EVENTS_VALUES_VALUES,
              payload: { key: data.component_id, values: response["results"] },
            });
            dispatch({
              type: SET_EVENTS_VALUES_STATUS,
              payload: { key: data.component_id, status: "complete" },
            });
          } catch (error) {
            console.log(error);
          }
        },
        true,
        filter
      )
    );
  }, [filter]);

  return eventsValues.values[id] !== undefined &&
    eventsValues.status[id] !== undefined &&
    eventsValues.status[id] === "complete" &&
    filter.length > 0 ? (
    <div id={`${id}`} className="GenericRadar_class">
      <ResponsiveContainer width="100%" height={300}>
        <LineChart width={500} height={300} data={eventsValues.values[id]}>
          <CartesianGrid />
          <XAxis dataKey="date" />
          <YAxis tick={custom.CustomTickChartGeneric}/>
          <Tooltip
            content={custom.CustomTooltipStroke}
            payload={eventsValues.values[id]}
            isAnimationActive={true}
          />
          <Legend />
          {lines}
        </LineChart>
      </ResponsiveContainer>
    </div>
  ) : (
    <div style={{ width: "100%", height: 206 }} className="spin_components">
      <Spin indicator={antIcon} />
    </div>
  );
};
