import { Tooltip } from "antd";
import React from "react";
import { Bar, Label, Line, XAxis, YAxis } from "recharts";

const BarCompose = (configChart, label) => {
  let source = [];


  configChart["bar"].map((count) => {

    let newProperties = {};

    if (label) {
      newProperties = {
        ...newProperties,
        label: { fill: "white", fontSize: 12 },
      };
    }

    source.push(
      <Bar
        dataKey={count["dataKey"]}
        width={count["width"]}
        fill={count["fill"]}
        {...newProperties}
      />
    );
  });
  return source;
};

const LineCompose = (configChart) => {
  let source = [];

  configChart["line"].map((count) => {
    source.push(
      <Line
        type="monotone"
        yAxisId={count["yAxisId"]}
        dataKey={count["dataKey"]}
        stroke={count["stroke"]}
      />
    );
  });

  return source;
};

const formatAxis = (tickItem, nChart, xChart) => {
  const { x, y, value, fill, payload, textAnchor, orientation } = tickItem;

  let newResults = [];

  // console.log(orientation);
  // console.log(nChart)
  // console.log(xChart)

  if (typeof payload.value === "string") {
    newResults.push(
      <text
        x={orientation === "left" ? x : x + xChart}
        y={y}
        fill={fill}
        width={200}
        text-anchor={textAnchor}
        className="tick_Yaxis"
      >
        <Tooltip title={payload.value} placement="topRight">
          <tspan>{payload.value.substring(0, nChart) + "... "}</tspan>
        </Tooltip>
      </text>
    );
  } else if (typeof payload.value === "number") {
    newResults.push(
      <text
        x={orientation === "left" ? x : x + xChart}
        y={y}
        fill={fill}
        // width={200}
        text-anchor={textAnchor}
        className="tick_Yaxis"
      >
        <Tooltip title={payload.value} placement="topRight">
          <tspan>{payload.value}</tspan>
        </Tooltip>
      </text>
    );
  }

  return newResults;
};

const XAxisCompose = (configChart, properties, tick, hide) => {
  let source = [];

  try {
    configChart["x"].map((count) => {
      let newProperties = {};

      properties.map((key) => {
        newProperties = {
          ...newProperties,
          [key]: count[key],
        };
      });

      if (tick === "tickFormating") {
        newProperties = {
          ...newProperties,
          ["tick"]: (tickItem) =>
            formatAxis(tickItem, configChart["nChart"], configChart["xChart"]),
        };
      }

      if (hide !== undefined && hide)
        newProperties = {
          ...newProperties,
          ["hide"]: true,
        };

      source.push(
        <XAxis
          dataKey={count["dataKey"]}
          textAnchor={"end"}
          {...newProperties}
        />
      );
    });
  } catch (error) {
    console.log(error);
  }

  return source;
};

const YAxisCompose = (configChart, properties, tick, hide) => {
  let source = [];
  try {
    configChart["y"].map((count) => {
      let newProperties = {};

      properties.map((key) => {
        newProperties = {
          ...newProperties,
          [key]: count[key],
        };
      });

      if (tick === "tickFormating") {
        newProperties = {
          ...newProperties,
          ["tick"]: (tickItem) =>
          formatAxis(tickItem, configChart["nChart"], configChart["xChart"]),
        };
      }

      source.push(
        <YAxis
          dataKey={count["dataKey"]}
          textAnchor={"end"}
          {...newProperties}
        />
      );
    });
  } catch (error) {
    console.log(error);
  }

  return source;
};

const ChartCompose = (configChart, label) => {
  try {
    let newComposeChart = [];

    if (configChart["bar"] !== undefined && configChart["bar"].length > 0) {
      newComposeChart = BarCompose(configChart, label);
      return newComposeChart;
    } else if (
      configChart["line"] !== undefined &&
      configChart["line"].length > 0
    ) {
      newComposeChart = LineCompose(configChart);
      return newComposeChart;
    }
  } catch (err) {
    console.log(err);
  }
};

const ChartCompose2 = (configChart, key, properties, tick, label) => {
  try {
    let newComposeChart = [];

    switch (key) {
      case "bar":
        newComposeChart = BarCompose(configChart, label);
        return newComposeChart;
      default:
    }
  } catch (err) {
    console.log(err);
  }
};

const AxisCompose = (configChart, key, properties, tick, hide) => {
  try {
    let newComposeAxis = [];

    switch (key) {
      case "y":
        newComposeAxis = YAxisCompose(configChart, properties, tick, hide);
        return newComposeAxis;
      case "x":
        newComposeAxis = XAxisCompose(configChart, properties, tick, hide);
        return newComposeAxis;
      default:
    }
  } catch (err) {
    console.log(err);
  }
};

export default {
  ChartCompose,
  AxisCompose,
  ChartCompose2,
  formatAxis,
};
