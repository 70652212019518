import { message } from "antd";
var moment = require("moment");
var sent_date_format = "YYYY-MM-DD";
var twix = require("twix");

var getConversionRate = function (conversions, users) {
  if (users === 0) return 0.0;
  var res = (conversions / users) * 100;
  return Math.round(res * 1e2) / 1e2;
};

var set_percentage = function (sum, sum_prev) {
  if (sum_prev === 0 || sum_prev === undefined) {
    var perc_diff = 0;
  } else {
    perc_diff = [(sum - sum_prev) / sum_prev] * 100;
  }

  return format_number(perc_diff);
};

var get_previous_period = function (start_date, end_date) {
  var moment_start_date = moment(start_date, sent_date_format);
  var moment_end_date = moment(end_date, sent_date_format);
  var diff_days = moment_end_date.diff(moment_start_date, "days");
  // ATTENZIONE: il metodo subtract modifica l'oggetto originale
  var prev_end_date = moment_start_date
    .subtract(1, "days")
    .format(sent_date_format);
  var prev_start_date = moment_start_date
    .subtract(diff_days, "days")
    .format(sent_date_format);

  return { start_date: prev_start_date, end_date: prev_end_date };
};

var sort_by_user = function (x, y) {
  return y["users"] - x["users"];
};

var format_number = function (num, to_fixed) {
  if (num !== null && num !== undefined) {
    let options = {};
    if (to_fixed !== undefined) options["maximumFractionDigits"] = to_fixed;
    return num.toLocaleString("en-GB", options);
  } else {
    return 0;
  }
};

function get_formatted_perc_ratio(q1, q2) {
  var result = 0;

  if (q2 !== undefined && q1 !== undefined && q2 > 0) {
    result = (q1 / q2) * 100;
  }
  return format_number(result, 2) + " %";
}

function get_perc_ratio(q1, q2) {
  var result = 0;

  if (q2 !== undefined && q1 !== undefined && q2 > 0) {
    result = (q1 / q2) * 100;
  }
  return parseFloat(result.toFixed(2));
}

var get_dates_array = function (start_date, end_date) {
  var moment_dates = moment(start_date).twix(end_date).toArray("days");
  var dates = [];
  moment_dates.forEach(function (item) {
    dates.push(item.format(sent_date_format));
  });
  return dates;
};

var format_string_to_number = function (num) {
  if (num !== undefined && num !== null) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    return "";
  }
};

export const validateDates = (startDate, endDate) => {
  switch (true) {
    // Entrambe le date sono undefined
    case (startDate === undefined && endDate === undefined) ||
      (startDate === null && endDate === null) ||
      (startDate.length < 1 && endDate.length < 1): {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      endDate = moment().format("YYYY-MM-DD");
      break;
    }
    // start date o end date sono undefined
    case (startDate === undefined || startDate.length < 1) &&
      endDate !== undefined &&
      endDate.length > 0: {
      startDate = moment(endDate).subtract(30, "days").format("YYYY-MM-DD");
      break;
    }
    case startDate !== undefined &&
      (endDate === undefined || endDate.length < 1) &&
      startDate.length > 0: {
      endDate = moment(startDate).add(30, "days").format("YYYY-MM-DD");
      break;
    }
    // end date viene dopo start date
    case moment(endDate).isBefore(startDate): {
      startDate = moment(endDate).subtract(30, "days").format("YYYY-MM-DD");
      break;
    }
    case !moment(endDate).isSameOrBefore(moment().format("YYYY-MM-DD")): {
      endDate = moment().format("YYYY-MM-DD");

      break;
    }
    default:
  }

  switch (true) {
    case !moment(startDate).isBetween(
      moment().subtract(6, "months").format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD")
    ) &&
      !moment(endDate).isBetween(
        moment().subtract(6, "months").format("YYYY-MM-DD"),
        moment().subtract(1, "days").format("YYYY-MM-DD")
      ): {
      startDate = moment().subtract(30, "days").format("YYYY-MM-DD");
      endDate = moment().subtract(1, "days").format("YYYY-MM-DD");

      message.error("You cannot select dates that are 6 months old");
    }
    default:
  }

  return { startDate: startDate, endDate: endDate };
};

const setColorLum = (hex, lum) => {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, "");
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#",
    c,
    i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
};

export default {
  getConversionRate,
  set_percentage,
  get_dates_array,
  get_previous_period,
  sort_by_user,
  format_number,
  get_formatted_perc_ratio,
  get_perc_ratio,
  format_string_to_number,
  setColorLum,
};
