import React from "react";
import { Layout } from "antd";
import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import App from "routes/index";
import { useSelector } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "../../constants/ThemeSetting";
import { useRouteMatch } from "react-router-dom";
import TestLoader from "../../components/CircularProgress/testLoader";

const { Content, Footer } = Layout;

const MainApp = () => {
  const { width, navStyle } = useSelector(({ settings }) => settings);
  const { accessToken, tokenValidity, validatingToken, initURL } = useSelector(
    ({ auth }) => auth
  );

  const match = useRouteMatch();

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return "";
    }
  };

  return (
    <Layout className="gx-app-layout">
      <Sidebar />
      <Layout>
        <Topbar />
        <Content
          className={`gx-layout-content ${getContainerClass(navStyle)} `}
        >
          {validatingToken ? <TestLoader /> : <App match={match} />}
        </Content>
      </Layout>
    </Layout>
  );
};
export default MainApp;
