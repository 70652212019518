import React, { useEffect } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { NavLink } from "react-router-dom";

import { Avatar, Popover, Spin, Menu } from "antd";
import { userSignOut } from "appRedux/actions/Auth";
import { Link } from "react-router-dom";
import { NAV_STYLE_MINI_SIDEBAR } from "../../constants/ThemeSetting";
import {
  CheckSquareOutlined,
  LoadingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { getUserActionNew } from "../../appRedux/actions/UserActions";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import CustomScrollbars from "util/CustomScrollbars";
import IntlMessages from "../../util/IntlMessages";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const UserInfo = () => {
  const dispatch = useDispatch();
  const store = useStore();

  const { userInfo } = useSelector((state) => state.user);
  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li>
        <Link to="/services">Services</Link>
      </li>
      <li onClick={() => dispatch(userSignOut())}>Logout</li>
    </ul>
  );

  const selectedKeys = pathname.substr(1);

  const defaultOpenKeys = selectedKeys.split("/")[1];

  return userInfo.values !== undefined && userInfo.values !== null ? (
    <div style={{ width: "100%", height: "100%" }}>
      {navStyle !== NAV_STYLE_MINI_SIDEBAR ? (
        <div className="userInfo-card">
          <div>
            <Avatar size={64} src={userInfo.values.profile_pic} />
          </div>
          <div className="name-user-email">
            <span>{userInfo.values.email}</span>
          </div>
        </div>
      ) : (
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomRight"
          content={userMenuOptions}
          trigger="click"
        >
          <Avatar size={28} src={userInfo.values.profile_pic} />
        </Popover>
      )}
    </div>
  ) : (
    <div className="spin_components">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default UserInfo;
