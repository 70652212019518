import React from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { Menu } from "antd";
import { NavLink } from "react-router-dom";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import UserInfo from "components/UserInfo";
import { SET_HEAT_MAP_CHART_VALUES, SET_PAGE_ACTIVE_VALUES } from "../../constants/ActionTypes";
import {
  EyeOutlined,
  AreaChartOutlined,
  MailOutlined,
  RadarChartOutlined,
  HeartOutlined,
  SolutionOutlined,
  ReadOutlined,
  DeploymentUnitOutlined,
  KeyOutlined,
  GiftOutlined,
  CodeOutlined,
  LogoutOutlined,
  CheckSquareOutlined,
  AppstoreAddOutlined,
  PieChartOutlined,
} from "@ant-design/icons";
import { getUserActions, userSignOut } from "../../appRedux/actions";

function get_pages(customersPage, customerActive, dispatch, store) {
  var pages_dict = JSON.parse(localStorage.getItem("pages_dict"));

  const icon = {
    EyeOutlined: EyeOutlined,
    AreaChartOutlined: AreaChartOutlined,
    HeartOutlined: HeartOutlined,
    SolutionOutlined: SolutionOutlined,
    ReadOutlined: ReadOutlined,
    DeploymentUnitOutlined: DeploymentUnitOutlined,
    KeyOutlined: KeyOutlined,
    GiftOutlined: GiftOutlined,
    MailOutlined: MailOutlined,
    RadarChartOutlined: RadarChartOutlined,
    CodeOutlined: CodeOutlined,
    AppstoreAddOutlined: AppstoreAddOutlined,
    PieChartOutlined: PieChartOutlined,
  };

  var list_page = [];
  if (customersPage.values !== null) {
    customersPage.values.map(function (page,i) {      
      if (
        page !== "interest_detail" &&
        page !== "strategy" &&
        pages_dict[page] !== null &&
        pages_dict[page] !== undefined
      ) {
        var Icon_chosen = icon[pages_dict[page].icon];
        list_page.push(
          <Menu.Item key={page}>
            <NavLink
              id={`page_${page}`}
              to={`/${page}?customer=${
                store.getState().user.customerActive.values
              }&start_date=${
                store.getState().filters.dates.start_date
              }&end_date=${store.getState().filters.dates.end_date}`}
              onClick={() => {
                //dispatch({ type: SET_HEAT_MAP_CHART_VALUES, payload: 'idle' })
                dispatch(getUserActions("testInitAll"));
                localStorage.setItem("pageActive", JSON.stringify(page));
                localStorage.setItem("interest", JSON.stringify(""));
              }}
            >
              <Icon_chosen className="icon_sidebar" />
              <IntlMessages id={`sidebar.${page}`} className="text_sidebar" />
            </NavLink>
          </Menu.Item>
        );
      }

      return true;
    });
  }

  return list_page;
}

const SidebarContent = () => {
  let { customers, customersPage, customerActive } = useSelector(
    (state) => state.user
  );

  const dispatch = useDispatch();

  const store = useStore();

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  let { navStyle, themeType, pathname } = useSelector(
    ({ settings }) => settings
  );

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <UserInfo />
        </div>
        <div className="dtl-sidebar-content">
          <Menu
            className="user-list-menu"
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.ItemGroup
              key="in-built-apps"
              className="gx-menu-group title-menu"
              title={<IntlMessages id="sidebar.userMenu" />}
            >
              <Menu.Item key={"service"}>
                <NavLink
                  to={`/services?customer=${
                    store.getState().user.customerActive.values
                  }&start_date=${
                    store.getState().filters.dates.start_date
                  }&end_date=${store.getState().filters.dates.end_date}`}
                >
                  <CheckSquareOutlined className="icon_sidebar" />
                  <IntlMessages
                    id={`sidebar.services`}
                    className="text_sidebar"
                  />
                </NavLink>
              </Menu.Item>
              {/* <Menu.Item key={"strategy_summary"}>
                <NavLink
                  to={`/summary?customer=${
                    store.getState().user.customerActive.values
                  }&start_date=${
                    store.getState().filters.dates.start_date
                  }&end_date=${store.getState().filters.dates.end_date}`}
                  onClick={() => {
                    //dispatch({ type: SET_HEAT_MAP_CHART_VALUES, payload: 'idle' })
                    dispatch(getUserActions("testInitAll"));
                    dispatch({
                      type: SET_PAGE_ACTIVE_VALUES,
                      payload: 'summary',
                    });
                    localStorage.setItem("pageActive", JSON.stringify('summary'));
                    localStorage.setItem("interest", JSON.stringify(""));
                  }}
                >
                  <CheckSquareOutlined className="icon_sidebar" />
                  <IntlMessages
                    id={`sidebar.strategy_summary`}
                    className="text_sidebar"
                  />
                </NavLink>
              </Menu.Item> */}
              <Menu.Item key={"logout"}>
                <div
                  className="user-list-menu-logout"
                  onClick={() => dispatch(userSignOut())}
                  id="logout"
                >
                  <LogoutOutlined className="icon_sidebar" />
                  <IntlMessages
                    id={`sidebar.logout`}
                    className="text_sidebar"
                  />
                </div>
              </Menu.Item>
              {/* <Menu.Item>
                <NavLink to={`/summary`}>
                  <CheckSquareOutlined className="icon_sidebar" />
                  <IntlMessages
                    id={`sidebar.summary`}
                    className="text_sidebar"
                  />
                </NavLink>
              </Menu.Item> */}
            </Menu.ItemGroup>
          </Menu>
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.ItemGroup
              key="in-built-apps"
              className="gx-menu-group"
              title={<IntlMessages id="sidebar.services" />}
            >
              {get_pages(customersPage, customerActive, dispatch, store)}
            </Menu.ItemGroup>
          </Menu>
        </div>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;
