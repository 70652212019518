import React from "react";
import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getFilterData } from "../../appRedux/actions/FilterActions";

export const FilterConversion = () => {
  const radioStyle = {
    display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const dispatch = useDispatch();

  const { filterConversions } = useSelector((state) => state.filters);

  const onChange = (e) => {
    dispatch(getFilterData("filterConversions", e.target.value));
  };

  return (
    <Radio.Group onChange={onChange} value={filterConversions.values}>
      <Radio style={radioStyle} value={"all"}>
        All
      </Radio>
      {/* <Radio style={radioStyle} value={"converted"}>
      Converted
    </Radio>
    <Radio style={radioStyle} value={"notconverted"}>
      Not Converted
    </Radio> */}
      <Radio style={radioStyle} value={1}>
        Converted
      </Radio>
      <Radio style={radioStyle} value={0}>
        Not Converted
      </Radio>
    </Radio.Group>
  );
};
